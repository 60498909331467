import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Settings, SquarePlus } from 'lucide-react';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { DialogActionType } from '@/constants';
import {
  CreateSensitiveDocument,
  UpdateSensitiveDocument,
  UsersQuery,
  UsersQueryVariables,
} from '@@graphql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { toast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { CreateSensitiveSchema } from '@/zodSchema/create-sensitive.ts';

type DialogProps = {
  type: DialogActionType;
  id?: string;
  word?: string;
  enabled?: boolean;
  refetch?: (
    variables?: Partial<UsersQueryVariables>,
  ) => Promise<ApolloQueryResult<UsersQuery>>;
};

interface FormData {
  word: string;
}

const SensitiveDialog = ({ type, word, refetch, id }: DialogProps) => {
  const [open, setOpen] = useState(false);
  const errorObject = {
    word: '',
  };
  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);

  const [createMutation] = useMutation(CreateSensitiveDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const [updateMutation] = useMutation(UpdateSensitiveDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const createSensitive = async (formData: FormData) => {
    await createMutation({
      variables: {
        ...formData,
      },
    });
  };

  const updateSensitive = async (id: string, formData: FormData) => {
    await updateMutation({
      variables: {
        id,
        ...formData,
      },
    });
  };
  const onSubmitCreateOrUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    const data = new FormData(e.currentTarget);
    const wordFieldValue = data.get('word') as string;
    const formData = {
      word: wordFieldValue.trim(),
    };
    try {
      CreateSensitiveSchema.parse(formData);
      if (DialogActionType.CREATE === type) {
        await createSensitive(formData);
        toast({ title: '新增機敏關鍵字成功' });
      } else {
        id && (await updateSensitive(id, formData));
        toast({ title: '編輯機敏關鍵字' });
      }
      setOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {type === DialogActionType.CREATE ? (
          <Button className="gap-1 font-normal text-primary" variant="ghost">
            <SquarePlus size={20} strokeWidth={1.5} /> 新增機敏關鍵字
          </Button>
        ) : (
          <Button
            className="size-8 text-typography-secondary"
            variant="ghost"
            size="icon"
          >
            <Settings size={20} strokeWidth={1.5} />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onSubmitCreateOrUpdate}>
          <DialogHeader>
            <DialogTitle>
              {type === DialogActionType.CREATE ? '新增機敏關鍵字' : '編輯機敏關鍵字'}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="word" className="text-right font-normal">
                關鍵字
              </Label>
              <div className="col-span-3 space-y-1">
                <Input
                  id="word"
                  name="word"
                  className="col-span-3"
                  defaultValue={word}
                />
                {errors.word && <div className="text-red-500">{errors.word}</div>}
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SensitiveDialog;
