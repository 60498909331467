import { BREAKPOINTS } from '@/constants/breakpoints';
import { useEffect, useState } from 'react';

/**
 * @param {string} [query] - Media query string
 * @example
 * isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.TABLET})`);
 * isTablet = useMediaQuery(`(min-width: ${BREAKPOINTS.MOBILE}) and (max-width: ${BREAKPOINTS.TABLET})`);
 * isMobile = useMediaQuery(); // default
 * @return {boolean}
 */

const useMediaQuery = (
  query: string = `(max-width: ${BREAKPOINTS.MOBILE})`,
) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent) => {
      setValue(event.matches);
    };

    const result = matchMedia(query);
    result.addEventListener('change', onChange);
    setValue(result.matches);

    return () => result.removeEventListener('change', onChange);
  }, [query]);

  return value;
};

export default useMediaQuery;
