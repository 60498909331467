import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import AdminDialog from '@/components/admin-dialog';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Department,
  DepartmentsDocument,
  RemoveDepartmentManagerDocument,
  UserModel,
  UsersIsManagerDocument,
} from '@@graphql';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '@/components/ui/label.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useAuth } from '@/auth.tsx';
import { User } from '@/types';

interface AdminData {
  id: string;
  name: string;
  email: string;
}

interface RemoveManagerProps {
  departmentId: string;
  userId: string;
}

export const Route = createFileRoute('/_layout/settings/admin-settings')({
  component: AdminSettings,
});

function AdminSettings() {
  const currentUser = useAuth().user as User;
  const { handleError } = useErrorHandler({});
  const [departmentId, setDepartmentId] = useState('');
  const [adminData, setAdminData] = useState([] as AdminData[]);

  const { data, loading, error } = useQuery(DepartmentsDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const [usersIsManagerQuery] = useLazyQuery(UsersIsManagerDocument);
  const [removeManagerMutation] = useMutation(RemoveDepartmentManagerDocument);
  const fetchUsers = useCallback(async () => {
    if (!departmentId) return;

    try {
      const { data } = await usersIsManagerQuery({
        variables: {
          isManager: true,
          departmentId,
        },
        fetchPolicy: 'network-only',
      });

      const result =
        data?.users
          .filter((user: UserModel) => user.id !== currentUser.id)
          .map(({ id, name, email }: UserModel) => ({
            id,
            name,
            email,
          })) ?? [];

      setAdminData(result);
    } catch (error) {
      handleError(error);
    }
  }, [departmentId]);

  useEffect(() => {
    if (departmentId) {
      fetchUsers();
    }
  }, [departmentId]);

  const removeManager = async ({ departmentId, userId }: RemoveManagerProps) => {
    try {
      await removeManagerMutation({
        variables: { departmentId, userId },
      });
      // 成功後重新獲取用戶列表
      await fetchUsers();
    } catch (error) {
      handleError(error);
    }
  };

  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  const departmentData =
    data?.departments.map(({ id, name }: Department) => {
      return {
        id,
        name,
      };
    }) ?? [];

  const selectDepartment = async (value: string) => {
    setDepartmentId(value);
  };

  const adminColumns: ColumnDef<AdminData>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            使用者名稱
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'email',
      header: '電子郵件',
      cell: ({ row }) => (
        <div className="font-mono text-sm">{row.getValue('email')}</div>
      ),
    },
    {
      id: 'actions',
      header: '操作',
      enableHiding: false,
      cell: ({ row }) => {
        const { email, id } = row.original;
        return (
          <div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="size-8 text-error hover:text-error"
                  variant="ghost"
                  size="icon"
                >
                  <Trash2 size={20} strokeWidth={1.5} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>刪除管理者</AlertDialogTitle>
                  <AlertDialogDescription>
                    {`確定要刪除電子郵件為 ${email} 的管理者?`}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>取消</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => removeManager({ departmentId, userId: id })}
                  >
                    確定
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
    },
  ];
  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>部門權限管理</CardTitle>
            <AdminDialog
              managerIds={adminData.map(admin => admin.id)}
              departmentId={departmentId}
              fetchUsers={fetchUsers}
            />
          </div>
          <CardDescription>
            此頁面用來新增和刪除部門的管理者，部門管理者可以在『部門設定』裡新增使用者到部門內並設定權限
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="flex justify-end items-center gap-2">
            <Label htmlFor="name" className="font-normal">
              選擇部門
            </Label>
            <Select onValueChange={selectDepartment}>
              <SelectTrigger className="w-[200px] focus:ring-transparent text-typography-secondary">
                <SelectValue placeholder="請選擇部門" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {departmentData.map(({ id, name }: Department) => (
                    <SelectItem key={id} value={id}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <DataTable data={adminData} columns={adminColumns} />
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
