import { useState, useRef, useEffect, useCallback } from 'react';
import debounce from 'debounce';
import { X } from 'lucide-react';
import {
  LazyQueryHookExecOptions,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { Input } from '@/components/ui/input.tsx';

interface InputTagsProps {
  keywordsQuery: (
    options?: Partial<LazyQueryHookExecOptions<any, OperationVariables>>,
  ) => Promise<QueryResult<any, OperationVariables>>;
  maxTags: number;
  onTagsChange: (newTags: string[]) => void;
  defaultTags: string[];
}

const InputTags = ({
  keywordsQuery,
  maxTags,
  onTagsChange,
  defaultTags,
}: InputTagsProps) => {
  const [tags, setTags] = useState<string[]>(defaultTags);
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const fetchSuggestions = async (query: string) => {
    const {
      data: { keywords: allSuggestions },
    } = await keywordsQuery({ variables: { search: query } });
    return allSuggestions.filter((suggestion: string) =>
      suggestion.toLowerCase().includes(query.toLowerCase()),
    );
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(async query => {
      if (query.length > 0 && tags.length < maxTags) {
        const results = await fetchSuggestions(query);
        setSuggestions(
          results.filter((suggestion: string) => !tags.includes(suggestion)),
        );
      } else {
        setSuggestions([]);
      }
    }, 500),
    [tags, maxTags],
  );

  useEffect(() => {
    debouncedFetchSuggestions(input);
  }, [input, debouncedFetchSuggestions]);

  useEffect(() => {
    onTagsChange(tags);
  }, [tags, onTagsChange]);

  const addTag = (tag: string) => {
    if (tag && !tags.includes(tag) && tags.length < maxTags) {
      const newTags = [...tags, tag];
      setTags(newTags);
      setInput('');
      setSuggestions([]);
      onTagsChange(newTags);
    }
  };

  const removeTag = (tagToRemove: string) => {
    const newTags = tags.filter(tag => tag !== tagToRemove);
    setTags(newTags);
    onTagsChange(newTags);
  };

  const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === 'Enter' && input && tags.length < maxTags) {
      e.preventDefault();
      addTag(input);
    } else if (e.key === 'Backspace' && !input && tags.length > 0) {
      removeTag(tags[tags.length - 1]);
    }
  };

  return (
    <div className="w-full col-span-3">
      <div className="flex flex-wrap gap-2 p-2 border rounded-md ">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="flex items-center bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm"
          >
            {tag}
            <button onClick={() => removeTag(tag)} className="ml-1 focus:outline-none">
              <X size={14} />
            </button>
          </span>
        ))}
        {tags.length < maxTags && (
          <Input
            ref={inputRef}
            type="text"
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            className="flex-grow outline-none"
            placeholder={tags.length === maxTags - 1 ? '最後一個' : '輸入關鍵字'}
          />
        )}
      </div>
      {suggestions.length > 0 && tags.length < maxTags && (
        <ul className="mt-2 border rounded-md shadow-sm">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => addTag(suggestion)}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      <p className="mt-2 text-sm text-gray-500 text-right">
        {tags.length} / {maxTags} 關鍵字已使用
      </p>
      <p className="text-sm mt-2 text-gray-400">
        提示：輸入完一個關鍵字後，點擊 <span className="text-primary">↵ </span>
        輸入鍵，確認關鍵字，可以進行下一個輸入
      </p>
    </div>
  );
};

export default InputTags;
