import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from './ui/button';
import { Settings } from 'lucide-react';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { NewFolder } from './icons';
import { DialogActionType } from '@/constants';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  CreateDepartmentDocument, DepartmentsQuery, DepartmentsQueryVariables,
  UpdateDepartmentDocument
} from '@@graphql';
import { FormEvent, useState } from 'react';
import { createDepartmentSchema } from '@/zodSchema/create-department.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { toast } from '@/components/ui/use-toast.ts';

type DepartmentDialogProps = {
  type: DialogActionType;
  id?: string;
  department?: string;
  description?: string | null;
  refetch?: (
    variables?: Partial<DepartmentsQueryVariables>,
  ) => Promise<ApolloQueryResult<DepartmentsQuery>>;
};

const DepartmentDialog = ({
  type,
  department,
  description,
  refetch,
  id: departmentId,
}: DepartmentDialogProps) => {
  const initialErrorState = {
    name: '',
    description: '',
  };
  const { errors, handleError, resetErrors } =
    useErrorHandler(initialErrorState);
  const [isOpen, setIsOpen] = useState(false);

  const [createDepartment] = useMutation(CreateDepartmentDocument);
  const [updateDepartment] = useMutation(UpdateDepartmentDocument);

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    const data = new FormData(e.currentTarget);
    const nameFieldValue = data.get('name');
    const descriptionFieldValue = data.get('description');
    const formData = {
      name: nameFieldValue!.toString(),
      description: descriptionFieldValue?.toString() ?? '',
    };
    try {
      createDepartmentSchema.parse(formData);
      if (type === DialogActionType.CREATE) {
        await createDepartment({
          variables: formData,
        });
        setIsOpen(false);
      } else {
        if (departmentId === undefined) {
          toast({
            title: '錯誤',
            description: '部門 ID 為必填',
            variant: 'destructive',
          });
          return;
        }
        await updateDepartment({
          variables: {
            ...formData,
            departmentId,
          },
        });
      }
      setIsOpen(false);
      refetch && (await refetch());
    } catch (err: unknown) {
      handleError(err);
    }
  };
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {type === DialogActionType.CREATE ? (
          <Button className="gap-1 font-normal text-primary" variant="ghost">
            <NewFolder /> 新增部門
          </Button>
        ) : (
          <Button
            className="size-8 text-typography-secondary"
            variant="ghost"
            size="icon"
          >
            <Settings size={20} strokeWidth={1.5} />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[430px]">
        <form onSubmit={onFormSubmit}>
          <DialogHeader>
            <DialogTitle>
              {type === DialogActionType.CREATE ? '新增部門' : '編輯部門'}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="department" className="text-right font-normal">
                部門名稱
              </Label>
              <div className="col-span-3">
                <Input
                  id="department"
                  defaultValue={department}
                  name="name"
                  placeholder="研發部"
                />
                {errors.name && (
                  <div className="text-red-500">{errors.name}</div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="description" className="text-right font-normal">
                部門描述
              </Label>
              <div className="col-span-3">
                <Input
                  id="description"
                  name="description"
                  defaultValue={description ?? undefined}
                  placeholder="研究與開發, 技術"
                />
                {errors.description && (
                  <div className="text-red-500">{errors.description}</div>
                )}
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default DepartmentDialog;
