import { createFileRoute } from '@tanstack/react-router';
import { useLazyQuery } from '@apollo/client';
import { PrivateFilesDocument, TimeFieldEnum } from '@@graphql';
import { FileModel } from '@/types';
import { formatFileSize, transFormDate, transformFileType } from '@/utils/file';
import FolderView from '@/pages/folderView';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from '@/store';
import DatePickerWithRange from '@/components/date-picker-with-range';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { SearchMime, TAKE_PER_PAGE } from '@/constants';
import { DateRange } from 'react-day-picker';
import InfiniteScrollWrapper from '@/components/infinite-scroll-wrapper';
import Loading from '@/components/loading';

export const Route = createFileRoute('/_layout/confidential-files/$departmentId')({
  component: ConfidentialFiles,
});

function ConfidentialFiles() {
  const isLoadingMoreRef = useRef(false);
  const currentPageRef = useRef<number>(1);
  const { departmentId } = Route.useParams();
  const departments = useStore.home(state => state.departments);
  const [fetchFiles, { loading, error, data }] = useLazyQuery(PrivateFilesDocument, {
    fetchPolicy: 'network-only',
  });
  const [searchMime, setSearchMime] = useState<string>(SearchMime.ALL);
  const [fileRawData, setFileRawData] = useState<FileModel[]>([]);

  const [currentTimeField, setCurrentTimeField] = useState<TimeFieldEnum | undefined>(
    undefined,
  );
  const [createdDateRange, setCreatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [updatedDateRange, setUpdatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );

  const loadMore = useCallback(async () => {
    if (!isLoadingMoreRef.current && data?.privateFiles?.pageInfo.hasNextPage) {
      isLoadingMoreRef.current = true;
      const startAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.from
          : updatedDateRange?.from;
      const endAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.to
          : updatedDateRange?.to;
      const result = await fetchFiles({
        variables: {
          departmentId,
          page: currentPageRef.current,
          take: TAKE_PER_PAGE,
          timeField: currentTimeField,
          timeRange: { startAt, endAt },
          mime: searchMime,
        },
      });
      isLoadingMoreRef.current = false;
      if (result.data) {
        setFileRawData(prev => [
          ...prev,
          ...(result.data?.privateFiles?.items as FileModel[]),
        ]);
        currentPageRef.current += 1;
      }
      return result;
    }
  }, [
    data?.privateFiles?.pageInfo.hasNextPage,
    fetchFiles,
    departmentId,
    currentTimeField,
    createdDateRange,
    updatedDateRange,
    searchMime,
  ]);

  const refetchFiles = useCallback(async () => {
    currentPageRef.current = 1;
    setFileRawData([]);
    const result = await fetchFiles({
      variables: {
        departmentId,
        page: 1,
        take: TAKE_PER_PAGE,
        timeField: currentTimeField,
        timeRange: {
          startAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.from
              : updatedDateRange?.from,
          endAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.to
              : updatedDateRange?.to,
        },
        mime: searchMime,
      },
    });
    if (result.data) {
      setFileRawData(result.data?.privateFiles?.items as FileModel[]);
      currentPageRef.current = 2;
    }
  }, [
    departmentId,
    fetchFiles,
    currentTimeField,
    createdDateRange,
    updatedDateRange,
    searchMime,
  ]);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const startAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.from
          : updatedDateRange?.from;
      const endAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.to
          : updatedDateRange?.to;
      const result = await fetchFiles({
        variables: {
          departmentId,
          timeField: currentTimeField,
          timeRange: { startAt, endAt },
          mime: searchMime,
          take: TAKE_PER_PAGE,
        },
      });
      if (!ignore && result.data) {
        setFileRawData(result.data?.privateFiles?.items as FileModel[]);
        currentPageRef.current = 2;
      }
    };

    fetchData();

    return () => {
      ignore = true;
    }
  }, [
    departmentId,
    createdDateRange,
    updatedDateRange,
    searchMime,
    currentTimeField,
    fetchFiles,
  ]);

  if (loading && currentPageRef.current === 1) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  const fileData = fileRawData.map(
    ({ name, ext, isDir, id, size, updatedAt, createdAt, creatorName, isPrivate }) => {
      let source = transFormDate(createdAt);
      if (!isDir) {
        source += `-${creatorName}`;
      }
      return {
        id,
        updatedAt: transFormDate(updatedAt),
        createdAt: transFormDate(createdAt),
        creationInfo: source,
        isDir,
        name,
        type: isDir ? undefined : transformFileType(ext),
        size: formatFileSize(size),
        isPrivate,
      };
    },
  );

  const departmentName = departments.find(d => d.id === departmentId)?.name;
  return (
    <InfiniteScrollWrapper
      hasMore={data?.privateFiles?.pageInfo.hasNextPage || false}
      loadMore={loadMore}
      isLoading={loading && currentPageRef.current !== 1}
    >
      <FolderView
        folderName={`${departmentName} 機敏檔案`}
        filterAction={
          <>
            <DatePickerWithRange
              date={createdDateRange}
              setDate={setCreatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.CreatedAt);
                setUpdatedDateRange(undefined);
              }}
              placeholder="建立時間"
            />
            <DatePickerWithRange
              date={updatedDateRange}
              setDate={setUpdatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.UpdatedAt);
                setCreatedDateRange(undefined);
              }}
              placeholder="上次修改時間"
            />
            <Select onValueChange={setSearchMime}>
              <SelectTrigger className="w-[110px] focus:ring-transparent bg-background hover:bg-accent hover:text-accent-foreground">
                <SelectValue placeholder="類型" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={SearchMime.ALL}>所有類型</SelectItem>
                <SelectItem value={SearchMime.PDF}>PDF</SelectItem>
                <SelectItem value={SearchMime.IMAGE}>圖片</SelectItem>
                <SelectItem value={SearchMime.OFFICE}>Office 相關</SelectItem>
              </SelectContent>
            </Select>
          </>
        }
        isDepartment={false}
        breadcrumbs={[]}
        refetchFolder={refetchFiles}
        files={fileData}
      />
    </InfiniteScrollWrapper>
  );
}
