import Lottie from 'react-lottie';
import animationData from '@/lotties/loading.json';
import { getAnimationConfig } from '@/lib/utils';

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Lottie
        options={getAnimationConfig({ animationData })}
        height={150}
        width={150}
      />
    </div>
  );
};

export default Loading;
