import InfiniteScrollTable from '@/components/infinite-scroll-table';
import { DATE_TIME_FORMAT } from '@/constants';
import {
  FileMetricTypeEnum,
  FileMetricsDocument,
  FileMetricModel,
} from '@/graphql/generated/.graphql';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import DatePickerWithRange from '@/components/date-picker-with-range';
import { DateRange } from 'react-day-picker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useState } from 'react';
import { CREATOR_TYPE_LABEL_MAP } from '../file-history-records';
import { Link } from '@tanstack/react-router';
import SortColumn from '@/components/sort-column';

const FILE_OPERATION_LABEL_MAP = {
  [FileMetricTypeEnum.All]: '所有行為',
  [FileMetricTypeEnum.Download]: '下載檔案',
  [FileMetricTypeEnum.Upload]: '介面上傳',
  [FileMetricTypeEnum.UploadDocuai]: '事務機上傳',
  [FileMetricTypeEnum.View]: '檢視檔案',
};

const FileHistoryRecords = () => {
  const [createdDateRange, setCreatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [operation, setOperation] = useState<FileMetricTypeEnum>(
    FileMetricTypeEnum.All,
  );
  const columns: ColumnDef<FileMetricModel>[] = [
    {
      header: ({ column }) => <SortColumn name="檔案名稱" column={column} />,
      accessorKey: 'file.name',
      cell: ({ row }) => {
        const { link, file } = row?.original || {};
        const name = file?.name || '-';

        if (!file) {
          return name;
        }

        return file?.deletedAt ? <span>{name}</span> : <Link to={link}>{name}</Link>;
      },
      size: 300,
    },
    {
      header: ({ column }) => <SortColumn name="檔案大小" column={column} />,
      accessorKey: 'size',
      cell: ({ row }) => {
        const size = row?.original?.size;
        return <div>{size ? `${size / 1000} KB` : '-'}</div>;
      },
    },
    {
      header: ({ column }) => <SortColumn name="行為" column={column} />,
      accessorKey: 'operation',
      cell: ({ row }) => {
        const operation = row?.original?.operation;
        return <div>{operation ? FILE_OPERATION_LABEL_MAP[operation] : ''}</div>;
      },
      size: 100,
    },
    {
      header: ({ column }) => <SortColumn name="操作人員帳號" column={column} />,
      accessorKey: 'creatorInfo.email',
      size: 250,
    },
    {
      header: ({ column }) => <SortColumn name="所屬部門" column={column} />,
      accessorKey: 'department.name',
    },
    {
      header: ({ column }) => <SortColumn name="檔案來源" column={column} />,
      accessorKey: 'creatorInfo.type',
      cell: ({ row }) => {
        const type = row?.original?.creatorInfo?.type;
        return <div>{type ? CREATOR_TYPE_LABEL_MAP[type] : ''}</div>;
      },
    },
    {
      header: ({ column }) => <SortColumn name="建立時間" column={column} />,
      accessorKey: 'createdAt',
      cell: ({ row }) => {
        const createdAt = row?.original?.createdAt;
        return <div>{createdAt ? dayjs(createdAt).format(DATE_TIME_FORMAT) : ''}</div>;
      },
    },
  ];

  return (
    <div className="p-8">
      <h1 className="mb-4 text-lg md:text-2xl font-bold">檔案流量紀錄</h1>
      <div className="flex gap-2">
        <DatePickerWithRange
          date={createdDateRange}
          setDate={setCreatedDateRange}
          placeholder="建立時間"
        />
        <div className="w-[150px] mb-4">
          <Select
            name="emailType"
            defaultValue={FileMetricTypeEnum.All}
            onValueChange={(value: FileMetricTypeEnum) => {
              setOperation(value);
            }}
          >
            <SelectTrigger className="bg-background hover:bg-accent hover:text-accent-foreground">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(FILE_OPERATION_LABEL_MAP).map(operation => (
                <SelectItem key={operation} value={operation}>
                  {FILE_OPERATION_LABEL_MAP[operation as FileMetricTypeEnum]}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <InfiniteScrollTable
          columns={columns}
          query={FileMetricsDocument}
          getVariables={cursor => ({
            operation,
            after: cursor,
            startAt: createdDateRange?.from,
            endAt: createdDateRange?.to,
          })}
          queryKey={[
            'actionLogs',
            { operation, startAt: createdDateRange?.from, endAt: createdDateRange?.to },
          ]}
          getNextPageParam={lastPage => lastPage?.fileMetrics?.pageInfo?.endCursor}
          dataExtractor={(data: any) => data?.fileMetrics?.items}
        />
      </div>
    </div>
  );
};

export default FileHistoryRecords;
