import {
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import DepartmentDialog from '@/components/department-dialog.tsx';
import { DialogActionType } from '@/constants';
import { DepartmentsQuery, DepartmentsQueryVariables } from '@@graphql';
import { ApolloQueryResult } from '@apollo/client';

interface DepartmentHeaderProps {
  refetch?: (
    variables?: Partial<DepartmentsQueryVariables>,
  ) => Promise<ApolloQueryResult<DepartmentsQuery>>;
}

function DepartmentHeader({ refetch }: DepartmentHeaderProps) {
  return (
    <CardHeader>
      <div className="flex justify-between items-center">
        <CardTitle>部門管理</CardTitle>
        <DepartmentDialog type={DialogActionType.CREATE} refetch={refetch} />
      </div>
      <CardDescription>
        此頁面提供新增、分類、編輯、刪除部門的設定
      </CardDescription>
    </CardHeader>
  );
}

export default DepartmentHeader;
