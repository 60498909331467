import { useEffect, useMemo, useRef, useState } from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import LandingLayout from '@/components/layout/landing-layout';
import { decodeJWT } from '@/lib/utils';
import { useMutation } from '@apollo/client';
import { SelectCompanyDocument } from '@@graphql';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { useStore } from '@/store';
import { JwtPayload } from 'jsonwebtoken';
import LinkExpired from '@/components/link-expired';
import { UserRoundCheck } from 'lucide-react';
import Loading from '@/components/loading';

type Company = {
  name: string;
  id: string;
};

type Payload = JwtPayload & {
  email: string;
  selectingCompany: boolean;
  companyId: string;
  companies: Array<Company>;
};

function SelectCompany() {
  const search = Route.useSearch() as { token: string };
  const { setUser } = useStore.user.getState();
  const { handleError } = useErrorHandler({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState<boolean>(false);
  const [selectCompanyMutation] = useMutation(SelectCompanyDocument, {
    onError: err => handleError(err),
  });
  const [payload, setPayload] = useState<Payload | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);

  const [showBlur, setShowBlur] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;
    setShowBlur(!isBottom);
  };

  useEffect(() => {
    if (!search.token) {
      setIsTokenValid(false);
      return;
    }

    try {
      const decodedPayload = decodeJWT(search.token) as Payload;
      if (decodedPayload.exp && decodedPayload.exp < Date.now() / 1000) {
        setIsTokenValid(false);
      } else {
        setPayload(decodedPayload);
        setIsTokenValid(true);
      }
    } catch (error) {
      setIsTokenValid(false);
    }
  }, [search.token]);

  const sortedCompanies = useMemo((): Payload['companies'] => {
    if (!payload) return [];
    const matchedCompany = payload.companies.find(
      company => company.id === payload.companyId,
    ) as Company;
    const otherCompanies = payload.companies.filter(
      company => company.id !== payload.companyId,
    );
    return [matchedCompany, ...otherCompanies];
  }, [payload]);

  const handleCompanySelect = async (companyId: string) => {
    setSelected(true);
    try {
      const { data, errors } = await selectCompanyMutation({
        variables: {
          token: search.token,
          companyId,
        },
      });
      if (errors) {
        handleError(errors);
        setSelected(false);
        return;
      }
      const { accessToken, user } = data.selectCompany;
      setUser(user);
      localStorage.setItem('accessToken', accessToken);
      return navigate({ replace: true, to: '/' });
    } catch (error) {
      handleError(error);
      setSelected(false);
    }
  };

  if (!isTokenValid) {
    return <LinkExpired />;
  }

  if (!payload) {
    return <Loading />;
  }

  return (
    <LandingLayout className="items-center">
      <div className="w-full max-w-md bg-white rounded-3xl p-8">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl font-semibold text-center mb-4">選擇公司</h1>
        <p className="text-center text-gray-500 mb-6">請選擇您要登入的公司</p>
        <div className="relative">
          <div
            ref={scrollRef}
            onScroll={handleScroll}
            className="max-h-[200px] overflow-y-auto rounded-lg"
          >
            <div className="space-y-4">
              {sortedCompanies.map(({ name, id }) => (
                <Button
                  key={id}
                  disabled={selected}
                  className={`w-full py-3 rounded-lg font-semibold ${
                    id === payload.companyId
                      ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                      : 'text-white'
                  }`}
                  onClick={() => handleCompanySelect(id)}
                >
                  {id === payload.companyId && <UserRoundCheck />}
                  {name}
                </Button>
              ))}
            </div>
          </div>
          {showBlur && sortedCompanies.length > 4 && (
            <div className="absolute bottom-0 left-0 right-0 h-20 pointer-events-none bg-gradient-to-t from-white to-transparent" />
          )}
        </div>
      </div>
    </LandingLayout>
  );
}

export const Route = createFileRoute('/select-company')({
  component: SelectCompany,
});
