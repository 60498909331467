import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import ProfileLayout from '@/components/layout/profile-layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import {
  RevokeSocialAuthDocument,
  SocialAuthModel,
  SocialAuthsDocument,
  SocialAuthUrlDocument,
  SocialProvider,
} from '@@graphql';
import { useToast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { GoogleCalendar } from '@/components/icons';
import { Info } from 'lucide-react';

const InfoCard = () => (
  <Card className="md:self-start border-blue-300 w-full md:w-1/4 bg-gradient-to-br from-blue-50 to-indigo-50 shadow-md">
    <CardContent className="p-4 font-light">
      <div className="flex items-center space-x-2 mb-3">
        <Info className="w-5 h-5 text-blue-500" />
        <h2 className="text-lg font-semibold">重要資訊</h2>
      </div>
      <p className="text-sm mb-2 text-gray-600">
        系統會為<span className="font-semibold">經自動分類功能</span>
        識別為以下類別的法律文件生成待辦事項：
      </p>
      <ul className="space-y-2 mb-4 ml-4">
        {['通知書', '傳票', '裁判'].map((item, index) => (
          <li key={index} className="flex items-center space-x-2">
            <span className="w-1.5 h-1.5 bg-blue-400 rounded-full"></span>{' '}
            <span className="text-sm text-gray-700">{item}</span>
          </li>
        ))}
      </ul>
    </CardContent>
  </Card>
);

function Integration() {
  const { toast } = useToast();
  const { data, loading, error, refetch } = useQuery(SocialAuthsDocument, {
    fetchPolicy: 'network-only',
  });
  const { handleError } = useErrorHandler({});
  const [revokeMutation] = useMutation(RevokeSocialAuthDocument);
  const [socialAuthUrl] = useMutation(SocialAuthUrlDocument);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const getAuthInfo = (provider: SocialProvider) => {
    const socialAuths = data?.socialAuths as SocialAuthModel[];
    return socialAuths.find(auth => auth.provider === provider);
  };
  const googleAuth = getAuthInfo(SocialProvider.Google);
  const toggleButton = async (provider: SocialProvider) => {
    const socialAuths = data?.socialAuths as SocialAuthModel[];
    const isBound = !!socialAuths.find(auth => auth.provider === provider);

    try {
      if (isBound) {
        await revokeMutation({
          variables: {
            provider,
          },
        });
        toast({
          title: '已取消綁定',
          description: `已取消綁定 ${provider} 行事曆`,
        });
        await refetch();
      } else {
        const { data } = await socialAuthUrl({
          variables: { type: provider },
        });
        if (data?.socialAuthUrl) {
          window.open(data?.socialAuthUrl, '_blank');
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <ProfileLayout>
      <div className="flex flex-col md:flex-row gap-6 w-full">
        <Card className="py-6 px-2 w-full md:w-1/3">
          <CardHeader>
            <CardTitle>綁定行事曆</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-500 mb-4">
                  綁定您的行事曆，即可將待辦事項直接同步到行事曆中。
                </p>
                <div className="flex items-center justify-between md:justify-around space-x-2">
                  <div className="flex items-center space-x-4">
                    <GoogleCalendar />
                    <div>
                      <div className="font-semibold">Google 行事曆</div>
                      <div className="text-sm text-neutral-500">
                        {googleAuth && googleAuth.rawData.email}
                      </div>
                    </div>
                  </div>
                  <Button
                    variant={data?.socialAuths?.length ? 'destructive' : 'default'}
                    onClick={async () => toggleButton(SocialProvider.Google)}
                  >
                    {data?.socialAuths?.length ? '解除綁定' : '綁定'}
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <InfoCard />
      </div>
    </ProfileLayout>
  );
}

export const Route = createFileRoute('/_layout/profile/integration')({
  component: Integration,
});
