import { Layout } from '@/components/layout';
import {
  createFileRoute,
  Outlet,
  ParsedLocation,
  redirect,
} from '@tanstack/react-router';
import { Toaster } from '@/components/ui/toaster';
import { AuthRouteContext } from '@/auth.tsx';
import { useStore } from '@/store';
import { client } from '@/apollo';
import { Suspense, useEffect, useTransition } from 'react';

export const Route = createFileRoute('/_layout')({
  beforeLoad: async ({
    context,
    location,
  }: {
    context: AuthRouteContext;
    location: ParsedLocation;
  }) => {
    if (!context?.auth?.isAuthenticated) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: LayoutComponent,
});

function LayoutComponent() {
  const [isPending, startTransition] = useTransition();
  const { checkAuth } = useStore.user.getState();
  useEffect(() => {
    startTransition(() => {
      checkAuth(client).catch(error => {
        console.error('Authentication check failed:', error);
        // 處理認證失敗，例如重定向到登入頁面
      });
    });
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isPending ? (
        <div>Checking authentication...</div>
      ) : (
        <Layout>
          <Outlet />
          <Toaster />
        </Layout>
      )}
    </Suspense>
  );
}
