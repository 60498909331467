import { createFileRoute } from '@tanstack/react-router';
import SettingLayout from '@/components/layout/setting-layout';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { toast } from '@/components/ui/use-toast';
import { useFileMutation } from '@/tanstack';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { downloadFileFromUrl } from '@/utils/file.ts';
import { Button } from '@/components/ui/button.tsx';
import { Input } from '@/components/ui/input.tsx';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Label } from '@/components/ui/label.tsx';

export const Route = createFileRoute('/_layout/settings/import-export')({
  component: ImportExport,
});

const MAX_FILE_SIZE = 50 * 1024 * 1024;

function ImportExport() {
  const { mutate } = useFileMutation({
    method: 'POST',
    path: `/api/department/import-users`,
    contentType: 'multipart/form-data',
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setError(null);
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError('檔案大小超過 50MB 限制，請選擇較小的檔案。');
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedFile) return;
    setError(null);
    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    try {
      mutate(formData, {
        onSuccess: () => {
          toast({
            title: '上傳成功',
            description: '檔案已成功上傳。',
          });
        },
        onError: e => {
          setError((e as Error)?.message || '上傳失敗');
        },
        onSettled: () => {
          setIsUploading(false);
        },
      });
    } catch (e) {
      setError((e as Error)?.message || '上傳失敗');
    }
  };

  const handleBlankDownload = async (uri: string, filename: string) => {
    try {
      await downloadFileFromUrl(`${import.meta.env.VITE_API_URL}/${uri}`, filename);
      toast({
        title: '下載成功',
        description: `文件 "${filename}" 已成功下載。`,
      });
    } catch (error) {
      toast({
        title: '下載失敗',
        description: '無法下載文件，請稍後再試。',
        variant: 'destructive',
      });
    }
  };

  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <CardTitle>使用者 匯入/匯出</CardTitle>
          <CardDescription>
            請先設定好部門名稱，下載以下連結，取得匯入使用者的範例檔案
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>下載空白匯入資料</AccordionTrigger>
              <AccordionContent>
                <p className="py-2">請依照部門，填寫完整資料後，上傳至系統</p>
                <Button
                  size={'sm'}
                  onClick={() =>
                    handleBlankDownload(
                      'api/department/import-blank-template',
                      'import-users.xlsx',
                    )
                  }
                >
                  下載
                </Button>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>匯入資料</AccordionTrigger>
              <AccordionContent>
                <p className="py-2">
                  匯入使用者僅為新增用途，如果已經在公司內，請手動設定
                </p>
                <Label htmlFor="file" className="text-right font-normal">
                  選擇檔案
                </Label>
                <form onSubmit={handleSubmit}>
                  <div className="flex">
                    <Input
                      id="file"
                      type="file"
                      accept=".xlsx"
                      onChange={handleFileSelect}
                      className="w-60"
                      disabled={isUploading}
                    />
                    <Button type="submit" disabled={!selectedFile || isUploading}>
                      {isUploading ? '上傳中...' : '上傳'}
                    </Button>
                  </div>
                  <div>
                    {error && <div className="col-span-4 text-red-500">{error}</div>}
                  </div>
                </form>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>匯出資料</AccordionTrigger>
              <AccordionContent>
                <p className="py-2">匯出當前各部門的使用者資料</p>
                <Button
                  size={'sm'}
                  onClick={() =>
                    handleBlankDownload(
                      'api/department/export-users',
                      'export-users.xlsx',
                    )
                  }
                >
                  匯出資料
                </Button>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
