import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

/**
 * Error Link
 */
// todo user error alert component
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`,
      );
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

/**
 * Auth Link
 */
const authLink = setContext(async (_, { headers }) => {
  const accessToken = localStorage.getItem('accessToken');

  const passedHeaders = {
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {}),
    ...headers,
  };

  return {
    headers: passedHeaders,
  };
});

const httpBatchLink = new HttpLink({
  uri: `${import.meta.env.VITE_API_URL}/graphql`,
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, httpBatchLink]),
  cache: new InMemoryCache(),
});
