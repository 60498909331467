import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { User as UserIcon } from 'lucide-react';
import { User } from '@/types';

export function UserAvatar({ user }: { user: User }) {
  return (
    <Avatar className="h-8 w-8 cursor-pointer">
      {user.image ? (
        <AvatarImage alt="Picture" src={user.image} />
      ) : (
        <AvatarFallback>
          <span className="sr-only">{user.name}</span>
          <UserIcon className="h-4 w-4" />
        </AvatarFallback>
      )}
    </Avatar>
  );
}
