import { z } from 'zod';

export const resetPasswordSchema = z
  .object({
    password: z.string().min(6, { message: '密碼長度至少需要 6 個字符' }),
    confirmPassword: z.string().min(6, { message: '密碼長度至少需要 6 個字符' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "確認密碼必須與密碼相同",
    path: ["confirmPassword"], // 指定錯誤信息應該附加到哪個字段
  });
