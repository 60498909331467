import { useEffect, useState } from 'react';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { PermissionData } from '@/types';
import { cn } from '@/lib/utils';

type PermissionProps = {
  permissionConfig: PermissionData[];
  defaultPermission?: number;
  onPermissionChange: (permission: number) => void;
  className?: string;
};

const PermissionSelector = ({
  permissionConfig,
  defaultPermission,
  onPermissionChange,
  className,
}: PermissionProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState(0);

  useEffect(() => {
    setSelectedPermissions(defaultPermission || 0);
  }, [defaultPermission]);

  const handlePermissionChange = (value: number, checked: boolean) => {
    let newPermissions;
    if (checked) {
      newPermissions = selectedPermissions | value;
    } else {
      newPermissions = selectedPermissions & ~value;
    }
    setSelectedPermissions(newPermissions);
    onPermissionChange(newPermissions);
  };

  return (
    <div className={cn('grid grid-cols-2 gap-2', className)}>
      {permissionConfig.map(permission => (
        <Label
          key={permission.code}
          htmlFor={permission.code}
          className="flex p-3 w-full items-center bg-white border border-gray-200 rounded-lg text-sm cursor-pointer"
        >
          <Checkbox
            id={permission.code}
            checked={(selectedPermissions & permission.value) !== 0}
            onCheckedChange={(checked: boolean) =>
              handlePermissionChange(permission.value, checked)
            }
            className="h-4 w-4 mr-2 rounded-sm"
          />
          <span className="text-sm text-gray-500">{permission.name}</span>
        </Label>
      ))}
    </div>
  );
};

export default PermissionSelector;
