import { DataTable } from '@/components/data-table.tsx';
import { departmentColumns } from '@/pages/settings/department-settings/department-table/department-columns.tsx';
import { CardContent } from '@/components/ui/card.tsx';
import { DepartmentData } from '@/types/department.ts';
import { DepartmentsQuery, DepartmentsQueryVariables } from '@@graphql';
import { ApolloQueryResult } from '@apollo/client';

interface DepartmentTableProps {
  data: DepartmentData[];
  refetch: (
    variables?: Partial<DepartmentsQueryVariables>,
  ) => Promise<ApolloQueryResult<DepartmentsQuery>>;
}

function DepartmentTable({ data, refetch }: DepartmentTableProps) {
  return (
    <CardContent className="space-y-2">
      <DataTable data={data} columns={departmentColumns(refetch)} />
    </CardContent>
  );
}

export default DepartmentTable;
