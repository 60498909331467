import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { UserRoundPlus } from 'lucide-react';
import { Label } from './ui/label';
import Combobox from './combobox';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SetDepartmentManagerDocument, UsersDocument } from '@@graphql';
import { FormEvent, useEffect, useState } from 'react';
import { useToast } from '@/components/ui/use-toast.ts';
import { useStore } from '@/store';
import { cn } from '@/lib/utils';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { User } from '@/types';
import useDebounce from '@/hooks/useDebounce.ts';
import { useAuth } from '@/auth.tsx';

interface AdminDialogProps {
  managerIds: string[];
  departmentId?: string;
  fetchUsers?: () => Promise<void>;
}

const AdminDialog = ({ managerIds, departmentId, fetchUsers }: AdminDialogProps) => {
  const [open, setOpen] = useState(false);
  const errorObject = {
    email: '',
  };
  const currentUser = useAuth().user as User;
  const { handleError, resetErrors } = useErrorHandler(errorObject);
  const departments = useStore.home(state => state.departments);
  const departmentName = departments.find(d => d.id === departmentId)?.name;
  const { toast } = useToast();
  const [name, setName] = useState<string | undefined>('');
  const [openCombobox, setOpenCombobox] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    email: string;
  } | null>(null);
  const [usersOptions, setUsersOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const debouncedName = useDebounce(name, 500);
  const [getUsersQuery, { loading: isUsersLoading, data: usersData }] = useLazyQuery(
    UsersDocument,
    {
      fetchPolicy: 'network-only',
      onError: err => handleError(err),
    },
  );

  useEffect(() => {
    if (debouncedName && debouncedName.length >= 1 && departmentId) {
      getUsersQuery({
        variables: {
          departmentId: null,
          name: { startsWith: debouncedName },
        },
      });
    }
  }, [debouncedName, departmentId, getUsersQuery]);

  useEffect(() => {
    setUsersOptions([]);
    if (usersData?.users) {
      const users = usersData?.users || [];
      const options = users
        .filter((user: User) => ![...managerIds, currentUser.id].includes(user.id))
        .map(({ email, name }: User) => {
          return {
            value: email,
            label: `${name} (${email})`,
          };
        });
      setUsersOptions(options);
    }
  }, [usersData, managerIds, currentUser.id]);

  const [setDepartmentManagerMutation] = useMutation(SetDepartmentManagerDocument, {
    onCompleted: async () => {
      fetchUsers && (await fetchUsers());
    },
  });

  const onSubmitSetManager = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    if (!departmentId || !selectedUser) return;
    try {
      await setDepartmentManagerMutation({
        variables: {
          email: selectedUser.email,
          departmentId,
        },
      });
      toast({
        title: '新增管理者成功',
        duration: 1500,
      });
      setName('');
      setSelectedUser(null);
      setOpen(false);
    } catch (err) {
      handleError(err);
    }
  };
  const handleDialogOpenChange = (newOpen: boolean) => {
    setName('');
    setOpenCombobox(false);
    setSelectedUser(null);
    setUsersOptions([]);
    setOpen(newOpen);
  };
  const handleOnChange = (value: string | null) => {
    setSelectedUser(null);
    const selected = usersOptions.find(option => option.value === value);
    if (selected) {
      setSelectedUser({ email: selected.value });
    }
  };
  return (
    <Dialog open={open} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger asChild>
        <Button
          className={cn('gap-1 font-normal text-primary', !departmentId && 'invisible')}
          variant="ghost"
        >
          <UserRoundPlus size={20} strokeWidth={1.5} /> 新增管理者
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onSubmitSetManager}>
          <DialogHeader>
            <DialogTitle>{departmentName} 新增管理者</DialogTitle>
            <DialogDescription className="text-sm">
              新增管理者之前，必須先在「系統設定 /
              使用者管理」中建立使用者。請先完成這個步驟，然後再進行管理者的設定。
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right font-normal">
                電子郵件
              </Label>
              <div className="col-span-3">
                <Combobox
                  isLoading={isUsersLoading}
                  options={usersOptions}
                  open={openCombobox}
                  value={selectedUser?.email ?? null}
                  onChange={handleOnChange}
                  setOpen={setOpenCombobox}
                  inputValue={name}
                  onInputChange={setName}
                  label="電子郵件"
                  dropDownLabel="使用者名稱"
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default AdminDialog;
