import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { FileMimeEnum, SearchFilesDocument } from '@@graphql';
import { FileModel, RealFileModel } from '@/types';
import { formatFileSize, transFormDate, transformFileType } from '@/utils/file.ts';
import FolderView from '@/pages/folderView.tsx';
import { useCallback, useEffect, useState } from 'react';
import { SearchScope } from '@/constants';

type SearchProps = {
  q?: string;
  searchScope: SearchScope;
  mime: FileMimeEnum;
};

export const Route = createFileRoute('/_layout/search')({
  component: Search,
});

function Search() {
  const { q, searchScope, mime } = Route.useSearch() as SearchProps;
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const ocrOnly = searchScope === SearchScope.OCR;
  const { data, error, loading, refetch } = useQuery(SearchFilesDocument, {
    variables: { q: q ?? '', ocrOnly, mime },
    skip: !q,
    // 避免從搜尋結果跳過來時重複下 query
    fetchPolicy: 'cache-first',
  });

  const refetchFiles = useCallback(async (variables: any) => {
    await refetch(variables);
  }, []);

  useEffect(() => {
    if (data?.searchFiles) {
      const fileRawData = data.searchFiles as FileModel[];
      const fileData = fileRawData.map(
        ({
          name,
          ext,
          isDir,
          id,
          size,
          updatedAt,
          createdAt,
          creatorName,
          isPrivate,
        }) => {
          let source = transFormDate(createdAt);
          if (!isDir) {
            source += `-${creatorName}`;
          }
          return {
            id,
            updatedAt: transFormDate(updatedAt),
            createdAt: transFormDate(createdAt),
            creationInfo: source,
            isDir,
            name,
            type: isDir ? undefined : transformFileType(ext),
            size: formatFileSize(size),
            isPrivate,
          };
        },
      );
      setFiles(fileData);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const isDepartment = false;
  const folderName = '搜尋結果';

  return (
    <FolderView
      folderName={folderName}
      isDepartment={isDepartment}
      breadcrumbs={[]}
      refetchFolder={refetchFiles}
      files={files}
    />
  );
}
