import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function ucfirst(str: string): string {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const objectToQueryString = (obj: Record<string, any>): string => {
  const params = Object.entries(obj)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => {
      if (value instanceof Date) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toISOString())}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    });

  return params.join('&');
};

export const formatDateForFilename = (date: Date, format = 'YYYY-MM-DD HHmmss') => {
  return dayjs(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format);
};
