import { Move } from '@/components/icons';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useParams } from '@tanstack/react-router';
import { useMutation } from '@apollo/client';
import { MoveFilesDocument } from '@@graphql';
import MoveForm from '@/pages/edit-actions/move-form.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';

export const MoveAction = ({
  selectedFileIds,
  refetchFolder,
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const { handleError } = useErrorHandler({});
  const { folderId: fileId } = useParams({ strict: false });
  const [moveFileMutation] = useMutation(MoveFilesDocument, {
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      closeDialog();
    },
  });
  const handleMove = async () => {
    if (fileId) {
      openDialog(
        '搬移',
        <MoveForm
          selectedFileIds={selectedFileIds}
          fileId={fileId}
          onSubmit={async ({ fileIds, targetFolderId }) => {
            try {
              await moveFileMutation({
                variables: {
                  fileIds,
                  targetFolderId,
                },
              });
              toast({
                title: '搬移成功',
              });
            } catch (err) {
              handleError(err);
            }
          }}
        />,
      );
    }
  };

  return {
    title: ACTION_TYPE_TITLE.MOVE,
    icon: <Move />,
    onClick: handleMove,
  };
};
