import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Trash2 } from 'lucide-react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { DepartmentsQuery, DepartmentsQueryVariables, RemoveDepartmentDocument } from '@@graphql';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';

interface RemoveDepartmentDialogProps {
  department: string;
  id: string;
  refetch?: (
    variables?: Partial<DepartmentsQueryVariables>,
  ) => Promise<ApolloQueryResult<DepartmentsQuery>>;
  canRemove?: boolean;
}

const RemoveDepartmentDialog = ({
  department,
  id: departmentId,
  refetch,
  canRemove,
}: RemoveDepartmentDialogProps) => {
  const { handleError } = useErrorHandler({});
  const [removeDepartment] = useMutation(RemoveDepartmentDocument, {
    onError: (err) => handleError(err),
    variables: {
      departmentId,
    },
    onCompleted: async () => {
      await refetch?.();
    }
  });
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          className="size-8 text-error hover:text-error"
          variant="ghost"
          size="icon"
          disabled={!canRemove}
        >
          <Trash2 size={20} strokeWidth={1.5} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>刪除部門</AlertDialogTitle>
          <AlertDialogDescription>
            {`確定要刪除『${department}』?`}
            <br />
            <span className="text-red-500">
              注意：若部門內（包含資源回收桶）仍有相關資料，系統將無法執行刪除操作。
            </span>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>取消</AlertDialogCancel>
          <AlertDialogAction
            onClick={async() => await removeDepartment()}
          >
            確定
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveDepartmentDialog;
