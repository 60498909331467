import { useState, useEffect } from 'react';
import { Button, ButtonProps } from '@/components/ui/button';
import { CheckIcon, CopyIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface CopyButtonProps extends ButtonProps {
  value: string;
}

const copyToClipboardWithMeta = (value: string) => {
  navigator.clipboard.writeText(value);
};

const CopyButton = ({
  value,
  variant = 'secondary',
  className,
  ...props
}: CopyButtonProps) => {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }, [hasCopied]);

  return (
    <Button
      size="icon"
      title="複製"
      variant={variant}
      className={cn(
        'relative z-10 size-[40px] p-2.5 text-primary bg-primary-100 hover:bg-primary-200 [&_svg]:h-8 [&_svg]:w-8',
        className,
      )}
      onClick={() => {
        copyToClipboardWithMeta(value);
        setHasCopied(true);
      }}
      {...props}
    >
      <span className="sr-only">Copy</span>
      {hasCopied ? <CheckIcon /> : <CopyIcon />}
    </Button>
  );
};

export default CopyButton;
