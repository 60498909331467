import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import PasswordInput from '@/components/PasswordInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import LandingLayout from '@/components/layout/landing-layout.tsx';
import { onboardSchema } from '@/zodSchema/onboard.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { OnboardDocument } from '@@graphql';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { decodeJWT } from '@/lib/utils.tsx';
import LinkExpired from '@/components/link-expired.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';

type JwtPayload = {
  email: string;
  token: string;
  name: string;
  iat: number;
  exp: number;
};

const SuccessDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>密碼已更新</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">密碼已設定完成，請使用新密碼進行登入</div>
      </DialogContent>
    </Dialog>
  );
};

function Onboarding() {
  const search = Route.useSearch() as { token: string };
  const { handleError, resetErrors, errors } = useErrorHandler({
    password: '',
    confirmPassword: '',
  });
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [resetPasswordMutation] = useMutation(OnboardDocument, {
    onError: err => handleError(err),
    onCompleted: () => setIsSuccessDialogOpen(true),
  });
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    confirmPassword: '',
  });
  const [isDone, setIsDone] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (!search.token) {
      setIsTokenValid(false);
      return;
    }

    try {
      const payload = decodeJWT(search.token) as JwtPayload;
      if (payload.exp && payload.exp < Date.now() / 1000) {
        setIsTokenValid(false);
      }
      setEmail(payload.email);
      setFormData(prevData => ({ ...prevData, name: payload.name }));
    } catch (error) {
      setIsTokenValid(false);
    }
  }, [search.token]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    resetErrors();
    try {
      onboardSchema.parse(formData);
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const resetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    await resetPasswordMutation({
      variables: {
        name: formData.name,
        password: formData.password,
        token: search.token,
      },
    });
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    setIsDone(true);
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  };
  if (!isTokenValid) {
    return <LinkExpired />;
  }

  return (
    <LandingLayout className="items-center h-[680px]">
      <div className="w-full max-w-md bg-white rounded-3xl px-12">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl font-bold text-center mb-4 text-neutral-900">
          設定帳號密碼
        </h1>
        <p className="text-center text-neutral-500 mb-6">
          歡迎使用 Docubank，請為您的帳號設定密碼，設定完成後即可登入系統
        </p>
        <div className="space-y-6">
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-sm text-neutral-400 mb-1">帳號 (電子郵件)</p>
            <p className="text-sm font-medium text-neutral-900">{email}</p>
          </div>
          <form onSubmit={resetPassword} className="space-y-6">
            <div>
              <Label htmlFor="name" className="font-normal">
                使用者名稱
              </Label>
              <Input
                autoComplete="username"
                id="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
                className="pr-10 py-6 text-neutral-400 border-neutral-300"
                placeholder="請輸入名稱"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>

            <div>
              <PasswordInput
                onChange={handleInputChange}
                value={formData.password}
                id="password"
                placeholder="設定密碼"
                className="py-6 text-neutral-400 border-neutral-300"
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>
            <div>
              <PasswordInput
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="py-6 text-neutral-400 border-neutral-300"
                placeholder="確認密碼"
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
              )}
            </div>
            <Button
              type="submit"
              className="w-full py-3 rounded-lg font-semibold bg-indigo-600 hover:bg-indigo-700 text-white"
              disabled={isDone}
            >
              完成設定
            </Button>
          </form>
        </div>
      </div>
      <SuccessDialog isOpen={isSuccessDialogOpen} onClose={handleSuccessDialogClose} />
    </LandingLayout>
  );
}

export const Route = createFileRoute('/onboarding')({
  component: Onboarding,
});
