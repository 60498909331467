export enum DialogActionType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export enum SearchScope {
  FILE_NAME = 'fileName',
  OCR = 'ocr',
}

export enum SearchMime {
  ALL = 'ALL',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  OFFICE = 'OFFICE',
}

export const DEFAULT_CLICK_EVENT_MAPPING: Record<
  string,
  keyof React.DOMAttributes<HTMLElement>
> = {
  mousedown: 'onMouseDown',
  touchstart: 'onTouchStart',
};

export const DEFAULT_CLICK_EVENTS = Object.keys(DEFAULT_CLICK_EVENT_MAPPING) as Array<
  keyof typeof DEFAULT_CLICK_EVENT_MAPPING
>;

export const TAKE_PER_PAGE = 20;

export enum ACTION_TYPE_TITLE {
  CREATE_FOLDER = 'create folder',
  DELETE = 'delete',
  DELETE_PERMANENTLY = 'delete-permanently',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  MOVE = 'move',
  UPLOAD = 'upload',
  DOWNLOAD = 'download',
  PIN = 'pin',
  UNPIN = 'unpin',
  RESTORE = 'restore',
  REVOKE_PRIVATE = 'revoke-private',
  SHARE = 'share',
  VIEW = 'view',
  SET_PRIVATE = 'set-private',
}

export const ACTION_TYPE_TITLE_LABEL_MAP: Record<ACTION_TYPE_TITLE, string> = {
  [ACTION_TYPE_TITLE.CREATE_FOLDER]: '新增資料夾',
  [ACTION_TYPE_TITLE.DELETE]: '刪除',
  [ACTION_TYPE_TITLE.DELETE_PERMANENTLY]: '永久刪除',
  [ACTION_TYPE_TITLE.DUPLICATE]: '複製',
  [ACTION_TYPE_TITLE.EDIT]: '重新命名',
  [ACTION_TYPE_TITLE.MOVE]: '移動',
  [ACTION_TYPE_TITLE.UPLOAD]: '上傳',
  [ACTION_TYPE_TITLE.DOWNLOAD]: '下載',
  [ACTION_TYPE_TITLE.PIN]: '置頂',
  [ACTION_TYPE_TITLE.UNPIN]: '取消置頂',
  [ACTION_TYPE_TITLE.RESTORE]: '復原',
  [ACTION_TYPE_TITLE.REVOKE_PRIVATE]: '解除機敏',
  [ACTION_TYPE_TITLE.SHARE]: '分享',
  [ACTION_TYPE_TITLE.VIEW]: '切換檢視模式',
  [ACTION_TYPE_TITLE.SET_PRIVATE]: '設為機敏',
};
