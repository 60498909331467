import { useState } from 'react';
import { SideNav } from '@/components/layout/side-nav';
import { cn } from '@/lib/utils';
import { useSidebar } from '@/hooks/useSidebar';
import { FolderClose } from '@/components/icons';
import { useStore } from '@/store';

interface SidebarProps {
  className?: string;
}

export default function Sidebar({ className }: SidebarProps) {
  const { isOpen } = useSidebar();
  const [status, setStatus] = useState(false);
  const departments = useStore.home((state) => state.departments);
  const navItems =
    departments.map(({ id, name: title, departmentId }) => {
      return {
        title,
        icon: <FolderClose />,
        href: id,
        departmentId,
      };
    }) ?? [];

  return (
    <nav
      className={cn(
        `relative hidden border-r pt-20 md:block overflow-y-auto`,
        status && 'duration-500',
        isOpen ? 'w-60' : 'w-[78px]',
        className,
      )}
    >
      <div className="px-3 pb-4">
        <SideNav
          className="text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100"
          items={navItems}
          setStatus={setStatus}
        />
      </div>
    </nav>
  );
}
