import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Control, UseFormSetValue } from 'react-hook-form';
import { SurveyData, KEYWORD_LABEL_NAME, GarnishmentStatus, Keyword } from './shared';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Input } from '@/components/ui/input';
import { FILTER_KEYWORDS_BY_TEMPLATE } from './shared';
import { cn } from '@/lib/utils';

const KeywordSection = ({
  templateId,
  control,
  fields,
  fieldArrayName,
  setValue,
}: {
  templateId: string;
  control: Control<SurveyData>;
  fields: Keyword[];
  fieldArrayName: 'AIKeywords' | 'manualKeywords';
  setValue: UseFormSetValue<SurveyData>;
}) => {
  const filterKeywords = FILTER_KEYWORDS_BY_TEMPLATE[templateId as GarnishmentStatus];
  return (
    <div className="space-y-2">
      {fields.map((field, index) => (
        <div
          key={field.label}
          className={cn('flex gap-2', filterKeywords.includes(field.label) && 'hidden')}
        >
          <FormField
            control={control}
            name={`${fieldArrayName}.${index}.used`}
            render={({ field: checkboxField }) => (
              <FormItem className="flex items-center">
                <FormControl>
                  <Checkbox
                    id={`${fieldArrayName}.${index}.used`}
                    className="size-5"
                    checked={!!checkboxField.value}
                    onCheckedChange={checked => {
                      checkboxField.onChange(checked);
                      if (fieldArrayName === 'AIKeywords') {
                        setValue(`manualKeywords.${index}.used`, !checked);
                      }
                      if (fieldArrayName === 'manualKeywords') {
                        setValue(`AIKeywords.${index}.used`, !checked);
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`${fieldArrayName}.${index}.value`}
            render={({ field: inputField }) => {
              const isMonthlySalary = field.label === 'monthlySalary';
              return (
                <FormItem>
                  <FormControl>
                    <Input
                      className="w-[200px] h-[30px]"
                      {...(isMonthlySalary
                        ? {
                            type: 'number',
                            min: '0',
                          }
                        : {})}
                      {...inputField}
                      onChange={event => {
                        inputField.onChange(
                          isMonthlySalary ? +event.target.value : event.target.value,
                        );
                      }}
                      disabled={fieldArrayName === 'AIKeywords'}
                      placeholder={
                        fieldArrayName === 'manualKeywords'
                          ? `請輸入${
                              KEYWORD_LABEL_NAME[
                                field.label as keyof typeof KEYWORD_LABEL_NAME
                              ]
                            }`
                          : '無資料'
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default KeywordSection;
