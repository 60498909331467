import React, { useCallback, useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  loadMore: (isInitial: boolean) => void;
  hasMore: boolean;
  isLoading: boolean;
  children: React.ReactNode;
}

const InfiniteScrollWrapper: React.FC<InfiniteScrollProps> = ({
  loadMore,
  hasMore,
  isLoading,
  children,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer && !isLoading && hasMore) {
      const { scrollHeight, scrollTop, clientHeight } = scrollContainer;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 50;

      if (isNearBottom) {
        loadMore(false);
      }
    }
  }, [hasMore, isLoading, loadMore]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <div ref={scrollContainerRef} className="h-[calc(100vh-70px)] overflow-y-auto">
      {children}
      {isLoading && <div>Loading more...</div>}
    </div>
  );
};

export default InfiniteScrollWrapper;
