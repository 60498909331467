import { Edit } from '@/components/icons';
import { Label } from '@/components/ui/label.tsx';
import { Input } from '@/components/ui/input.tsx';
import { DialogClose, DialogFooter } from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';
import { useMutation, useLazyQuery, handleError } from '@apollo/client';
import { RenameFileDocument, GetFileDocument } from '@@graphql';
import { FormEvent, useRef, useState } from 'react';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';

export const EditAction = ({
  selectedFileIds,
  refetchFolder,
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const { toast } = useToast();
  const { handleError } = useErrorHandler({});

  const [getFile] = useLazyQuery(GetFileDocument, {
    fetchPolicy: 'network-only',
    onError: err => handleError(err),
  });

  const [renameFileMutation] = useMutation(RenameFileDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      toast({
        title: '編輯成功',
      });
      refetchFolder && (await refetchFolder());
      closeDialog();
    },
  });

  const handleEdit = async () => {
    if (selectedFileIds.length > 1) {
      toast({
        title: '編輯失敗',
        description: '一次只能夠編輯一個檔案',
        duration: 1500,
      });
      return;
    }

    const { data: fileData } = await getFile({
      variables: { fileId: selectedFileIds[0] },
    });

    const EditForm = () => {
      const formRef = useRef<HTMLFormElement>(null);
      const [isValid, setIsValid] = useState(true);

      const handleChange = () => {
        if (formRef.current) {
          setIsValid(formRef.current.checkValidity());
        }
      };

      return (
        <form ref={formRef} onSubmit={handleSave}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right font-normal">
                新名稱
              </Label>
              <Input
                id="newName"
                name="newName"
                className="col-span-3"
                defaultValue={fileData?.file?.name}
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit" disabled={!isValid}>
              儲存
            </Button>
          </DialogFooter>
        </form>
      );
    };

    openDialog('重新命名', <EditForm />);
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const newNameFieldValue = data.get('newName');
    if (!(selectedFileIds[0] && newNameFieldValue)) return;
    await renameFileMutation({
      variables: {
        fileId: selectedFileIds[0],
        newName: newNameFieldValue.toString(),
      },
    });
  };

  return {
    title: ACTION_TYPE_TITLE.EDIT,
    icon: <Edit />,
    onClick: handleEdit,
  };
};
