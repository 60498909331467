import { createRootRoute, Outlet } from '@tanstack/react-router';
import '../index.css';

const rootRoute = createRootRoute({
  component: () => {
    return (
      <>
        <Outlet />
      </>
    );
  },
});

export const Route = rootRoute;
