import { createFileRoute } from '@tanstack/react-router';
import { useMutation, useQuery } from '@apollo/client';
import { ChangeCompanyDocument, GroupOfCompaniesDocument } from '@@graphql';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useStore } from '@/store';
import { useMemo, useState } from 'react';
import Loading from '@/components/loading';
import { Card } from '@/components/ui/card.tsx';
import ProfileLayout from '@/components/layout/profile-layout.tsx';
import { User } from '@/types';
import { useAuth } from '@/auth.tsx';
import { UserRoundCheck } from 'lucide-react';
import { Button } from '@/components/ui/button.tsx';

type Company = {
  name: string;
  id: string;
};

function ChangeCompany() {
  const user = useAuth().user as User;
  const { id: companyId } = user.company || {};
  const { setUser } = useStore.user.getState();
  const { handleError } = useErrorHandler({});
  const [selected, setSelected] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const { data, error, loading } = useQuery(GroupOfCompaniesDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const sortedCompanies = useMemo(() => {
    if (!data?.groupOfCompanies) return [];
    const companies = data.groupOfCompanies as Company[];
    const matchedCompany = companies.find(
      company => company.id === companyId,
    ) as Company;
    const otherCompanies = companies.filter(company => company.id !== companyId);
    return [matchedCompany, ...otherCompanies];
  }, [data?.groupOfCompanies]);
  const [changeCompanyMutation] = useMutation(ChangeCompanyDocument, {
    onError: err => handleError(err),
  });

  const handleCompanySelect = async (companyId: string) => {
    setSelected(true);
    try {
      const { data, errors } = await changeCompanyMutation({
        variables: { companyId },
      });
      if (errors) {
        handleError(errors);
        setSelected(false);
        return;
      }
      const { accessToken, user } = data.changeCompany;
      setRedirecting(true);
      setTimeout(() => {
        setUser(user);
        localStorage.removeItem('accessToken');
        localStorage.setItem('accessToken', accessToken);
        window.location.replace('/');
      }, 1000);
    } catch (error) {
      handleError(error);
      setSelected(false);
    }
  };
  if (loading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;
  if (!sortedCompanies.length) return <div>無分點公司，無法使用此功能</div>;
  if (redirecting) return <Loading />;
  return (
    <ProfileLayout>
      <Card className="flex items-center justify-center w-full px-2 py-6 md:w-1/3">
        <div className="w-full max-w-md p-8 bg-white rounded-3xl">
          <h1 className="mb-4 text-2xl font-semibold text-center">切換公司</h1>
          <p className="mb-6 text-center text-gray-500">請選擇您要登入的公司</p>
          <div className="space-y-4">
            {sortedCompanies.map(({ name, id }: { name: string; id: string }) => (
              <Button
                key={id}
                disabled={selected}
                className={`w-full py-3 rounded-lg font-semibold ${
                  id === companyId
                    ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                    : 'text-white'
                }`}
                onClick={() => handleCompanySelect(id)}
              >
                {id === user.company?.id && <UserRoundCheck />}
                {name}
              </Button>
            ))}
          </div>
        </div>
      </Card>
    </ProfileLayout>
  );
}

export const Route = createFileRoute('/_layout/profile/change-company')({
  component: ChangeCompany,
});
