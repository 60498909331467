import { Switch } from '@/components/ui/switch.tsx';
import { useMutation, QueryOptions } from '@apollo/client';
import { CreateFtpAccountDocument, FtpAccountType } from '@@graphql';
import { useToast } from './ui/use-toast';
import { useErrorHandler } from '@/hooks/useErrorHandler';

type EnableFtpSwitchProps = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  departmentId?: string;
  setCurrentFtpPassword: (password: string) => void;
  refetchQueries: QueryOptions<{ [key: string]: string }>[];
};

const EnableFtpSwitch = ({
  checked,
  onCheckedChange,
  departmentId,
  setCurrentFtpPassword,
  refetchQueries,
}: EnableFtpSwitchProps) => {
  const { toast } = useToast();
  const { handleError } = useErrorHandler({});

  const [createFtpAccount] = useMutation(CreateFtpAccountDocument, {
    refetchQueries,
    onCompleted: ({ createFtpAccount }) => {
      setCurrentFtpPassword(createFtpAccount.plainPassword);
      toast({
        title: '新增 FTP 帳號成功',
        duration: 1500,
      });
    },
    onError: err => {
      handleError(err);
      toast({
        title: '新增 FTP 帳號失敗',
        duration: 1500,
      });
    },
  });

  const onChange = async (checked: boolean) => {
    onCheckedChange(checked);
    await createFtpAccount({
      variables: {
        accountType: departmentId ? FtpAccountType.Split : FtpAccountType.Archive,
        ...(departmentId ? { fileId: departmentId } : {}),
      },
    });
  };

  return (
    <div className="flex items-center gap-2 mb-6">
      <Switch id="showFtp" checked={checked} onCheckedChange={onChange} />
      <div>{`啟用${departmentId ? '部門' : '公司'} FTP`}</div>
    </div>
  );
};

export default EnableFtpSwitch;
