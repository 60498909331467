import { useMutation } from '@apollo/client';
import { RestoreFilesDocument } from '@@graphql';
import { Restore } from '@/components/icons';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useToast } from '@/components/ui/use-toast.ts';
import { ACTION_TYPE_TITLE } from '@/constants';
import {
  DialogClose,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';

export const RestoreAction = ({
  selectedFileIds,
  refetchFolder,
  closeDialog,
  openDialog,
}: ActionProps): ActionButton => {
  const { handleError, resetErrors } = useErrorHandler({});
  const { toast } = useToast();

  const [restoreFiles] = useMutation(RestoreFilesDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      toast({
        title: `復原檔案成功`,
        duration: 1000,
      });
      closeDialog();
    },
  });

  const handleRestore = async () => {
    openDialog(
      '還原檔案',
      <div>
        <DialogDescription className="text-sm">
          只有該部門具有還原權限的使用者可以進行還原
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            type="button"
            onClick={() => {
              resetErrors();
              restoreFiles({
                variables: { fileIds: selectedFileIds },
              });
            }}
          >
            確定還原
          </Button>
        </DialogFooter>
      </div>,
    );
  };

  return {
    title: ACTION_TYPE_TITLE.RESTORE,
    icon: <Restore />,
    onClick: handleRestore,
  };
};
