import { createFileRoute } from '@tanstack/react-router';
import SettingLayout from '@/components/layout/setting-layout';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { toast } from '@/components/ui/use-toast';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import {
  InfoInput,
  SetWaterMarkDocument,
  WaterMarkDisplayEnum,
  WaterMarkDocument,
  WaterMarkInfoColumnEnum,
  WaterMarkModel,
} from '@/graphql/generated/.graphql';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useEffect, useMemo } from 'react';

export const Route = createFileRoute('/_layout/settings/water-mark-settings')({
  component: WaterMarkSettings,
});

const INFOS_OPTION = [
  {
    id: WaterMarkInfoColumnEnum.Department,
    label: '部門名稱',
  },
  {
    id: WaterMarkInfoColumnEnum.Ip,
    label: 'IP',
  },
  {
    id: WaterMarkInfoColumnEnum.Name,
    label: '姓名',
  },
  {
    id: WaterMarkInfoColumnEnum.Timestamp,
    label: '時間',
  },
] as const;

const CUSTOM_TEXT_LIMIT = 8;
const displayFormSchema = z.object({
  display: z.string().optional(),
  info: z
    .array(
      z.object({
        column: z.string(),
        value: z.string(),
      }),
    )
    .optional(),
  custom: z
    .string()
    .max(CUSTOM_TEXT_LIMIT, { message: `自訂浮水印文字超過 ${CUSTOM_TEXT_LIMIT} 個字` })
    .optional(),
});

type DisplayFormValues = z.infer<typeof displayFormSchema>;

const getDefaultValues = (warterMark?: WaterMarkModel) => {
  const infos = warterMark?.info || [];
  return {
    display: warterMark?.display || WaterMarkDisplayEnum.Disable,
    info: infos || [],
    custom:
      infos.find(
        (i: { column: WaterMarkInfoColumnEnum; value: string }) =>
          i.column === WaterMarkInfoColumnEnum.Custom,
      )?.value || '',
  };
};

function WaterMarkSettings() {
  const {
    data: waterMarkData,
    error,
    loading,
    refetch,
  } = useQuery(WaterMarkDocument, {
    fetchPolicy: 'network-only',
  });
  const [setWaterMarkMutation] = useMutation(SetWaterMarkDocument, {
    onCompleted: async () => {
      toast({
        title: '設定浮水印成功',
        duration: 1500,
      });
      await refetch();
    },
    onError: async () => {
      toast({
        title: '設定浮水印失敗',
        duration: 1500,
      });
    },
  });

  const waterMark = waterMarkData?.waterMark;

  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: useMemo(() => getDefaultValues(waterMarkData), [waterMark]),
  });
  const { watch, setValue, reset, control } = form;
  const display = watch('display');
  const custom = watch('custom');
  const customTextLength = custom?.length || 0;

  useEffect(() => {
    if (waterMark) {
      reset(getDefaultValues(waterMark));
    }
  }, [waterMark, reset]);

  const onSubmit = async (data: DisplayFormValues) => {
    const infoData = data?.info || [];
    const info = infoData.map(i =>
      i.column === WaterMarkInfoColumnEnum.Custom ? { ...i, value: data?.custom } : i,
    );
    await setWaterMarkMutation({
      variables: {
        display: data?.display,
        info,
      },
    });
  };

  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <CardTitle>文件浮水印設定</CardTitle>
          <CardDescription>
            可以設定浮水印相關資訊，下載檔案後、自動將浮水印顯示在文件背景，用於強化文件的安全性。
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={control}
                name="display"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-base">浮水印顯示</FormLabel>
                    <div className="w-[200px]">
                      <FormControl>
                        <Select
                          value={display}
                          onValueChange={value => {
                            setValue('display', value);
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="選擇類型" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={WaterMarkDisplayEnum.Disable}>
                              隱藏浮水印
                            </SelectItem>
                            <SelectItem value={WaterMarkDisplayEnum.Text}>
                              文字浮水印
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="info"
                render={({ field }) => {
                  const infos = field.value as InfoInput[];
                  return (
                    <FormItem>
                      <div className="mb-4">
                        <FormLabel className="text-base">浮水印資訊</FormLabel>
                        <FormDescription>勾選後，浮水印將顯示以下資訊</FormDescription>
                      </div>
                      {INFOS_OPTION.map(info => (
                        <FormItem
                          key={info.id}
                          className="flex flex-row items-center space-x-3 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={infos.some(
                                item =>
                                  item.column === info.id && item.value === 'true',
                              )}
                              onCheckedChange={checked => {
                                const updatedInfos = infos.map(item =>
                                  item.column === info.id
                                    ? { ...item, value: checked ? 'true' : 'false' }
                                    : item,
                                );
                                if (
                                  checked &&
                                  !infos.some(item => item.column === info.id)
                                ) {
                                  updatedInfos.push({ column: info.id, value: 'true' });
                                }
                                field.onChange(updatedInfos);
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">{info.label}</FormLabel>
                        </FormItem>
                      ))}
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="custom"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-base">自訂浮水印文字</FormLabel>
                    <FormDescription>
                      可以自訂浮水印文字，限制 {CUSTOM_TEXT_LIMIT} 個字
                    </FormDescription>
                    <FormControl>
                      <Input
                        className="w-[200px]"
                        placeholder="請輸入自訂文字"
                        value={custom}
                        onChange={e => {
                          setValue('custom',  e.target.value);
                        }}
                      />
                    </FormControl>
                    <p
                      className={cn(
                        'w-[200px] mt-2 text-gray-500 text-sm text-right',
                        customTextLength > CUSTOM_TEXT_LIMIT && 'text-error',
                      )}
                    >
                      {customTextLength} / {CUSTOM_TEXT_LIMIT} 文字已使用
                    </p>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">儲存變更</Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
