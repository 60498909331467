import { FileOcrModel } from '@/types';
import { useFileQuery } from '@/tanstack';
import { useRouter } from '@tanstack/react-router';
import { Button } from '@/components/ui/button.tsx';
import { AlarmClockCheck, CircleX, Lock, LockOpen } from 'lucide-react';
import {
  ComponentType,
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useState,
} from 'react';
import OcrViewer from '@/components/viewer/ocr-viewer.tsx';
import {
  FileExpiredModel,
  GetFileQuery,
  GetFileQueryVariables,
  SetFileExpiredAtDocument,
  ToggleFilesPrivateDocument,
} from '@@graphql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useWindowSize } from '@react-hook/window-size';
import { Switch } from '@/components/ui/switch.tsx';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import dayjs from 'dayjs';
import { cn } from '@/lib/utils';
import { Calendar } from '@/components/ui/calendar';
import { DATE_FORMAT } from '@/constants';
import GeneratePleadingDialog from './generate-pleading-dialog';
import { LegalType } from '@/constants/files';
import { useStore } from '@/store';
import { useAuth } from '@/auth';

interface FileViewProps {
  fileUrl: string;
  ocr?: FileOcrModel;
  name?: string;
  legalType?: string;
  createdAt: Date;
  ext?: string | undefined;
  expired?: FileExpiredModel;
  fileId: string;
  isPrivate?: boolean;
  refetchFile?: (
    variables?: Partial<GetFileQueryVariables>,
  ) => Promise<ApolloQueryResult<GetFileQuery>>;
}

export interface FileViewerProps {
  fileUrl?: string;
  file?: Blob | undefined;
}

type ViewerComponentType = LazyExoticComponent<ComponentType<FileViewerProps>>;

const importComponent = (ext?: string) => {
  if (!ext) return lazy(() => import('@/components/viewer/unknown-viewer.tsx'));
  const pureExt = ext.replace('.', '').toLowerCase();
  const imageTypes = ['gif', 'jpeg', 'png', 'bmp', 'webp', 'jpg'];
  if (imageTypes.includes(pureExt))
    return lazy(
      () =>
        import('@/components/viewer/image-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  if (pureExt === 'pdf')
    return lazy(
      () =>
        import('@/components/viewer/pdf-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  return lazy(
    () =>
      import('@/components/viewer/unknown-viewer.tsx') as Promise<{
        default: ComponentType<FileViewerProps>;
      }>,
  );
  //   todo other types
};

enum ViewerType {
  FILE = 'file',
  OCR = 'ocr',
}

const FileView = ({
  fileUrl,
  ocr,
  ext,
  fileId,
  refetchFile,
  expired,
  legalType,
  createdAt,
  isPrivate,
}: FileViewProps) => {
  const [ViewerComponent, setViewerComponent] = useState<ViewerComponentType | null>(
    null,
  );
  const router = useRouter();
  const { user } = useAuth();
  const [width] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [mobileViewer, setMobileViewer] = useState(ViewerType.FILE);
  const defaultExpectedAt = expired ? dayjs(expired?.expectedAt).toDate() : undefined;
  const [expectedAt, setExpectedAt] = useState<Date | undefined>(defaultExpectedAt);
  const [setFileExpiredAt] = useMutation(SetFileExpiredAtDocument);
  const [setFilePrivate] = useMutation(ToggleFilesPrivateDocument);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const getCompanyConfig = useStore.companyConfig(state => state.getConfigValue);
  const roles = getCompanyConfig('roles');

  const canGeneratePleading =
    user?.isAdmin ||
    Boolean(
      user?.assignments?.find(
        assignment =>
          roles.find(role => role.canCreateComplaint)?.id === assignment.role.id,
      ),
    );

  const hasPleading =
    legalType === LegalType.LEGAL_PLEADING_COURT ||
    legalType === LegalType.LEGAL_PLEADING_AED;

  const canControlPrivate =
    user?.isAdmin ||
    Boolean(
      user?.assignments?.find(
        assignment =>
          roles.find(role => role.canControlPrivateFile)?.id === assignment.role.id,
      ),
    );

  const onChangeCalendar = async (date: Date | undefined) => {
    if (date) setCalendarOpen(false);
    setExpectedAt(date);
    await setFileExpiredAt({
      variables: {
        fileId,
        expectedAt: date ? dayjs(date).format() : null,
      },
    });
  };

  useEffect(() => {
    if (width > 1440) {
      setMobileViewer(ViewerType.FILE);
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  const { data } = useFileQuery(fileUrl);

  useEffect(() => {
    setViewerComponent(() => importComponent(ext));
  }, [ext]);

  if (!ViewerComponent) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading viewer...</div>}>
      <section>
        <div className="lg:hidden grid grid-cols-3 items-center">
          <Button className="m-2" onClick={() => router.history.back()}>
            回上一頁
          </Button>
          <div className="grid grid-cols-3">
            <div>File</div>
            <Switch
              onCheckedChange={isChecked => {
                setMobileViewer(isChecked ? ViewerType.OCR : ViewerType.FILE);
              }}
              checked={mobileViewer === ViewerType.OCR}
            />
            <div>OCR</div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-2 mt-4">
            {isMobile && mobileViewer === ViewerType.FILE && (
              <ViewerComponent file={data} fileUrl={fileUrl} />
            )}
            {!isMobile && <ViewerComponent file={data} fileUrl={fileUrl} />}
          </div>
          <div className="col-span-1">
            <div className="hidden md:flex md:flex-col md:justify-start md:mr-6 md:p-4 md:gap-2 md:absolute lg:static md:top-[28px] md:right-[-30px]">
              {!isMobile && (
                <>
                  {hasPleading && canGeneratePleading && (
                    <GeneratePleadingDialog fileId={fileId} createdAt={createdAt} />
                  )}
                  {canControlPrivate && (
                    <Button
                      variant="outline"
                      size="sm"
                      className={cn('flex items-center gap-1 bg-white')}
                      onClick={async () => {
                        await setFilePrivate({
                          variables: {
                            fileIds: [fileId],
                            isPrivate: !isPrivate,
                          },
                        });
                        refetchFile?.({ fileId });
                      }}
                    >
                      {isPrivate ? (
                        <Lock className="h-4 w-4" />
                      ) : (
                        <LockOpen className="h-4 w-4" />
                      )}
                      {isPrivate ? '取消機敏文件' : '設為機敏文件'}
                    </Button>
                  )}
                </>
              )}
              <p className="text-sm font-medium">提醒時間</p>
              <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className={cn(
                      'w-[160px] pl-3 text-left font-normal',
                      expectedAt ? 'text-muted-foreground bg-primary-100' : 'bg-white',
                    )}
                  >
                    {expectedAt ? (
                      dayjs(expectedAt).format(DATE_FORMAT)
                    ) : (
                      <span>請選擇提醒時間</span>
                    )}
                    {expectedAt ? (
                      <CircleX
                        className="ml-auto h-4 w-4 opacity-50"
                        onClick={() => onChangeCalendar(undefined)}
                      />
                    ) : (
                      <AlarmClockCheck className="ml-auto h-4 w-4 opacity-50" />
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={expectedAt}
                    onSelect={onChangeCalendar}
                    disabled={date => date < new Date()}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <p className="text-sm text-muted-foreground hidden lg:block">
                系統將在提醒日期當天的 00:00 自動發送電子郵件通知
              </p>
            </div>
            {isMobile && mobileViewer === ViewerType.OCR && (
              <OcrViewer
                fileId={fileId}
                refetchFile={refetchFile}
                ocrText={ocr?.ocrText}
              />
            )}
            {!isMobile && (
              <OcrViewer
                fileId={fileId}
                refetchFile={refetchFile}
                ocrText={ocr?.ocrText}
              />
            )}
          </div>
        </div>
      </section>
    </Suspense>
  );
};

export default FileView;
