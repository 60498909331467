import { RegisteredRouter } from '@tanstack/react-router';


interface goToFolderProps {
  router: RegisteredRouter;
  folderId: string;
}

interface goToFileProps {
  router: RegisteredRouter;
  fileId: string;
}

export const goToFolder = async ({ router, folderId }: goToFolderProps) => {
  await router.navigate({
      to: `/folders/$folderId`,
      params: {
        folderId
      }
    }
  );
};

export const goToFile = async ({ router, fileId}: goToFileProps) => {
  await router.navigate({
      to: `/files/$fileId`,
      params: {
        fileId
      }
    }
  );
};


export const gotoTrashFile = async ({ router, fileId}: goToFileProps) => {
  await router.navigate({
      to: `/trash/$fileId`,
      params: {
        fileId
      }
    }
  );
};
