import { useUserStore } from './user';
import { useHomeStore } from './home.ts';
import { useCompanyConfigStore } from './company-config';
export interface Store {
  user: typeof useUserStore;
  home: typeof useHomeStore;
  companyConfig: typeof useCompanyConfigStore;
}
export const useStore: Store = {
  user: useUserStore,
  home: useHomeStore,
  companyConfig: useCompanyConfigStore,
};
