import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { FileExpiredModel, GetFileDocument } from '@@graphql';
import FileView from '@/pages/fileView.tsx';
import { FileModel } from '@/types';

export const Route = createFileRoute('/_layout/files/$fileId')({
  component: FileComponent,
});

function FileComponent() {
  const { fileId } = Route.useParams();
  const { data, error, loading, refetch } = useQuery(GetFileDocument, {
    variables: { fileId },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;
  const { ext, name, expired, fileUrl, fileOcr, legalType, createdAt, isPrivate } =
    data?.file as FileModel;
  if (!fileUrl) return <div>File not found</div>;

  return (
    <FileView
      refetchFile={refetch}
      fileId={fileId}
      fileUrl={fileUrl}
      name={name}
      ext={ext}
      expired={expired as FileExpiredModel | undefined}
      ocr={fileOcr}
      legalType={legalType}
      createdAt={createdAt}
      isPrivate={isPrivate}
    />
  );
}
