import { Column } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';

const SortColumn = ({
  name,
  column,
  className,
  onClick,
  ...props
}: {
  name: string;
  column: Column<any, unknown>;
  className?: string;
  onClick?: () => void;
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      column.toggleSorting(column.getIsSorted() === 'asc');
    }
  };

  return (
    <div
      className={`flex items-center cursor-pointer ${className}`}
      onClick={handleClick}
      {...props}
    >
      {name}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </div>
  );
};

export default SortColumn;
