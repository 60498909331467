import { z } from 'zod';

export const createPromptSchema = z.object({
  keywords: z.array(
    z.string())
    .max(4, {
      message: '最多只能新增 4 個關鍵字',
    })
    .min(1, {
      message: '請至少新增一個關鍵字',
    }),
});
