import {
  ComponentType,
  Fragment,
  lazy,
  LazyExoticComponent,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { BreadcrumbModel, RealFileModel } from '@/types';
import useMediaQuery from '@/hooks/useMediaQuery';
import { FolderLock, LayoutGrid, List } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Setting } from '@/components/icons';
import DropzoneArea from '@/components/dropzone-area';
import { Link, useLocation, useRouter } from '@tanstack/react-router';
import { goToFile, goToFolder, gotoTrashFile } from '@/lib/router';
import { CreateFolderAction } from '@/components/layout/edit-actions/create-folder-action.tsx';
import { DownloadAction } from '@/components/layout/edit-actions/download-action.tsx';
import { EditAction } from '@/components/layout/edit-actions/edit-action.tsx';
import { DeleteAction } from '@/components/layout/edit-actions/delete-action.tsx';
import ActionDialog from '@/components/layout/edit-actions/dialogs/action-dialog.tsx';
import { UploadAction } from '@/components/layout/edit-actions/upload-action.tsx';
import EmptyData from '@/components/empty-data';
import { stopPropagationClickHandlers } from '@/lib/utils';
import { DuplicateAction } from '@/components/layout/edit-actions/duplicate-action.tsx';
import { MoveAction } from '@/components/layout/edit-actions/move-action.tsx';
import { DeletePermanentlyAction } from '@/components/layout/edit-actions/delete-permanently-action.tsx';
import { RestoreAction } from '@/components/layout/edit-actions/restore-action.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { useStore } from '@/store';
import { RevokePrivateAction } from '@/components/layout/edit-actions/revoke-private-action.tsx';
import { defaultColumnKeys, FileColumnConfig } from '@/types/file-column-config.ts';
import { Row } from '@tanstack/react-table';
import { PinAction } from '@/components/layout/edit-actions/pin-action.tsx';
import { UnpinAction } from '@/components/layout/edit-actions/unpin-action.tsx';
import { ACTION_TYPE_TITLE, ACTION_TYPE_TITLE_LABEL_MAP } from '@/constants';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { SetPrivateAction } from '@/components/layout/edit-actions/set-private-action.tsx';
import { useTableStore } from '@/components/data-table';
import { ActionButton } from '@/types/edit-action.ts';

type QueryFilter = Record<string, string | number | boolean>;

interface FolderViewProps {
  breadcrumbs: BreadcrumbModel[];
  filterAction?: ReactNode;
  files: RealFileModel[];
  isDepartment?: boolean;
  folderName?: string;
  departmentId?: string;
  refetchFolder?: (filter?: QueryFilter) => Promise<void>;
  companyId?: string;
  columns?: FileColumnConfig;
  canControlConfidentialFiles?: boolean;
  canModifyDepartment?: boolean;
}

export interface ViewerProps {
  files: RealFileModel[];
  selectedFileIds: string[];
  handleClick: (row?: Row<RealFileModel>) => void;
  handleDoubleClick: (file: RealFileModel) => void;
  columns?: FileColumnConfig;
  isGridView?: boolean;
}

type ActionButtonGroup = {
  show: boolean;
  actionButton: ActionButton;
};

type ViewerComponentType = LazyExoticComponent<ComponentType<ViewerProps>>;

const FolderView = ({
  breadcrumbs,
  filterAction,
  files,
  isDepartment,
  folderName,
  departmentId,
  refetchFolder,
  companyId,
  columns = defaultColumnKeys,
  canControlConfidentialFiles,
  canModifyDepartment,
}: FolderViewProps) => {
  const router = useRouter();
  const [isGridView, setIsGridView] = useState(false);
  const { table, selectedFileIds } = useTableStore(state => state);
  const hasSelectedFolder =
    selectedFileIds.length > 0 &&
    files.some(file => selectedFileIds.includes(file.id) && file.isDir);
  const departments = useStore.home(state => state.departments);
  const [FileListComponent, setFileListComponent] =
    useState<ViewerComponentType | null>(null);
  const location = useLocation();
  const isMobile = useMediaQuery();

  const isConfidentialPage = location.pathname.includes('/confidential-files');
  const isTrashPage = location.pathname.includes('/trash');
  const isSearchPage = location.pathname.includes('/search');
  const openDialog = (title: string, content: ReactNode) => {
    setDialogState({ isOpen: true, title, content });
  };
  const closeDialog = () => {
    setDialogState(prev => ({ ...prev, isOpen: false }));
    table.toggleAllRowsSelected(false);
  };

  const editButtonGroup: ActionButtonGroup[] = [
    {
      show: true,
      actionButton: DownloadAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    // ShareAction({ selectedFileIds, closeDialog }),
    {
      show: true,
      actionButton: EditAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },

    {
      show: true,
      actionButton: DuplicateAction({
        selectedFileIds,
        refetchFolder,
        closeDialog,
        openDialog,
      }),
    },
    {
      show: true,
      actionButton: MoveAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: DeleteAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: PinAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: UnpinAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: !!canControlConfidentialFiles && !hasSelectedFolder,
      actionButton: SetPrivateAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
  ];
  const searchButtonGroup: ActionButtonGroup[] = [
    {
      show: true,
      actionButton: DownloadAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    // ShareAction({ selectedFileIds, closeDialog }),
    {
      show: true,
      actionButton: EditAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: DeleteAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
  ];
  const trashButtonGroup: ActionButtonGroup[] = [
    {
      show: true,
      actionButton: DeletePermanentlyAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: RestoreAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
  ];
  const confidentialFilesButtonGroup: ActionButtonGroup[] = [
    {
      show: true,
      actionButton: DownloadAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    {
      show: true,
      actionButton: RevokePrivateAction({
        selectedFileIds,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
  ];
  const viewButton = {
    title: ACTION_TYPE_TITLE.VIEW,
    icon: isGridView ? (
      <List size={24} strokeWidth={1.5} />
    ) : (
      <LayoutGrid size={24} strokeWidth={1.5} />
    ),
    onClick: () => setIsGridView(!isGridView),
  };
  const lastParentId = breadcrumbs?.length ? breadcrumbs.at(-1)!.id : '';
  const normalButtonGroup: ActionButtonGroup[] = [
    {
      show: true,
      actionButton: CreateFolderAction({
        parentFileId: lastParentId,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
    { show: true, actionButton: viewButton },
    {
      show: true,
      actionButton: UploadAction({
        toFolderId: lastParentId,
        refetchFolder,
        openDialog,
        closeDialog,
      }),
    },
  ];

  const actionButtonGroup = (() => {
    const hasSelectedFile = selectedFileIds.length > 0;
    if (isConfidentialPage) {
      if (hasSelectedFile) {
        return confidentialFilesButtonGroup;
      } else {
        return [viewButton];
      }
    } else if (isTrashPage) {
      if (hasSelectedFile) {
        return trashButtonGroup;
      } else {
        return [{ show: true, actionButton: viewButton }];
      }
    } else if (isSearchPage) {
      if (hasSelectedFile) {
        return searchButtonGroup;
      } else {
        return [{ show: true, actionButton: viewButton }];
      }
    } else if (breadcrumbs.length === 0) {
      return [{ show: true, actionButton: viewButton }];
    } else if (hasSelectedFile) {
      return editButtonGroup;
    } else {
      return normalButtonGroup;
    }
  })() as ActionButtonGroup[];

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    title: '',
    content: null as ReactNode,
  });

  useEffect(() => {
    setFileListComponent(() =>
      lazy(
        () =>
          import('@/components/layout/file-table-view') as Promise<{
            default: ComponentType<ViewerProps>;
          }>,
      ),
    );
  }, [isMobile, isGridView]);

  const toggleFileSelection = (row?: Row<RealFileModel>) => {
    row && row.toggleSelected();
  };

  const handleDoubleClick = (file: RealFileModel) => {
    if (isTrashPage) {
      if (file.isDir) {
        // @todo 用 modal
        alert('請復原後再觀看');
        return;
      } else {
        return gotoTrashFile({
          router,
          fileId: file.id,
        });
      }
    }
    file.isDir
      ? goToFolder({
          router,
          folderId: file.id,
        })
      : goToFile({
          router,
          fileId: file.id,
        });
  };

  if (!FileListComponent) {
    return null;
  }

  return (
    <Suspense>
      <div className="px-8">
        <div className="sticky py-4 top-0 bg-background z-10">
          <Breadcrumb>
            <BreadcrumbList>
              {breadcrumbs.map((item, index) => {
                return (
                  <Fragment key={item.id}>
                    <BreadcrumbItem key={item.id}>
                      <BreadcrumbLink
                        className="cursor-pointer"
                        onClick={() =>
                          router.navigate({
                            to:
                              item.id && item.id !== companyId
                                ? `/folders/$folderId`
                                : '/',
                            params: {
                              folderId: item.id,
                            },
                          })
                        }
                      >
                        {item.name}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    {breadcrumbs.length - 1 !== index && <BreadcrumbSeparator />}
                  </Fragment>
                );
              })}
            </BreadcrumbList>
          </Breadcrumb>
          <div className="pt-6 flex justify-between items-center">
            <div className="flex items-center gap-2">
              <h1 className="text-lg md:text-2xl font-bold">{folderName}</h1>
              {isTrashPage && (
                <Select
                  defaultValue="all"
                  onValueChange={value => {
                    refetchFolder &&
                      refetchFolder({ departmentId: value, isTrash: true });
                  }}
                >
                  <SelectTrigger className="w-[200px] focus:ring-transparent text-typography-secondary">
                    <SelectValue placeholder="all" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem key="all" value="all">
                        all
                      </SelectItem>
                      {departments.map(({ id, name }) => (
                        <SelectItem key={id} value={id}>
                          {name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
              {isDepartment && canModifyDepartment && (
                <div className="flex gap-1">
                  <Link to={`/settings/departments/${departmentId}`}>
                    <Button className="px-2 font-normal text-primary" variant="ghost">
                      <Setting />
                      <p className="ml-1">部門設定</p>
                    </Button>
                  </Link>
                </div>
              )}
              {isDepartment && canControlConfidentialFiles && (
                <div className="flex gap-1">
                  <Link to={`/confidential-files/${departmentId}`}>
                    <Button className="px-2 font-normal text-primary" variant="ghost">
                      <FolderLock strokeWidth={1.5} />
                      <p className="ml-1">機敏檔案</p>
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="pt-6 flex justify-between items-center gap-2">
            <div className="flex gap-2">{filterAction}</div>
            <div
              className="flex justify-center items-center gap-1"
              {...stopPropagationClickHandlers()}
            >
              {actionButtonGroup.map(({ show, actionButton: button }) => {
                if (!show) return null;
                if (isMobile) return null;
                if (button.title === ACTION_TYPE_TITLE.VIEW && isGridView) {
                  return (
                    <TooltipProvider key={ACTION_TYPE_TITLE.VIEW}>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            className="size-8 bg-primary-100 text-typography-secondary hover:bg-primary-100 hover:text-typography-secondary"
                            variant="ghost"
                            size="icon"
                            data-action-button-title={button.title}
                            onClick={() => setIsGridView(false)}
                          >
                            <LayoutGrid size={24} strokeWidth={1.5} />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{ACTION_TYPE_TITLE_LABEL_MAP[ACTION_TYPE_TITLE.VIEW]}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  );
                }
                return (
                  <TooltipProvider key={button.title}>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          className="size-8 text-typography-secondary hover:bg-primary-100 hover:text-typography-secondary"
                          variant="ghost"
                          size="icon"
                          data-action-button-title={
                            ACTION_TYPE_TITLE_LABEL_MAP[
                              button.title as keyof typeof ACTION_TYPE_TITLE_LABEL_MAP
                            ]
                          }
                          onClick={button.onClick}
                        >
                          {button.icon}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {
                            ACTION_TYPE_TITLE_LABEL_MAP[
                              button.title as keyof typeof ACTION_TYPE_TITLE_LABEL_MAP
                            ]
                          }
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                );
              })}
              <ActionDialog
                isOpen={dialogState.isOpen}
                onClose={closeDialog}
                title={dialogState.title}
                content={dialogState.content}
              />
            </div>
          </div>
        </div>
        <DropzoneArea toFolderId={lastParentId} refetchFolder={refetchFolder}>
          {files.length === 0 ? (
            <EmptyData />
          ) : (
            <div>
              <FileListComponent
                files={files}
                selectedFileIds={selectedFileIds}
                handleClick={toggleFileSelection}
                handleDoubleClick={handleDoubleClick}
                columns={columns}
                isGridView={isGridView}
              />
            </div>
          )}
        </DropzoneArea>
      </div>
    </Suspense>
  );
};

export default FolderView;
