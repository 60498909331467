import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button.tsx';
import { Card, CardContent, CardHeader } from '@/components/ui/card.tsx';
import { useMutation } from '@apollo/client';
import { SocialAuthUrlDocument, SocialProvider } from '@@graphql';
import { useAuth } from '@/auth.tsx';

function ProfileAuth() {
  const [socialAuthUrl] = useMutation(SocialAuthUrlDocument);
  const { user } = useAuth();
  const isBound = !!user?.socialAuths?.find(
    auth => auth.provider === SocialProvider.Google,
  );

  return (
    <div className="p-8 flex items-center justify-center">
      <Card>
        <CardHeader>{isBound ? '已綁定' : '進行 google 綁定'}</CardHeader>
        <CardContent className="space-y-2">
          {!isBound && (
            <div>
              <Button
                className="w-full"
                onClick={async () => {
                  const { data } = await socialAuthUrl({
                    variables: { type: SocialProvider.Google },
                  });
                  if (data?.socialAuthUrl) {
                    window.open(data?.socialAuthUrl, '_blank');
                  }
                }}
              >
                auth
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export const Route = createFileRoute('/_layout/profile-auth')({
  component: ProfileAuth,
});
