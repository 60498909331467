import { create } from 'zustand';
import { RoleModel } from '@/types';
import { FtpAccountModel } from '@/graphql/generated/.graphql';

type UsageLimit = {
  storage: number;
  networkTraffic: number;
};

type RoleConfig = RoleModel & {
  isManager: boolean;
  canControlPrivateFile: boolean;
  canCreateComplaint: boolean;
};

type Config = {
  usedSize: string;
  roles: RoleConfig[];
  hasSiblings: boolean;
  usageLimit: UsageLimit;
  isMain: boolean;
  ftpAccount: FtpAccountModel | null;
};

interface CompanyConfigStore {
  config: Config;
  setConfig: (params: Partial<Config>) => void;
  getConfigValue: <K extends keyof Config>(key: K) => Config[K];
  resetConfig: () => void;
}

const DEFAULT_CONFIG: Config = {
  usedSize: "0",
  roles: [],
  hasSiblings: false,
  usageLimit: {
    storage: 0,
    networkTraffic: 0,
  },
  isMain: false,
  ftpAccount: null,
};

export const useCompanyConfigStore = create<CompanyConfigStore>((set, get) => ({
  config: DEFAULT_CONFIG,
  setConfig: params =>
    set(state => ({
      config: { ...state.config, ...params },
    })),

  getConfigValue: <K extends keyof Config>(key: K): Config[K] => {
    return get().config[key];
  },

  resetConfig: () => set({ config: DEFAULT_CONFIG }),
}));
