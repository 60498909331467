import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Input } from '@/components/ui/input.tsx';
import LandingLayout from '@/components/layout/landing-layout.tsx';
import {
  GeneralLoginResponse,
  SelectCompanyResponse,
  SendOtpVerifyEmailDocument,
  VerifyOtpDocument,
} from '@@graphql';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useMutation } from '@apollo/client';

import { verifyOtpSchema } from '@/zodSchema/verify-otp.ts';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useStore } from '@/store';
import { User } from '@/types';
import Loading from '@/components/loading.tsx';
import { decodeJWT } from '@/lib/utils.tsx';
import LinkExpired from '@/components/link-expired.tsx';

const SuccessDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>驗證成功</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">驗證已完成，將為您導向首頁</div>
      </DialogContent>
    </Dialog>
  );
};

function OtpVerification() {
  const search = Route.useSearch() as { token: string; status?: string };
  const navigate = useNavigate();
  const { setUser } = useStore.user.getState();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [cooldown, setCooldown] = useState(0);
  const [isPass, setIsPass] = useState(false);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [autoRedirectTime, setAutoRedirectTime] = useState(3);
  const [isManualClose, setIsManualClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);

  const [verifyOtpMutation] = useMutation(VerifyOtpDocument, {
    onError: err => handleError(err),
  });

  const [sendVerifyEmail] = useMutation(SendOtpVerifyEmailDocument, {
    onError: err => handleError(err),
  });

  const { handleError, resetErrors, errors } = useErrorHandler({ otp: '' });

  useEffect(() => {
    if (!search.token) {
      setIsTokenValid(false);
      return;
    }

    try {
      const payload = decodeJWT(search.token);
      if (payload.exp && payload.exp < Date.now() / 1000) {
        setIsTokenValid(false);
      }
    } catch (error) {
      setIsTokenValid(false);
    }
  }, [search.token]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isPass && !isManualClose && autoRedirectTime > 0) {
      timer = setTimeout(() => {
        setAutoRedirectTime(prev => prev - 1);
      }, 1000);
    } else if (isPass && autoRedirectTime === 0 && !isManualClose) {
      navigate({ replace: true, to: '/' });
    }
    return () => clearTimeout(timer);
  }, [isPass, autoRedirectTime, isManualClose, navigate]);

  useEffect(() => {
    if (search.status === 'sent') {
      setCooldown(60);
      localStorage.setItem('otpCooldown', '60');
      localStorage.setItem('otpTimestamp', Date.now().toString());
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('status');
      window.history.replaceState({}, '', newUrl.toString());
      return;
    }
    const storedCooldown = localStorage.getItem('otpCooldown');
    const storedTimestamp = localStorage.getItem('otpTimestamp');

    if (storedCooldown && storedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
      const remainingCooldown = Math.max(0, parseInt(storedCooldown) - elapsedTime);

      if (remainingCooldown > 0) {
        setCooldown(remainingCooldown);
      } else {
        localStorage.removeItem('otpCooldown');
        localStorage.removeItem('otpTimestamp');
      }
    }
  }, [search.status]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setTimeout(() => {
        const newCooldown = cooldown - 1;
        setCooldown(newCooldown);
        if (newCooldown === 0) {
          localStorage.removeItem('otpCooldown');
          localStorage.removeItem('otpTimestamp');
        } else {
          localStorage.setItem('otpCooldown', newCooldown.toString());
          localStorage.setItem('otpTimestamp', Date.now().toString());
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldown]);

  useEffect(() => {
    setIsOtpComplete(otp.every(digit => digit !== ''));
  }, [otp]);

  if (!isTokenValid) {
    return <LinkExpired />;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    resetErrors();
    try {
      const optString = otp.join('');
      verifyOtpSchema.parse({ otp: optString, token: search.token });
      const { data, errors } = await verifyOtpMutation({
        variables: { otp: optString, token: search.token },
      });
      if (errors) {
        handleError(errors);
        setLoading(false);
        return;
      }
      if (typeof data.verifyOtp?.selectCompanyPath === 'string') {
        localStorage.removeItem('otpCooldown');
        localStorage.removeItem('otpTimestamp');
        const { selectCompanyPath, token } = data.verifyOtp as SelectCompanyResponse;
        setTimeout(() => {
          navigate({
            replace: true,
            to: selectCompanyPath,
            search: { token } as any,
          });
        }, 800);
        return;
      }
      const { accessToken, user }: { accessToken: string; user: User } =
        data.verifyOtp as GeneralLoginResponse;
      setUser(user);
      localStorage.setItem('accessToken', accessToken);
      setIsPass(true);
      localStorage.removeItem('otpCooldown');
      localStorage.removeItem('otpTimestamp');
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };
  const handleResendCode = async () => {
    setCooldown(60);
    setOtp(['', '', '', '', '', '']);
    setIsOtpComplete(false);
    const { data } = await sendVerifyEmail({
      variables: { token: search.token },
    });
    const { otpPageToken } = data.sendOtpVerifyEmail;
    return navigate({
      to: '/otp-verification',
      search: { token: otpPageToken },
      replace: true,
    });
  };

  const handleDialogClose = () => {
    setIsManualClose(true);
    setIsPass(false);
    return navigate({ to: '/' });
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      document.getElementById(`otp-${index - 1}`)?.focus();
    }
  };

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`)?.focus();
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, otp.length);
    const newOtp = [...otp];

    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
    }

    setOtp(newOtp);
  };
  return (
    <LandingLayout className="items-center">
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full max-w-md bg-white rounded-3xl p-8 shadow-lg">
          <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
          <h1 className="text-2xl text-center mb-4">2FA 雙重驗證</h1>
          <p className="text-center text-gray-500 mb-6">
            您的帳戶受到雙重驗證機制的保護
            <br />
            請輸入驗證碼以完成登入驗證流程
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex justify-center space-x-2">
              {otp.map((digit, index) => (
                <Input
                  key={index}
                  type="text"
                  maxLength={1}
                  className="w-12 h-12 text-center text-2xl"
                  value={digit}
                  onChange={e => handleInputChange(index, e)}
                  onKeyDown={e => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                  id={`otp-${index}`}
                />
              ))}
            </div>
            {errors.otp && <p className="text-red-500 text-center">{errors.otp}</p>}
            <Button
              type="submit"
              className="w-full py-3 rounded-lg font-semibold"
              disabled={!isOtpComplete}
            >
              驗證
            </Button>
          </form>
          <Button
            onClick={handleResendCode}
            variant="link"
            disabled={cooldown > 0}
            className="mt-4 text-center w-full text-gray-500 hover:text-gray-700"
          >
            {cooldown > 0 ? `重新發送 (${cooldown}s)` : '重新發送驗證碼'}
          </Button>
        </div>
      )}
      <SuccessDialog isOpen={isPass} onClose={handleDialogClose} />
    </LandingLayout>
  );
}

export const Route = createFileRoute('/otp-verification')({
  component: OtpVerification,
});
