import { cn } from '@/lib/utils';
import { Link, useRouterState } from '@tanstack/react-router';
import { buttonVariants } from '../ui/button';
import { Separator } from '../ui/separator';
import React from 'react';

const sidebarNavItems = [
  {
    title: '部門管理',
    href: '/settings/department-settings',
  },
  {
    title: '使用者管理',
    href: '/settings/user-settings',
  },
  {
    title: '部門權限管理',
    href: '/settings/admin-settings',
  },
  {
    title: '角色權限管理',
    href: '/settings/role-settings',
  },
  {
    title: '寄件人白名單管理',
    href: '/settings/whitelist-settings',
  },
  {
    title: '文件分類管理',
    href: '/settings/keywords',
  },
  {
    title: '機敏關鍵字管理',
    href: '/settings/sensitive-word-settings',
  },
  {
    title: '文件浮水印設定',
    href: '/settings/water-mark-settings',
  },
  {
    title: '匯入/匯出',
    href: '/settings/import-export',
  },
];

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

const SettingSideNav = ({ className, items, ...props }: SidebarNavProps) => {
  const router = useRouterState();
  return (
    <nav className={cn('flex space-x-2', className)} {...props}>
      {items.map(item => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            router.location.pathname === item.href
              ? 'bg-muted hover:bg-muted'
              : 'hover:bg-transparent hover:underline',
            'justify-start',
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
};

const SettingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="space-y-6 pl-6 pt-6 pr-12 pb-16">
      <div className="space-y-0.5">
        <h1 className="text-lg lg:text-2xl font-bold">系統設定</h1>
        <p className="font-normal text-sm text-[#120D6E]">
          提供公司管理者進行部門、使用者、管理者的設定
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-3">
        <aside>
          <SettingSideNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

export default SettingLayout;
