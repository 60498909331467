import { FormEvent, useState } from 'react';
import { QueryOptions, useLazyQuery, useMutation } from '@apollo/client';
import { CreateDepartmentKeywordDocument, KeywordsDocument } from '@@graphql';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { FilePlus2 } from 'lucide-react';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { createPromptSchema } from '@/zodSchema/create-prompt';
import { useToast } from '@/components/ui/use-toast.ts';
import { Label } from '@/components/ui/label.tsx';
import InputTags from '@/components/InputTags.tsx';

interface CreatePromptDialogProps {
  departmentId: string;
  onPromptCreated: (departmentId: string) => void;
  fileId?: string;
  refetchQueries: QueryOptions<{ [key: string]: string }>[];
}

const CreatePromptDialog = ({
  departmentId,
  onPromptCreated,
  fileId,
  refetchQueries,
}: CreatePromptDialogProps) => {
  const { toast } = useToast();
  const [isCreateKeywordsOpen, setIsCreateKeywordsOpen] = useState(false);
  const { errors, handleError, resetErrors } = useErrorHandler({ keywords: '' });
  const [keywords, setKeywords] = useState<string[]>([]);
  const [createDepartmentKeywordsMutation] = useMutation(
    CreateDepartmentKeywordDocument,
    {
      refetchQueries,
      onCompleted: () => {
        onPromptCreated(departmentId);
      },
    },
  );

  const [keywordsQuery] = useLazyQuery(KeywordsDocument);

  const onFormSubmitCreatePrompt = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      resetErrors();
      createPromptSchema.parse({ keywords });
      await createDepartmentKeywordsMutation({
        variables: { keywords, departmentId, fileId },
      });
      toast({ title: '新增文件分類規則成功', duration: 1500 });
      setIsCreateKeywordsOpen(false);
    } catch (err: unknown) {
      handleError(err);
    }
  };

  const handleTagsChange = (newTags: string[]) => {
    setKeywords(newTags);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      // 當對話框關閉時，清除錯誤和關鍵字
      resetErrors();
      setKeywords([]);
    }
    setIsCreateKeywordsOpen(open);
  };

  return (
    <Dialog open={isCreateKeywordsOpen} onOpenChange={handleOpenChange}>
      <div className="flex justify-end">
        <DialogTrigger asChild>
          <Button
            className="gap-1 font-normal text-primary w-[165px] h-[32px]"
            variant="ghost"
          >
            <FilePlus2 size={20} />
            新增文件分類規則
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onFormSubmitCreatePrompt}>
          <DialogHeader>
            <DialogTitle>新增文件分類規則</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="keyword" className="text-right font-normal">
                關鍵字
              </Label>
              <InputTags
                keywordsQuery={keywordsQuery}
                defaultTags={[]}
                maxTags={4}
                onTagsChange={handleTagsChange}
              />
              {errors.keywords && (
                <div className="text-red-500 col-span-4">{errors.keywords}</div>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePromptDialog;
