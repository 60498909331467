import { createFileRoute, redirect } from '@tanstack/react-router';
import { ColumnDef, Row } from '@tanstack/react-table';
import { RoleModel, User } from '@/types';
import { useEffect, useState, useRef } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { ArrowUpDown, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '@/components/ui/tabs-with-border';
import { Button } from '@/components/ui/button.tsx';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  RemoveUserFromDepartmentDocument,
  RolesDocument,
  SetUserToDepartmentDocument,
  UserModel,
  UserWithAssignmentsDocument,
  DepartmentDocument,
  DepartmentModel,
  FtpAccountModel,
} from '@@graphql';
import { QRCodeCanvas } from 'qrcode.react';
import { Separator } from '@/components/ui/separator.tsx';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/components/ui/card.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { toast, useToast } from '@/components/ui/use-toast.ts';
import { useHomeStore } from '@/store/home.ts';
import { useStore } from '@/store';
import { ucfirst } from '@/utils';
import DepartmentPermissionDialog from '@/components/department-permission-dialog.tsx';
import { useAuth } from '@/auth.tsx';
import FtpSetting from '@/components/ftp-setting';
import CodePositionSetting, {
  CodePositionType,
} from '@/components/code-position-setting';
import EnableFtpSwitch from '@/components/enableFtpSwitch';
import RemoveFtpDialog from '@/components/removeFtpDialog';

type DepartmentPermissionData = {
  id: string;
  name: string;
  email: string;
  role: string;
  roleId: string;
};
type DeleteUserCellProps = {
  email: string;
  departmentId: string;
  userId: string;
  refetchUsers: () => void;
};

export const Route = createFileRoute('/_layout/settings/departments/$departmentId')({
  beforeLoad: ({ params }: { params: { departmentId: string } }) => {
    if (!params.departmentId) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: DepartmentSetting,
});

const UpdateUserRoleDropdown = ({
  row,
  roles,
  departmentId,
  refetchUsers,
}: {
  row: Row<DepartmentPermissionData>;
  roles: RoleModel[];
  departmentId: string;
  refetchUsers: () => void;
}) => {
  const currentRole = roles.find(role => role.id === row.original.roleId);
  if (!currentRole) throw new Error('currentRole not found');

  const [selectedRole, setSelectedRole] = useState<RoleModel>(currentRole);
  const [updateUserRole] = useMutation(SetUserToDepartmentDocument, {
    onCompleted: () => {
      refetchUsers();
      toast({
        title: `成功將 ${row.original.email} 分配 ${ucfirst(selectedRole.name)} 權限`,
        duration: 1000,
      });
    },
  });
  const handleSelectChange = async (value: string) => {
    const selected = roles.find(role => role.id === value);
    if (!selected) return;

    setSelectedRole(selected);
    await updateUserRole({
      variables: {
        departmentId,
        roleId: value,
        userId: row.original.id,
      },
    });
  };

  return (
    <Select value={selectedRole?.id || ''} onValueChange={handleSelectChange}>
      <SelectTrigger className="w-[150px] focus:ring-transparent text-typography-secondary">
        <SelectValue placeholder="請選擇權限" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {roles.map(role => (
            <SelectItem key={role.id} value={role.id}>
              {ucfirst(role.name)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const DeleteUserCell = ({
  departmentId,
  userId,
  email,
  refetchUsers,
}: DeleteUserCellProps) => {
  const { toast } = useToast();
  const [removeUser] = useMutation(RemoveUserFromDepartmentDocument, {
    onCompleted: async () => {
      refetchUsers();
      toast({
        title: `成功將 ${email} 從部門中移除`,
        duration: 1000,
      });
    },
  });

  const handleDelete = async () => {
    try {
      await removeUser({ variables: { departmentId, userId } });
    } catch (error) {
      toast({
        title: '移除用戶失敗',
        description: '請稍後再試',
        duration: 3000,
      });
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          className="size-8 text-error hover:text-error"
          variant="ghost"
          size="icon"
        >
          <Trash2 size={20} strokeWidth={1.5} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>刪除使用者</AlertDialogTitle>
          <AlertDialogDescription>{`確定要刪除 ${email} ?`}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>取消</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>確定</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const departmentPermissionColumns = (
  departmentId: string,
  roles: RoleModel[],
  refetchUsers: unknown,
): ColumnDef<DepartmentPermissionData>[] => [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          使用者名稱
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => row.getValue('name'),
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          電子郵件
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => row.getValue('email'),
  },
  {
    accessorKey: 'role',
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          權限
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => (
      <UpdateUserRoleDropdown
        row={row}
        roles={roles}
        departmentId={departmentId}
        refetchUsers={refetchUsers as () => void}
      />
    ),
  },
  {
    id: 'delete',
    header: '刪除',
    enableHiding: false,
    cell: ({ row }) => (
      <DeleteUserCell
        departmentId={departmentId}
        userId={row.original.id}
        email={row.original.email}
        refetchUsers={refetchUsers as () => void}
      />
    ),
  },
];

function DepartmentSetting() {
  const { departmentId } = Route.useParams();
  const homeStore = useHomeStore();
  const currentUser = useAuth().user as User;
  const companyConfig = useStore.companyConfig(state => state.config);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [rolesQuery, { data: rolesData }] = useLazyQuery(RolesDocument);
  const [departmentName, setDepartmentName] = useState('');
  const [currentFtpInfo, setCurrentFtpInfo] = useState<FtpAccountModel | null>(null);
  const [currentFtpPassword, setCurrentFtpPassword] = useState<string | undefined>(
    undefined,
  );
  const hasCompanyFtpAccount = !!companyConfig?.ftpAccount;

  const { data: departmentData } = useQuery(DepartmentDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      departmentId,
    },
    skip: !departmentId,
  });

  const department: DepartmentModel = departmentData?.department;

  const [showFtp, setShowFtp] = useState(false);

  useEffect(() => {
    if (department) {
      setCurrentFtpInfo(department.ftpAccount || null);
      setShowFtp(!!department.ftpAccount);
    }
  }, [department, department?.ftpAccount]);

  useEffect(() => {
    setRoles([]);
    rolesQuery();
    if (rolesData?.roles) {
      setRoles(rolesData?.roles);
    }
  }, [rolesData, rolesQuery]);

  useEffect(() => {
    if (homeStore.departments.length) {
      setDepartmentName(
        homeStore.departments.find(department => department.id === departmentId)
          ?.name ?? '',
      );
    }
  }, [homeStore.departments, departmentId]);

  const downloadQRCode = () => {
    const node = canvasRef.current;

    if (node == null) {
      return;
    }
    const dataURI = node.toDataURL('image/png');
    const a = document.createElement('a');
    a.download = `${departmentName} QR Code.png`;
    a.href = dataURI;
    a.click();
  };

  const {
    data: usersData,
    loading,
    error,
    refetch: refetchUsers,
  } = useQuery(UserWithAssignmentsDocument, {
    variables: { departmentId: null },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  let users: DepartmentPermissionData[] = [];

  if (usersData?.users?.length) {
    users = usersData.users
      .filter((user: UserModel) => user.id !== currentUser.id)
      .map(({ id, name, email, assignments }: UserModel) => {
        const role = assignments.find(
          assignment => assignment.department.id === departmentId,
        )?.role;
        if (!role) return null;
        return {
          id,
          name,
          email,
          role: role.name,
          roleId: role.id,
        } as DepartmentPermissionData;
      })
      .filter(Boolean) as DepartmentPermissionData[];
  }

  return (
    <div className="space-y-6 pl-6 pt-6 pr-12 pb-16">
      <div className="space-y-0.5">
        <h1 className="text-lg lg:text-2xl font-bold">{departmentName} 部門設定</h1>
      </div>
      <Separator className="my-6" />
      <Card>
        <Tabs defaultValue="permission" className="p-6">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="permission">使用者權限管理</TabsTrigger>
            <TabsTrigger value="segmentation">文件分割管理</TabsTrigger>
            <TabsTrigger value="qrcode">特定文件分類</TabsTrigger>
          </TabsList>
          <TabsContent value="permission">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardDescription>
                  {`若想要讓使用者能夠查看或管理${departmentName}的文件，請在此新增使用者，並且設定對應的使用者權限。`}
                </CardDescription>
                <DepartmentPermissionDialog
                  roles={roles}
                  departmentId={departmentId}
                  refetchUsers={refetchUsers}
                />
              </div>
            </CardHeader>
            <CardContent className="space-y-2">
              <DataTable
                perPage={50}
                data={users}
                columns={departmentPermissionColumns(departmentId, roles, refetchUsers)}
              />
            </CardContent>
          </TabsContent>
          <TabsContent value="segmentation">
            <CardHeader>
              <CardDescription>
                Barcode 作為文件分割的依據，請開啟部門 FTP，系統會根據文件上特定位置的
                Barcode，自動分割文件。
              </CardDescription>
              <CardDescription>
                注意：啟用部門 FTP
                之後，請至事務機變更設定再進行掃描，才會執行文件分割流程。
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2 h-auto">
              {showFtp ? (
                <div className="space-y-6 w-[600px]">
                  <FtpSetting
                    ftpAccountId={currentFtpInfo?.id}
                    account={currentFtpInfo?.account || ''}
                    password={currentFtpPassword}
                    refetchQueries={[
                      {
                        query: DepartmentDocument,
                        variables: { departmentId },
                      },
                    ]}
                  />
                  <Separator />
                  <CodePositionSetting
                    ftpAccountId={currentFtpInfo?.id}
                    type={CodePositionType.BARCODE}
                    defaultRegion={currentFtpInfo?.processConfig.regionKey}
                    refetchQueries={[
                      {
                        query: DepartmentDocument,
                        variables: { departmentId },
                      },
                    ]}
                  />
                  <Separator />
                  <RemoveFtpDialog
                    id={currentFtpInfo?.id}
                    departmentName={departmentName}
                    refetchQueries={[
                      {
                        query: DepartmentDocument,
                        variables: { departmentId },
                      },
                    ]}
                  />
                </div>
              ) : (
                <EnableFtpSwitch
                  departmentId={departmentId}
                  checked={showFtp}
                  onCheckedChange={setShowFtp}
                  setCurrentFtpPassword={setCurrentFtpPassword}
                  refetchQueries={[
                    {
                      query: DepartmentDocument,
                      variables: { departmentId },
                    },
                  ]}
                />
              )}
            </CardContent>
          </TabsContent>
          <TabsContent value="qrcode">
            <CardHeader>
              {hasCompanyFtpAccount ? (
                <CardDescription>
                  請下載 QR Code 並將 QR Code 加到文件的指定位置，使用公司的 FTP
                  掃描，即可自動分類檔案到對應的部門。
                </CardDescription>
              ) : (
                <CardDescription className="flex flex-col gap-2 h-[300px]">
                  <div>特定文件分類需要透過公司 FTP 掃描文件才會觸發分類流程。</div>
                  <div>
                    目前公司尚未啟用 FTP
                    帳號，請公司管理者協助至『文件分類管理』進行設定。
                  </div>
                </CardDescription>
              )}
            </CardHeader>
            {hasCompanyFtpAccount && (
              <CardContent className="space-y-2 h-auto">
                <QRCodeCanvas
                  ref={canvasRef}
                  size={200}
                  value={departmentId}
                  imageSettings={{
                    src: '/logo-mark.svg',
                    height: 30,
                    width: 30,
                    excavate: true,
                  }}
                />
                <div className="flex justify-end space-x-2 w-[700px]">
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button variant="outline">查看指定位置</Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>指定位置</AlertDialogTitle>
                        <AlertDialogDescription>
                          請將 QR Code
                          放置於下方指定範圍內，如需修改，請公司管理者變更。
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <CodePositionSetting readonly />
                      <AlertDialogFooter>
                        <AlertDialogAction>我瞭解了</AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                  <Button onClick={downloadQRCode}>下載 QR Code</Button>
                </div>
              </CardContent>
            )}
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
}
