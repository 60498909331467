import { forwardRef, useCallback } from 'react';
import { cn } from '@/lib/utils';
import { SurveyData, GarnishmentStatus } from './shared';

type PleadingHtmlProps = {
  value: SurveyData;
  isExport?: boolean;
};

type PleadingBodyProps = {
  templateId: string;
  [key: string]: string | number | undefined;
};

const PleadingHtml = forwardRef<HTMLDivElement, PleadingHtmlProps>((props, ref) => {
  const { value, isExport = false } = props;
  const { templateId, AIKeywords, manualKeywords } = value;

  const allKeywords = [...AIKeywords, ...manualKeywords];
  const keywords = Object.fromEntries(
    allKeywords
      .filter(keyword => keyword.used)
      .map(({ label, value }) => [label, value]),
  );

  const {
    caseNumber,
    stockType,
    declarantName,
    declarantAddress,
    phone,
    createdDate,
    debtorName,
    salaryDeductionDate,
    monthlySalary,
    legalRepresentative,
    executionLocation,
    seizedCaseNumber,
    seizedExecutionLocation,
    publishedDate,
  } = keywords;

  const title =
    templateId === GarnishmentStatus.NOT_EMPLOYEE ? '民事異議狀' : '民事陳報狀';
  const description =
    templateId === GarnishmentStatus.NOT_EMPLOYEE ? '聲明異議' : '陳報';

  const getBgStyle = useCallback(
    (columnLabel: string) => {
      const keyword = manualKeywords.find(keyword => keyword.label === columnLabel);
      const bgStyle = keyword?.used ? 'bg-yellow-300' : 'bg-primary-200';
      return !isExport && bgStyle;
    },
    [isExport, manualKeywords],
  );

  const getPleadingBody = useCallback(
    ({
      templateId,
      monthlySalary,
      seizedCaseNumber,
      seizedExecutionLocation,
      salaryDeductionDate,
    }: PleadingBodyProps) => {
      switch (templateId) {
        // 非員工
        case GarnishmentStatus.NOT_EMPLOYEE:
        default:
          return '惟債務人非第三人員工，無從扣押，爰提出異議。';
        // 已被地院扣押 或 已被行政執行署扣押
        case GarnishmentStatus.GARNISHED:
          return (
            <>
              惟債務人每月薪資新台幣
              <span className={cn('p-1', getBgStyle('monthlySalary'))}>
                {monthlySalary}
              </span>
              元，業經
              <span className={cn('p-1', getBgStyle('seizedExecutionLocation'))}>
                {seizedExecutionLocation}
              </span>
              <span className={cn('p-1', getBgStyle('seizedCaseNumber'))}>
                {seizedCaseNumber}
              </span>
              執行命令扣押，爰以此狀陳報。
            </>
          );
        // 欲扣押
        case GarnishmentStatus.PENDING:
          return (
            <>
              債務人現為第三人員工，依照執行命令自
              <span className={cn('p-1', getBgStyle('salaryDeductionDate'))}>
                {salaryDeductionDate}
              </span>
              起每月扣薪，爰以此狀陳報。
            </>
          );
      }
    },
    [getBgStyle],
  );

  return (
    <div ref={ref} className="">
      <p className="font-bold">{title}</p>
      <div className="flex flex-col gap-3 mt-3">
        <div className="flex items-center">
          <div className="mr-6 text-sm">案號</div>
          <div className={cn('text-sm p-1', getBgStyle('caseNumber'))}>
            {caseNumber}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-6 text-sm">股別</div>
          <div className={cn('text-sm p-1', getBgStyle('stockType'))}>{stockType}</div>
        </div>
        <div className="text-sm">聲明人</div>
        <div className="flex items-center">
          <div className="mr-6 text-sm">即第三人</div>
          <div className={cn('text-sm p-1', getBgStyle('declarantName'))}>
            {declarantName}
          </div>
        </div>
        <div className="mr-6 text-sm ml-[37px]">
          <div className="flex items-center mb-3">
            地址：
            <div className={cn('p-1', getBgStyle('declarantAddress'))}>
              {declarantAddress}
            </div>
          </div>
          <div className="flex items-center">
            電話：
            <div className={cn('text-sm p-1', getBgStyle('phone'))}>{phone}</div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-6 text-sm">法定代理人</div>
          <div className={cn('text-sm p-1', getBgStyle('legalRepresentative'))}>
            {legalRepresentative}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-6 text-sm">債務人</div>
          <div className={cn('text-sm p-1', getBgStyle('debtorName'))}>
            {debtorName}
          </div>
        </div>
        <div className="mt-5 text-sm">為對執行事件{description}事：</div>
        <div className="text-sm leading-8">
          聲明人於民國
          <span className={cn('p-1', getBgStyle('createdDate'))}>{createdDate}</span>
          接奉 鈞院
          <span className={cn('p-1', getBgStyle('caseNumber'))}>{caseNumber}</span>
          執行命令一件，
          {getPleadingBody({
            templateId,
            salaryDeductionDate,
            monthlySalary,
            seizedCaseNumber,
            seizedExecutionLocation,
          })}
        </div>
        <div className="text-sm ml-6">謹 狀</div>
        <div className="text-sm">
          <span className={cn('p-1', getBgStyle('executionLocation'))}>
            {executionLocation}
          </span>
          民事執行處 公鑒
        </div>
        <div className="text-sm">
          中華民國
          <span className={cn('p-1', getBgStyle('publishedDate'))}>
            {publishedDate}
          </span>
        </div>
        <div className="text-sm my-3 ml-[160px]">具狀人 ＿＿＿＿＿＿（簽章）</div>
      </div>
    </div>
  );
});

export default PleadingHtml;
