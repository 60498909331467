import React, { FormEvent } from 'react';
import { Label } from '@/components/ui/label.tsx';
import { Input } from '@/components/ui/input.tsx';
import { DialogClose, DialogFooter } from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { FolderPlus } from 'lucide-react';
import { ActionButton } from '@/types/edit-action.ts';
import { useMutation } from '@apollo/client';
import { CreateFolderDocument } from '@@graphql';
import { useToast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';

export interface CreateFolderProps {
  parentFileId?: string | null;
  refetchFolder?: () => Promise<void>;
  openDialog: (title: string, content: React.ReactNode) => void;
  closeDialog: () => void;
}

export const CreateFolderAction = ({
  refetchFolder,
  openDialog,
  closeDialog,
  parentFileId,
}: CreateFolderProps): ActionButton => {
  const { handleError } = useErrorHandler({});
  const [createFolderMutation] = useMutation(CreateFolderDocument, {
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      closeDialog();
    },
  });
  const { toast } = useToast();
  const CreateFolderForm = () => {
    return (
      <form onSubmit={handleSave}>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right font-normal">
              資料夾名稱
            </Label>
            <Input
              id="name"
              name="name"
              className="col-span-3"
              defaultValue=""
              placeholder="請輸入資料夾名稱"
              required
            />
            {/*{errors.name && <div className="text-red-500">{errors.name}</div>}*/}
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button type="submit">確定</Button>
        </DialogFooter>
      </form>
    );
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const nameFieldValue = data.get('name');
    const name = nameFieldValue!.toString();
    if (!name) return;
    if (!parentFileId) return;
    try {
      await createFolderMutation({
        variables: {
          name,
          fileId: parentFileId as string,
        },
      });
      toast({
        title: `新增 ${name} 資料夾成功`,
      });
    } catch (err) {
      handleError(err);
    }
  };

  return {
    title: ACTION_TYPE_TITLE.CREATE_FOLDER,
    icon: <FolderPlus strokeWidth={1.5} />,
    onClick: () => {
      openDialog('新增資料夾', <CreateFolderForm />);
    },
  };
};
