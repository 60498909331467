import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ColumnDef } from '@tanstack/react-table';
import { DialogActionType } from '@/constants';
import { ArrowUpDown, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/data-table';
import { useToast } from '@/components/ui/use-toast';
import { Switch } from '@/components/ui/switch';
import { useMutation, useQuery } from '@apollo/client';
import {
  RemoveSensitiveDocument,
  SensitiveModel,
  SensitivesDocument,
  UpdateSensitiveDocument,
} from '@@graphql';
import SensitiveDialog from '@/components/sensitive-dialog.tsx';

type SensitiveData = {
  id: string;
  word: string;
  enabled: boolean;
};

interface toggleProps {
  id: string;
  enabled: boolean;
}

export const Route = createFileRoute('/_layout/settings/sensitive-word-settings')({
  component: SensitiveWordSetting,
});

function SensitiveWordSetting() {
  const { toast } = useToast();
  const { data, error, loading, refetch } = useQuery(SensitivesDocument, {
    fetchPolicy: 'network-only',
  });
  const [toggleSensitiveMutation] = useMutation(UpdateSensitiveDocument, {
    onCompleted: async () => {
      await refetch();
    },
  });
  const [removeSensitiveMutation] = useMutation(RemoveSensitiveDocument, {
    onCompleted: async () => {
      await refetch();
    },
  });
  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;
  const sensitives =
    data?.sensitives.map(({ id, word, enabled }: SensitiveModel) => {
      return {
        id,
        word,
        enabled,
      };
    }) ?? ([] as SensitiveData[]);

  const toggleSensitive = async ({ id, enabled }: toggleProps) => {
    await toggleSensitiveMutation({
      variables: {
        id,
        enabled,
      },
    });
  };

  const columns: ColumnDef<SensitiveModel>[] = [
    {
      accessorKey: 'word',
      header: '關鍵字',
      cell: ({ row }) => row.getValue('word'),
    },
    {
      accessorKey: 'enabled',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            是否啟用
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: function Cell({ row }) {
        return (
          <Switch
            id="enabled"
            defaultChecked={row.getValue('enabled')}
            onCheckedChange={async isChecked => {
              await toggleSensitive({
                id: row.original.id,
                enabled: isChecked,
              });
              toast({
                title: isChecked ? '已啟用成功' : '已取消啟用成功',
                duration: 1500,
              });
            }}
          />
        );
      },
    },
    {
      id: 'edit',
      header: '編輯',
      enableHiding: false,
      cell: ({ row }) => {
        const { word, id } = row.original;
        return (
          <SensitiveDialog
            id={id}
            type={DialogActionType.EDIT}
            word={word}
            refetch={refetch}
          />
        );
      },
    },
    {
      id: 'actions',
      header: '操作',
      enableHiding: false,
      cell: ({ row }) => {
        const { word } = row.original;
        return (
          <div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="size-8 text-error hover:text-error"
                  variant="ghost"
                  size="icon"
                >
                  <Trash2 size={20} strokeWidth={1.5} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>刪除機敏關鍵字</AlertDialogTitle>
                  <AlertDialogDescription>
                    {`確定要刪除 ${word}`}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>取消</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={async () => {
                      await removeSensitiveMutation({
                        variables: {
                          id: row.original.id,
                        },
                      });
                      toast({
                        title: '已刪除成功',
                        duration: 1500,
                      });
                    }}
                  >
                    確定
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
    },
  ];
  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>機敏關鍵字管理</CardTitle>
            <SensitiveDialog type={DialogActionType.CREATE} refetch={refetch} />
          </div>
          <CardDescription>
            此頁面用於管理 OCR 敏感詞庫。您可以在此新增、編輯或刪除關鍵字。當 OCR
            識別出的文本內容中包含這些預設的關鍵字時,系統將自動將該文件標記為機敏檔案。
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <DataTable data={sensitives} columns={columns} />
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
