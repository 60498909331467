import React, { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import PasswordInput from '@/components/PasswordInput';
import { Button } from '@/components/ui/button';
import LandingLayout from '@/components/layout/landing-layout';
import { resetPasswordSchema } from '@/zodSchema/reset-password';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { ResetPasswordDocument } from '@@graphql';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { decodeJWT } from '@/lib/utils';
import LinkExpired from '@/components/link-expired';

const SuccessDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>密碼已更新</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">密碼已重新設定完成，請使用新密碼進行登入</div>
      </DialogContent>
    </Dialog>
  );
};

function ResetPassword() {
  const search = Route.useSearch() as { token: string };
  const { handleError, resetErrors, errors } = useErrorHandler({
    password: '',
    confirmPassword: '',
  });
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [resetPasswordMutation] = useMutation(ResetPasswordDocument, {
    onError: err => handleError(err),
    onCompleted: () => setIsSuccessDialogOpen(true),
  });
  const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
  const [isDone, setIsDone] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (!search.token) {
      setIsTokenValid(false);
      return;
    }

    try {
      const payload = decodeJWT(search.token);
      if (payload.exp && payload.exp < Date.now() / 1000) {
        setIsTokenValid(false);
      }
    } catch (error) {
      setIsTokenValid(false);
    }
  }, [search.token]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    resetErrors();
    try {
      resetPasswordSchema.parse(formData);
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const resetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    await resetPasswordMutation({
      variables: {
        password: formData.password,
        token: search.token,
      },
    });
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    setIsDone(true);
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  };

  if (!isTokenValid) {
    return <LinkExpired />;
  }

  return (
    <LandingLayout className="items-center">
      <div className="w-full max-w-md bg-white rounded-3xl px-12">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl text-center mb-4 text-neutral-900">重設密碼</h1>
        <p className="text-center text-neutral-500 mb-6">
          請重新設定密碼，設定完成後需重新登入
        </p>
        <div className="space-y-6">
          <form onSubmit={resetPassword} className="space-y-6">
            <div>
              <PasswordInput
                onChange={handleInputChange}
                value={formData.password}
                id="password"
                placeholder="輸入您的新密碼"
                className="py-6 text-neutral-400 border-neutral-300"
              />
              {errors.password && <p className="text-red-500">{errors.password}</p>}
            </div>
            <div>
              <PasswordInput
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="py-6 text-neutral-400 border-neutral-300"
                placeholder="再次確認您的新密碼"
              />
              {errors.confirmPassword && (
                <p className="text-red-500">{errors.confirmPassword}</p>
              )}
            </div>
            <Button
              type="submit"
              className="w-full py-3 rounded-lg font-semibold bg-indigo-600 hover:bg-indigo-700 text-white"
              disabled={isDone}
            >
              重設密碼
            </Button>
          </form>
        </div>
      </div>
      <SuccessDialog isOpen={isSuccessDialogOpen} onClose={handleSuccessDialogClose} />
    </LandingLayout>
  );
}

export const Route = createFileRoute('/reset-password')({
  component: ResetPassword,
});
