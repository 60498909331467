export enum FileType {
  PDF = 'PDF',
  DOCX = 'DOCX',
  XLSX = 'XLSX',
  IMG = 'IMG',
  PPTX = 'PPTX',
  UNKNOWN = 'UNKNOWN',
}

export enum LegalType {
  NON_PROSECUTION_DISPOSITION = '不起訴處分書',
  LEGAL_BRIEF = '書狀',
  OFFICIAL_LETTER = '函文',
  JUDGEMENT = '裁判',
  NOTICE = '通知書',
  SUMMONS = '傳票',
  CONTRACT = '契約',
  OTHER = '其他法律相關文件',
  LEGAL_PLEADING_COURT = '薪資類-法院執行命令',
  LEGAL_PLEADING_AED = '薪資類-行政執行署命令',
}
