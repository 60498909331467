import { QueryClient, useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { MutationPayload } from '@/types/tanstack.ts';

export type ApiError = {
  message: string;
  error: string;
  statusCode: number;
}

export const queryClient = new QueryClient();

export const useFileQuery = (url: string) => {
  return useQuery({
    queryKey: ['file', url],
    queryFn: async () => {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      });
      return response.blob();
    }
  });
};

export const useFileMutation = ({
                                  method,
                                  path,
                                  contentType = 'application/json'
                                }: {
  method: string;
  path: string;
  contentType?: 'application/json' | 'multipart/form-data';
}): UseMutationResult<{ blob: Blob; headers: Headers }, Error, MutationPayload> => {
  return useMutation({
    mutationFn: async (payload: MutationPayload) => {
      const url = `${import.meta.env.VITE_API_URL}${path}`;
      const headers: Record<string, string> = {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      let body: string | FormData | undefined;

      if (contentType === 'application/json' && payload && !(payload instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(payload);
      } else if (contentType === 'multipart/form-data' && payload instanceof FormData) {
        body = payload;
      }

      const response = await fetch(url, {
        method,
        headers,
        body
      });

      if (!response.ok) {
        let error;
        try {
          error = await response.json() as ApiError;
        } catch (err) {
          console.error(response.status, response.statusText);
          throw new Error(`未預期的錯誤`);
        }
        throw error;
      }

      return { blob: await response.blob(), headers: response.headers };
    }
  });
};
