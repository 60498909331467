import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { UserAvatar } from '@/components/layout/user-avatar';
import { User } from '@/types';
import { Link } from '@tanstack/react-router';

export function UserNav({ user }: { user: User }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none">
        <UserAvatar user={user} />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex items-center justify-start gap-4 p-2">
          <div className="flex flex-col space-y-1 leading-none">
            <p className="font-medium">{user.name}</p>
            <p className="w-[200px] truncate text-sm text-zinc-700">{user.email}</p>
          </div>
        </div>
        <DropdownMenuSeparator />
        <Link to="/profile/basic-settings">
          <DropdownMenuItem className="cursor-pointer">帳戶設定</DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        {user.isAdmin && (
          <>
            <Link to="/audit-reports">
              <DropdownMenuItem className="cursor-pointer">
                重要事項提醒
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
            <Link to="/file-history-records">
              <DropdownMenuItem className="cursor-pointer">
                文件歷史紀錄
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
            <Link to="/file-metrics-records">
              <DropdownMenuItem className="cursor-pointer">
                檔案流量紀錄
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
          </>
        )}
        <DropdownMenuItem
          onClick={async e => {
            e.preventDefault();
            localStorage.removeItem('accessToken');
            window.location.href = '/login';
          }}
          className="cursor-pointer"
        >
          登出
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
