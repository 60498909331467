import { cn } from '@/lib/utils';
import { Link, useRouterState } from '@tanstack/react-router';
import { buttonVariants } from '../ui/button';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CompanyConfigDocument, GroupOfCompaniesDocument } from '@@graphql';
import Loading from '@/components/loading.tsx';

const baseSidebarNavItems = [
  {
    title: '基本資料',
    href: '/profile/basic-settings',
  },
  {
    title: '2FA 雙重驗證',
    href: '/profile/2fa-settings',
  },
  {
    title: '第三方整合',
    href: '/profile/integration',
  },
];

interface NavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

const SideNav = ({ className, items, ...props }: NavProps) => {
  const router = useRouterState();
  return (
    <nav className={cn('flex space-x-2', className)} {...props}>
      {items.map(item => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            router.location.pathname === item.href
              ? 'bg-muted hover:bg-muted'
              : 'hover:bg-transparent hover:underline',
            'justify-start',
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
};

const ProfileLayout = ({ children }: { children: React.ReactNode }) => {
  const { data, error, loading } = useQuery(CompanyConfigDocument, {
    fetchPolicy: 'cache-first',
  });
  const { data: groupData } = useQuery(GroupOfCompaniesDocument, {
    fetchPolicy: 'cache-first',
  });

  const sidebarNavItems = useMemo(() => {
    if (data?.company?.hasSiblings && groupData?.groupOfCompanies?.length) {
      return [
        ...baseSidebarNavItems,
        {
          title: '切換公司',
          href: '/profile/change-company',
        },
      ];
    }
    return baseSidebarNavItems;
  }, [data, groupData]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="pt-6 pb-16 pl-6 pr-12 space-y-6">
      <div className="space-y-0.5">
        <h1 className="text-lg font-bold lg:text-2xl">帳戶設定</h1>
      </div>
      <div className="flex flex-col space-y-3">
        <aside>
          <SideNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

export default ProfileLayout;
