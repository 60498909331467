import { useMemo } from 'react';
import { FileText, Eye, ArrowUpDown, FileX } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/data-table';
import { Button } from '@/components/ui/button';
import { Report } from './';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@/constants';
import { Link } from '@tanstack/react-router';

const AuditTable = ({ data }: { data: Report[] }) => {
  const columns = useMemo<ColumnDef<Report>[]>(
    () => [
      {
        accessorKey: 'subject',
        header: () => '主旨',
        cell: ({ row }) => {
          const { fileUrl, subject } = row.original;
          const fileIsDeleted = fileUrl.endsWith('null');
          return (
            <Link disabled={fileIsDeleted} className="flex max-w-sm" target="_blank" to={fileIsDeleted ? '#' : fileUrl}>
              <span className="px-4">
                { fileIsDeleted ? <FileX size={20} strokeWidth={1.5} /> : <FileText size={20} strokeWidth={1.5} /> }
              </span>
              <span className="truncate">{subject}</span>
            </Link>
          );
        },
      },
      {
        accessorKey: 'createdAt',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            建立時間
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => {
          const { createdAt } = row.original;
          return <div>{dayjs(createdAt).format(DATE_TIME_FORMAT)}</div>;
        },
        sortDescFirst: true,
      },
      {
        accessorKey: 'begin',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            事件開始時間
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => {
          const { begin } = row.original;
          return <div>{dayjs(begin).format(DATE_TIME_FORMAT)}</div>;
        },
      },
      {
        accessorKey: 'end',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            事件結束時間
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => {
          const { end } = row.original;
          return <div>{dayjs(end).format(DATE_TIME_FORMAT)}</div>;
        },
      },
      {
        id: 'edit',
        header: () => <div className="text-center">內容</div>,
        cell: ({ row }) => {
          const { subject, procedure, content } = row.original;
          return (
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  className="size-8 text-typography-secondary w-full"
                  variant="ghost"
                  size="icon"
                >
                  <Eye size={20} strokeWidth={1.5} />
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>{subject}</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <b className="text-primary">程序事項</b>
                  <div>{procedure}</div>
                  <b className="text-primary">內容</b>
                  <div>{content}</div>
                </div>
              </DialogContent>
            </Dialog>
          );
        },
      },
    ],
    [],
  );

  return <DataTable data={data} columns={columns} />;
};

export default AuditTable;
