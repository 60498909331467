import { useLazyQuery, useMutation } from '@apollo/client';
import { CompanyConfigDocument, DeleteFilesDocument } from '@@graphql';
import { DeletePermanently } from '@/components/icons';
import { DialogClose, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useToast } from '@/components/ui/use-toast.ts';
import { useStore } from '@/store';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';

export const DeletePermanentlyAction = ({
  selectedFileIds,
  refetchFolder,
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const { toast } = useToast();
  const { handleError, resetErrors } = useErrorHandler({});
  const setCompanyConfig = useStore.companyConfig(state => state.setConfig);
  const [companyConfigQuery] = useLazyQuery(CompanyConfigDocument);
  const [deleteFiles] = useMutation(DeleteFilesDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      const { data } = await companyConfigQuery({
        fetchPolicy: 'network-only',
      });
      if (data?.company) {
        setCompanyConfig({ ...data.company });
      }
      toast({
        title: '刪除成功',
        duration: 1500,
      });
      closeDialog();
    },
  });

  const handleDelete = async () => {
    openDialog(
      '永久刪除檔案',
      <div>
        <DialogDescription className="text-sm">
          你確定要刪除選取的檔案嗎？這個操作無法復原
        </DialogDescription>
        <DialogDescription className="text-sm">
          只有該部門的管理者可以進行刪除
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            type="button"
            onClick={() => {
              resetErrors();
              deleteFiles({
                variables: { fileIds: selectedFileIds },
              });
            }}
          >
            確定刪除
          </Button>
        </DialogFooter>
      </div>,
    );
  };

  return {
    title: ACTION_TYPE_TITLE.DELETE_PERMANENTLY,
    icon: <DeletePermanently />,
    onClick: handleDelete,
  };
};
