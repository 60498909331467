import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useFieldArray } from 'react-hook-form';
import PleadingHtml from './pleading-html';
import KeywordSection from './keyword-section';
import { StepProps, SurveyData } from './shared';
import { Button } from '@/components/ui/button';
import { Download, Save } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useMutation } from '@apollo/client';
import {
  PleadingDocument,
  CreatePleadingDocument,
  UpdatePleadingDocument,
} from '@/graphql/generated/.graphql';

const MergeColumns: React.FC<StepProps> = ({
  fileId,
  pleadingId,
  prevStep,
  control,
  value,
  setValue,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef, documentTitle: '生成書狀' });
  const { toast } = useToast();

  const [createPleading] = useMutation(CreatePleadingDocument, {
    refetchQueries: [
      {
        query: PleadingDocument,
        variables: { fileId },
      },
    ],
    onCompleted: async () => {
      toast({
        title: '儲存書狀成功',
        duration: 1500,
      });
    },
    onError: () => {
      toast({
        title: '儲存書狀失敗',
        duration: 1500,
      });
    },
  });

  const [updatePleading] = useMutation(UpdatePleadingDocument, {
    onCompleted: async () => {
      toast({
        title: '更新書狀成功',
        duration: 1500,
      });
    },
    onError: () => {
      toast({
        title: '更新書狀失敗',
        duration: 1500,
      });
    },
  });

  const { fields: aiFields } = useFieldArray({
    control,
    name: 'AIKeywords',
  });

  const { fields: manualFields } = useFieldArray({
    control,
    name: 'manualKeywords',
  });

  if (!setValue || !control) return null;

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full p-3 h-[500px] bg-white overflow-auto border">
          <PleadingHtml ref={contentRef} value={value as SurveyData} />
          <div className="hidden">
            <div ref={contentRef} className="p-10">
              <PleadingHtml value={value as SurveyData} isExport />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-2 gap-4 mb-2">
            <div>AI 生成內容</div>
            <div>自訂內容</div>
          </div>
          <div className="flex gap-3">
            <KeywordSection
              templateId={value?.templateId as string}
              control={control}
              fields={aiFields}
              fieldArrayName="AIKeywords"
              setValue={setValue}
            />
            <KeywordSection
              templateId={value?.templateId as string}
              control={control}
              fields={manualFields}
              fieldArrayName="manualKeywords"
              setValue={setValue}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          {!pleadingId && (
            <Button type="button" onClick={prevStep}>
              上一步
            </Button>
          )}
        </div>
        <div className="flex gap-3">
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              const { AIKeywords, manualKeywords, templateId } = value as SurveyData;
              const variables = {
                aiKeywords: AIKeywords.map(keyword => ({
                  label: keyword.label,
                  used: keyword.used,
                  value: keyword.value,
                })),
                manualKeywords: manualKeywords.map(keyword => ({
                  label: keyword.label,
                  used: keyword.used,
                  value: keyword.value,
                })),
                templateId,
                fileId,
              };

              pleadingId
                ? updatePleading({
                    variables: {
                      ...variables,
                      updatePleadingId: pleadingId,
                    },
                  })
                : createPleading({
                    variables,
                  });
            }}
          >
            <Save className="mr-1" size={20} strokeWidth={1.5} />
            {`${pleadingId ? '更新' : '儲存'}書狀`}
          </Button>
          <Button type="button" onClick={() => reactToPrintFn()}>
            <Download className="mr-1" size={20} strokeWidth={1.5} />
            下載書狀
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MergeColumns;
