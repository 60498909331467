import { useEffect } from 'react';
import { create } from 'zustand';
import { useLocation } from '@tanstack/react-router';

const useRouteStore = create(set => ({
  history: [],
  updateLocation: newLocation =>
    set(state => {
      // 如果新位置與最後一個位置不同，則加到 history
      const updatedHistory =
        state.history.length === 0 ||
        state.history[state.history.length - 1].pathname !== newLocation.pathname
          ? [...state.history, newLocation]
          : state.history;
      return {
        history: updatedHistory.slice(-2), // 只保留最近兩個位置
      };
    }),
}));

const usePreviousLocation = () => {
  const location = useLocation();

  const { updateLocation, history } = useRouteStore();
  useEffect(() => {
    updateLocation(location);
  }, [location, updateLocation]);

  if (location.pathname === history[0]?.pathname) return null;
  // 回傳 history 中的第一個位置（上一頁）
  return history.length > 1 ? history[0] : null;
};

export default usePreviousLocation;
