// hooks/useQueryString.ts
import { useEffect, useState } from 'react';

type ParamProps = {
  [key: string]: string
};
const useQueryString = () => {
  const [query, setQuery] = useState<ParamProps>({} as ParamProps);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries()) as ParamProps;
    setQuery(params);
  }, []);

  return query;
};

export default useQueryString;