import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { FilesDocument } from '@@graphql';
import { FileModel, RealFileModel } from '@/types';
import { formatFileSize, transFormDate, transformFileType } from '@/utils/file.ts';
import FolderView from '@/pages/folderView.tsx';
import { useEffect, useState } from 'react';


export const Route = createFileRoute('/_layout/trash/')({
  component: Trash
});

function Trash() {
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const { data, error, loading, refetch } = useQuery(FilesDocument, {
    variables: { inTrash: true, departmentId: 'all' },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.files) {
      const fileRawData = data.files as FileModel[];
      const fileData = fileRawData.map(
        ({
           name,
           ext,
           isDir,
           id,
           size,
           createdAt,
           updatedAt,
           deletedAt,
           creatorName,
          isPrivate,
         }) => {
          deletedAt = deletedAt as Date;
          let source = transFormDate(createdAt)
          if (!isDir) {
            source += `-${creatorName}`;
          }
          return {
            id,
            updatedAt: transFormDate(updatedAt),
            createdAt: transFormDate(deletedAt),
            creationInfo: source,
            isDir,
            name,
            type: isDir ? undefined : transformFileType(ext),
            size: formatFileSize(size),
            isPrivate,
          };
        }
      ) as RealFileModel[];
      setFiles(fileData);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const refetchFolder = async (variables: any) => {
    await refetch(variables);
  };
  return (
    <FolderView
      folderName="資源回收桶"
      isDepartment={false}
      breadcrumbs={[]}
      refetchFolder={refetchFolder}
      files={files}
    />
  );
}
