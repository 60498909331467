import { ColumnDef } from '@tanstack/react-table';
import { DepartmentData, DialogActionType } from '@/types/department.ts';
import { ArrowUpDown } from 'lucide-react';
import DepartmentDialog from '@/components/department-dialog.tsx';
import RemoveDepartmentDialog from '@/components/removeDepartmentDialog.tsx';
import { DepartmentsQuery, DepartmentsQueryVariables } from '@@graphql';
import { ApolloQueryResult } from '@apollo/client';

export const departmentColumns = (
  refetch: (
    variables?: Partial<DepartmentsQueryVariables>,
  ) => Promise<ApolloQueryResult<DepartmentsQuery>>,
): ColumnDef<DepartmentData>[] => [
  {
    accessorKey: 'department',
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        部門名稱
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => row.getValue('department'),
  },
  {
    accessorKey: 'description',
    header: '部門描述',
    cell: ({ row }) => <div>{row.getValue('description')}</div>,
  },
  {
    id: 'edit',
    header: '編輯',
    cell: ({ row }) => {
      const { department, description, id } = row.original;
      return (
        <DepartmentDialog
          id={id}
          type={DialogActionType.EDIT}
          department={department}
          description={description}
          refetch={refetch}
        />
      );
    },
  },
  {
    id: 'delete',
    header: '刪除',
    cell: ({ row }) => {
      const { department, id, canRemove } = row.original;
      return (
        <RemoveDepartmentDialog
          id={id}
          department={department}
          refetch={refetch}
          canRemove={canRemove}
        />
      );
    },
  },
];
