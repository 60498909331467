import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import LandingLayout from '@/components/layout/landing-layout.tsx';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { VerifyEmailDocument } from '@@graphql';
import { useMutation } from '@apollo/client';
import { decodeJWT } from '@/lib/utils.tsx';
import LinkExpired from '@/components/link-expired.tsx';

function EmailVerification() {
  const search = Route.useSearch() as { token: string };
  const { handleError } = useErrorHandler({});
  const [verifyEmailMutation] = useMutation(VerifyEmailDocument, {
    onError: err => handleError(err),
    onCompleted: () => setIsDone(true),
  });
  const [isDone, setIsDone] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
  useEffect(() => {
    if (search.token) {
      try {
        const payload = decodeJWT(search.token);
        if (payload.exp < Date.now() / 1000) {
          setIsTokenValid(false);
        } else {
          setIsTokenValid(true);
        }
      } catch (error) {
        setIsTokenValid(false);
      }
    } else {
      setIsTokenValid(false);
    }
  }, [search.token]);

  useEffect(() => {
    if (isTokenValid) {
      verifyEmail(search.token);
    }
  }, [isTokenValid]);

  const verifyEmail = async (token: string) => {
    setIsVerifying(true);
    try {
      await verifyEmailMutation({
        variables: {
          token: token,
        },
      });
    } finally {
      setIsVerifying(false);
    }
  };
  if (isTokenValid === false) {
    return <LinkExpired />;
  }
  return (
    <LandingLayout className="items-center">
      <div className="w-full max-w-md bg-white rounded-3xl px-12 py-8">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl text-center mb-4 text-neutral-900">
          {isDone ? '信箱驗證成功' : '信箱驗證'}
        </h1>
        {!isDone ? (
          <p className="text-center text-neutral-500 mb-6">
            {isVerifying ? '正在驗證您的信箱...' : '驗證失敗，請重新驗證'}
          </p>
        ) : (
          <p className="text-center text-neutral-500 mb-6">
            您的信箱已成功驗證，現在可以使用所有功能了。
          </p>
        )}
      </div>
    </LandingLayout>
  );
}

export const Route = createFileRoute('/email-verification')({
  component: EmailVerification,
});
