import { cn, getFileIcon } from '@/lib/utils';
import { RealFileModel } from '@/types';
import { Row } from '@tanstack/react-table';
import { Lock, Pin } from 'lucide-react';
import { useTableStore } from '../data-table';
import { Checkbox } from '@/components/ui/checkbox';

type GridViewProps = {
  rows: Row<RealFileModel>[];
  rowProps?: (row: Row<RealFileModel>) => NonNullable<unknown>;
};

const GridView = ({ rows, rowProps }: GridViewProps) => {
  const { selectedFileIds } = useTableStore(state => state);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
      {rows.map(row => {
        const file = row.original;

        return (
          <div
            key={file.id}
            {...(rowProps && rowProps(row))}
            className={`h-[88px] bg-white hover:bg-primary-100 border px-2 py-3 rounded cursor-pointer`}
            role="listbox"
            aria-label={file.name}
          >
            <div className="grid grid-rows-3 grid-flow-col grid-cols-[100px_1fr] gap-0.5 text-sm">
              <div className="row-span-3 flex items-center">
                <div className="flex items-center">
                  <Checkbox
                    className={cn(
                      'mr-1 invisible',
                      selectedFileIds.length > 0 && 'visible',
                    )}
                    checked={row.getIsSelected()}
                    onCheckedChange={value => row.toggleSelected(!!value)}
                    aria-label="Select row"
                  />
                  {getFileIcon(file, 60)}
                </div>
              </div>
              <div className="col-span-1 truncate">{file.name}x</div>
              <div className="col-span-1">{file.size}</div>
              <div className="col-span-1">{file.updatedAt}</div>
              {file.isPrivate && <Lock size={16} className="text-neutral-500" />}
              {file.pinnedAt && <Pin size={16} className="text-neutral-500" />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const FileGridView = ({
  rows,
  rowProps,
}: {
  rows: Row<RealFileModel>[];
  rowProps?: (row: Row<RealFileModel>) => NonNullable<unknown>;
}) => {
  const folderFiles = rows.filter(row => row.original.isDir);
  const normalFiles = rows.filter(row => !row.original.isDir);

  return (
    <>
      {folderFiles.length ? (
        <>
          <p className="my-4 text-sm text-neutral-400">Folders</p>
          <GridView rows={folderFiles} rowProps={rowProps} />
        </>
      ) : null}
      {normalFiles.length ? (
        <>
          <p className="my-4 text-sm text-neutral-400">Files</p>
          <GridView rows={normalFiles} rowProps={rowProps} />
        </>
      ) : null}
    </>
  );
};

export default FileGridView;
