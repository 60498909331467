import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { UserLoginDocument } from '@@graphql';
import { AuthData, User } from '@/types';
import { RouteContext } from '@tanstack/react-router';
import { useUserStore } from '@/store/user.ts';

export interface AuthContext {
  isAuthenticated: boolean;
  login: (arg0: AuthData) => Promise<{
    requiresSelectCompany: boolean;
    requiresOtp: boolean;
    token?: string;
  }>;
  logout: () => Promise<void>;
  user: User | null;
}

const AuthContext = createContext<AuthContext | null>(null);

export interface AuthRouteContext extends RouteContext {
  auth?: AuthContext;
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const { user, isAuthenticated, setUser, clearUser } = useUserStore();
  const [userLogin] = useMutation(UserLoginDocument);

  const logout = useCallback(async () => {
    localStorage.removeItem('accessToken');
    clearUser();
  }, [clearUser]);

  const login = useCallback(
    async (authData: AuthData) => {
      const { data } = await userLogin({
        variables: authData,
      });
      if (!data?.login) throw new Error('No data returned from server');
      if (data.login.selectCompanyPath) {
        // select company
        return {
          requiresOtp: false,
          requiresSelectCompany: true,
          token: data.login.token,
        };
      }
      if (data.login.otpPath) {
        return {
          requiresOtp: true,
          requiresSelectCompany: false,
          token: data.login.token,
        };
      }
      const { accessToken, user: dbUser } = data.login;
      localStorage.setItem('accessToken', accessToken);
      setUser(dbUser);
      return { requiresOtp: false, requiresSelectCompany: false };
    },
    [setUser, userLogin],
  );

  const contextValue = useMemo(() => ({
    isAuthenticated,
    user,
    login,
    logout,
  }), [isAuthenticated, user, login, logout]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
