import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import { Card } from '@/components/ui/card';
import { useQuery } from '@apollo/client';
import { DepartmentsDocument } from '@@graphql';
import { useEffect } from 'react';
import { useStore } from '@/store';
import DepartmentHeader from '@/pages/settings/department-settings/department-table/department-header.tsx';
import DepartmentTable from '@/pages/settings/department-settings/department-table/department-table.tsx';

export const Route = createFileRoute('/_layout/settings/department-settings')({
  component: DepartmentSettings,
});

function DepartmentSettings() {
  const { data, error, loading, refetch } = useQuery(
    DepartmentsDocument,
    {
      fetchPolicy: 'network-only',
    },
  );
  const setDepartments = useStore.home((state) => state.setDepartments);

  useEffect(() => {
    if (data) {
      setDepartments(
        data?.departments.map(({ id, name, scope }: {id: string, name: string, scope: string}) => ({
          id,
          name,
          departmentId: id,
          scope,
        })),
      );
    }
  }, [data, setDepartments]);

  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  const departmentData =
    data?.departments.map(({ id, name, description, canRemove }: {
      id: string,
      name: string,
      description: string,
      canRemove: boolean
    }) => {
      return {
        id,
        department: name,
        description,
        canRemove,
      };
    }) ?? [];

  return (
    <SettingLayout>
      <Card>
        <DepartmentHeader refetch={refetch} />
        <DepartmentTable data={departmentData} refetch={refetch} />
      </Card>
    </SettingLayout>
  );
}
