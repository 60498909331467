import InfiniteScrollTable from '@/components/infinite-scroll-table';
import { DATE_TIME_FORMAT } from '@/constants';
import {
  ActionEventEnum,
  ActionLogModel,
  ActionLogsDocument,
  CreatorType,
} from '@/graphql/generated/.graphql';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import DatePickerWithRange from '@/components/date-picker-with-range';
import { DateRange } from 'react-day-picker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useState } from 'react';
import { Link } from '@tanstack/react-router';
import SortColumn from '@/components/sort-column';
import { Button } from '@/components/ui/button.tsx';
import { downloadFileFromUrl } from '@/utils/file.ts';
import { useToast } from '@/components/ui/use-toast.ts';
import { formatDateForFilename, objectToQueryString } from '@/utils';
import { Download } from 'lucide-react';

const ACTION_EVENT_LABEL_MAP = {
  [ActionEventEnum.All]: '所有行為',
  [ActionEventEnum.ViewFile]: '檢視檔案',
  [ActionEventEnum.CopyFile]: '複製檔案',
  [ActionEventEnum.UploadFileDocuai]: '事務機上傳',
  [ActionEventEnum.UploadFilesDocuai]: '批次事務機上傳',
  [ActionEventEnum.UploadFile]: '介面上傳',
  [ActionEventEnum.DownloadFile]: '下載檔案',
  [ActionEventEnum.DownloadFiles]: '批次下載',
  [ActionEventEnum.RemoveFile]: '移除檔案',
  [ActionEventEnum.DeleteFile]: '永久刪除檔案',
  [ActionEventEnum.RenameFile]: '重新命名檔案',
  [ActionEventEnum.MoveFile]: '移動檔案',
};

export const CREATOR_TYPE_LABEL_MAP = {
  [CreatorType.User]: '介面',
  [CreatorType.Mfp]: '事務機',
  [CreatorType.Email]: '電子郵件',
  [CreatorType.Unknown]: '白名單被移除',
};

const FileHistoryRecords = () => {
  const [createdDateRange, setCreatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const { toast } = useToast();
  const [event, setEvent] = useState<ActionEventEnum>(ActionEventEnum.All);
  const columns: ColumnDef<ActionLogModel>[] = [
    {
      header: ({ column }) => <SortColumn name="檔案名稱" column={column} />,
      accessorKey: 'file.name',
      cell: ({ row }) => {
        const { file, modelId } = row?.original || {};
        const name = file?.name || '-';

        if (!file) {
          return name;
        }

        return file?.deletedAt ? (
          <span>{name}</span>
        ) : (
          <Link to={`/files/${modelId}`}>{name}</Link>
        );
      },
      size: 300,
    },
    {
      header: ({ column }) => <SortColumn name="行為" column={column} />,
      accessorKey: 'event',
      cell: ({ row }) => {
        const event = row?.original?.event;
        return (
          <div>{event ? ACTION_EVENT_LABEL_MAP[event as ActionEventEnum] : ''}</div>
        );
      },
      size: 100,
    },
    {
      header: ({ column }) => <SortColumn name="操作人員帳號" column={column} />,
      accessorKey: 'creatorInfo.email',
      size: 250,
    },
    {
      header: ({ column }) => <SortColumn name="所屬部門" column={column} />,
      accessorKey: 'department.name',
    },
    {
      header: ({ column }) => <SortColumn name="檔案來源" column={column} />,
      accessorKey: 'creatorInfo.type',
      cell: ({ row }) => {
        const type = row?.original?.creatorInfo?.type;
        return <div>{type ? CREATOR_TYPE_LABEL_MAP[type] : ''}</div>;
      },
    },
    {
      header: ({ column }) => <SortColumn name="建立時間" column={column} />,
      accessorKey: 'createdAt',
      cell: ({ row }) => {
        const createdAt = row?.original?.createdAt;
        return <div>{createdAt ? dayjs(createdAt).format(DATE_TIME_FORMAT) : ''}</div>;
      },
    },
  ];

  const handleExport = async (path: string) => {
    try {
      const qs = objectToQueryString({
        event,
        startAt: createdDateRange?.from,
        endAt: createdDateRange?.to,
      });
      const filenameSuffix = formatDateForFilename(new Date(), 'YYYY-MM-DD HHmm');
      await downloadFileFromUrl(
        `${import.meta.env.VITE_API_URL}/${path}?${qs}`,
        `文件歷史紀錄-${filenameSuffix}.xlsx`,
      );
      toast({
        title: '下載成功',
        description: `文件 "${filenameSuffix}" 已成功下載。`,
        duration: 1500,
      });
    } catch (error) {
      toast({
        title: '下載失敗',
        description: '無法下載文件，請稍後再試。',
        variant: 'destructive',
        duration: 1500,
      });
    }
  };
  return (
    <div className="p-8">
      <h1 className="mb-4 text-lg font-bold md:text-2xl">文件歷史紀錄</h1>
      <div className="flex justify-between">
        <div className="flex flex-row gap-2">
          <DatePickerWithRange
            date={createdDateRange}
            setDate={setCreatedDateRange}
            placeholder="建立時間"
          />
          <div className="w-[150px] mb-4">
            <Select
              name="emailType"
              defaultValue={ActionEventEnum.All}
              onValueChange={(value: ActionEventEnum) => {
                setEvent(value);
              }}
            >
              <SelectTrigger className="bg-background hover:bg-accent hover:text-accent-foreground">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(ACTION_EVENT_LABEL_MAP)
                  .filter(
                    event =>
                      event !==
                      (ActionEventEnum.UploadFilesDocuai ||
                        ActionEventEnum.DownloadFiles),
                  )
                  .map(event => (
                    <SelectItem key={event} value={event}>
                      {ACTION_EVENT_LABEL_MAP[event as ActionEventEnum]}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <Button
            type="button"
            variant="outline"
            onClick={() => handleExport('api/usage-analytics/export-action-log')}
          >
            <Download className="mr-1" size={20} strokeWidth={1.5} />
            下載文件歷史紀錄
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <InfiniteScrollTable
          columns={columns}
          query={ActionLogsDocument}
          getVariables={cursor => ({
            event,
            after: cursor,
            startAt: createdDateRange?.from,
            endAt: createdDateRange?.to,
          })}
          queryKey={[
            'actionLogs',
            { event, startAt: createdDateRange?.from, endAt: createdDateRange?.to },
          ]}
          getNextPageParam={lastPage => lastPage?.actionLogs?.pageInfo?.endCursor}
          dataExtractor={(data: any) => data?.actionLogs?.items}
        />
      </div>
    </div>
  );
};

export default FileHistoryRecords;
