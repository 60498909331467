import { Options } from 'react-lottie';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { FileType } from '@/constants/files';
import {
  DocxFile,
  FullFolder,
  ImgFile,
  PdfFile,
  PptxFile,
  UnknownFile,
  XlsxFile,
} from '@/components/icons';
import { RealFileModel } from '@/types';
import { DEFAULT_CLICK_EVENTS, DEFAULT_CLICK_EVENT_MAPPING } from '@/constants';
import dayjs from 'dayjs';

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const getFileIcon = (file: RealFileModel, size?: number): JSX.Element => {
  if (file.isDir) {
    return <FullFolder size={size} />;
  }
  // if (file?.children) {
  //   return file.children.length > 0 ? (
  //   ) : (
  //     <EmptyFolder size={size} />
  //   );
  // }
  switch (file?.type) {
    case FileType.PDF:
      return <PdfFile size={size} />;
    case FileType.DOCX:
      return <DocxFile size={size} />;
    case FileType.XLSX:
      return <XlsxFile size={size} />;
    case FileType.IMG:
      return <ImgFile size={size} />;
    case FileType.PPTX:
      return <PptxFile size={size} />;
    case FileType.UNKNOWN:
    default:
      return <UnknownFile size={size} />;
  }
};

export const getAnimationConfig = ({ animationData }: Options) => ({
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

export const stopPropagationClickHandlers = (): Record<string, (e: Event) => void> => {
  return DEFAULT_CLICK_EVENTS.reduce(
    (handlers, event) => {
      const reactEvent = DEFAULT_CLICK_EVENT_MAPPING[event];
      if (reactEvent) {
        handlers[reactEvent] = (e: Event) => e.stopPropagation();
      }
      return handlers;
    },
    {} as Record<string, (e: Event) => void>,
  );
};

export const decodeJWT = (token: string) => {
  // Split the JWT into its three parts
  const parts = token.split('.');

  if (parts.length !== 3) {
    throw new Error('Invalid JWT token');
  }

  // Decode the payload (the second part of the JWT)
  const base64Url = parts[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  // Decode the base64 string into a JSON string
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  // Parse the JSON string into an object
  return JSON.parse(jsonPayload);
};

export const getTaiwanDate = (isoDateTime?: Date) => {
  const date = dayjs(isoDateTime);
  const taiwanYear = date.year() - 1911;
  return `${taiwanYear} 年 ${date.month() + 1} 月 ${date.date()} 日`;
};
