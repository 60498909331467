export type DepartmentData = {
  id: string;
  department: string;
  description?: string | null;
  canRemove: boolean;
};

export enum DialogActionType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
