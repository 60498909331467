import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { createFileRoute, Link, redirect, useRouter } from '@tanstack/react-router';
import { FormEvent, useState } from 'react';
import { loginSchema } from '@/zodSchema/login.ts';
import { z } from 'zod';
import { AuthRouteContext, useAuth } from '@/auth.tsx';
import LandingLayout from '@/components/layout/landing-layout';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';

const fallback = '/' as const;

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  beforeLoad: ({
    context,
    search,
  }: {
    context: AuthRouteContext;
    search: { redirect?: string };
  }) => {
    if (context?.auth?.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
  component: Login,
});

function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();
  const router = useRouter();
  const search = Route.useSearch();
  const errorObject = {
    email: '',
  };
  const { handleError, resetErrors, errors } = useErrorHandler(errorObject);

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    setIsSubmitting(true);

    const data = new FormData(e.currentTarget);
    const emailFieldValue = data.get('email');
    const passwordFieldValue = data.get('password');
    if (!emailFieldValue || !passwordFieldValue) {
      setIsSubmitting(false);
      return;
    }
    try {
      const authData = {
        email: emailFieldValue.toString(),
        password: passwordFieldValue.toString(),
      };
      loginSchema.parse(authData);
      const result = await auth.login(authData);
      if (result.requiresOtp) {
        return router.navigate({
          replace: true,
          to: '/otp-verification',
          search: { token: result.token, status: 'sent' },
        });
      }
      if (result.requiresSelectCompany) {
        return router.navigate({
          replace: true,
          to: '/select-company',
          search: { token: result.token },
        });
      }
      await router.invalidate();
      setTimeout(() => {
        router.navigate({
          to: search.redirect || fallback,
          replace: true,
        });
      }, 500);
    } catch (err: unknown) {
      setIsSubmitting(false);
      handleError(err);
    }
  };

  return (
    <LandingLayout>
      <div className="w-1/2">
        <div className="mt-12 flex flex-col items-center">
          <img src="/logo.svg" className="my-[50px]" alt="logo" />
          <form onSubmit={onFormSubmit} className="w-[300px] flex-1">
            <div className="flex flex-col gap-6">
              <Input className="h-11" type="email" name="email" placeholder="Email" />
              {errors.email && <div className="text-red-500">{errors.email}</div>}
              <Input
                autoComplete="current-password"
                type="password"
                name="password"
                placeholder="Password"
                className="h-11"
              />
              <Button type="submit" className="w-full h-12" disabled={isSubmitting}>
                登入
              </Button>
              <Link className="text-end text-sm" to="/forgot-password">
                忘記密碼？
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden lg:flex w-[500px]">
        <img src="/login-cover.jpg" className="w-mx-auto" alt="login-cover" />
      </div>
    </LandingLayout>
  );
}
