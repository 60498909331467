import { useState } from 'react';
import { Label } from '@/components/ui/label.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Eye, EyeOff } from 'lucide-react';
import { cn } from '@/lib/utils';

const PasswordInput = ({
  id,
  value,
  onChange,
  label,
  placeholder,
  className,
}: {
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string;
  placeholder?: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div className="space-y-1">
      {label && (
        <Label htmlFor={id} className="font-normal">
          {label}
        </Label>
      )}
      <div className="relative">
        <Input
          id={id}
          autoComplete="new-password"
          type={isVisible ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          className={cn('pr-10', className)}
          placeholder={placeholder}
        />
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="absolute right-0 top-0 h-full"
          onClick={toggleVisibility}
        >
          {isVisible ? (
            <Eye className="text-gray-400" />
          ) : (
            <EyeOff className="text-gray-400" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default PasswordInput;
