import * as z from 'zod';
import { FieldErrors, Control, UseFormSetValue } from 'react-hook-form';

export const AI_KEYWORDS = [
  { label: 'caseNumber', value: '', used: true },
  { label: 'stockType', value: '', used: true },
  { label: 'declarantName', value: '', used: true },
  { label: 'declarantAddress', value: '', used: true },
  { label: 'phone', value: '', used: false },
  { label: 'legalRepresentative', value: '', used: true },
  { label: 'debtorName', value: '', used: true },
  { label: 'executionLocation', value: '', used: true },
  { label: 'createdDate', value: '', used: false },
  { label: 'monthlySalary', value: '', used: false },
  { label: 'seizedCaseNumber', value: '', used: false },
  { label: 'seizedExecutionLocation', value: '', used: false },
  { label: 'publishedDate', value: '', used: false },
];

export const MANUAL_KEYWORDS = [
  { label: 'caseNumber', value: '', used: false },
  { label: 'stockType', value: '', used: false },
  { label: 'declarantName', value: '', used: false },
  { label: 'declarantAddress', value: '', used: false },
  { label: 'phone', value: '', used: true },
  { label: 'legalRepresentative', value: '', used: false },
  { label: 'debtorName', value: '', used: false },
  { label: 'executionLocation', value: '', used: false },
  { label: 'createdDate', value: '', used: true },
  { label: 'monthlySalary', value: '', used: true },
  { label: 'seizedCaseNumber', value: '', used: true },
  { label: 'seizedExecutionLocation', value: '', used: true },
  { label: 'publishedDate', value: '', used: true },
];

export enum KEYWORD_LABEL_NAME {
  caseNumber = '案號',
  stockType = '股別',
  declarantName = '受文者',
  declarantAddress = '受文者地址',
  phone = '電話',
  legalRepresentative = '法定代理人',
  debtorName = '債務人',
  executionLocation = '執行地方',
  createdDate = '收件日期',
  salaryDeductionDate = '薪資扣押日期',
  monthlySalary = '每月薪資',
  seizedCaseNumber = '被扣押案號',
  seizedExecutionLocation = '被扣押執行地方',
  publishedDate = '發佈日期',
}

export enum FieldType {
  RADIO = 'radio',
  NUMBER_INPUT = 'numberInput',
  TEXT_INPUT = 'textInput',
}

export enum GarnishmentStatus {
  NOT_EMPLOYEE = 'notEmployee',
  GARNISHED = 'garnished',
  PENDING = 'pending',
}

export type Question = {
  id: string;
  type: string;
  title: string;
  placeholder: string;
  description: string;
  options: {
    value: string;
    label: string;
    next: string;
  }[];
  next: string | null;
  template: string | null;
};

export const QUESTIONS: Question[] = [
  {
    id: 'q1',
    type: FieldType.RADIO,
    title: 'Q1: 債務人是否為貴公司在職員工?',
    placeholder: '',
    description: '',
    options: [
      {
        value: 'true',
        label: '是',
        next: 'q2',
      },
      {
        value: 'false',
        label: '否',
        next: 'a1',
      },
    ],
    next: null,
    template: null,
  },
  {
    id: 'a1',
    type: 'answer',
    title: '',
    placeholder: '',
    description: '',
    options: [],
    next: null,
    template: GarnishmentStatus.NOT_EMPLOYEE,
  },
  {
    id: 'q2',
    type: FieldType.RADIO,
    title: 'Q2: 債務人的薪資之前是否已被扣押?',
    placeholder: '',
    description: '',
    options: [
      {
        value: 'true',
        label: '是',
        next: 'q3',
      },
      {
        value: 'false',
        label: '否',
        next: 'a2',
      },
    ],
    next: null,
    template: null,
  },
  {
    id: 'a2',
    type: 'answer',
    title: '',
    placeholder: '',
    description: '',
    options: [],
    next: null,
    template: GarnishmentStatus.PENDING,
  },
  {
    id: 'q3',
    type: FieldType.NUMBER_INPUT,
    title: 'Q3: 債務人每月薪資多少元?',
    placeholder: '請輸入每月薪資',
    description: '',
    options: [],
    next: 'q4',
    template: null,
  },
  {
    id: 'q4',
    type: FieldType.TEXT_INPUT,
    title: 'Q4: 執行命令的案號?',
    placeholder: '請輸入執行命令的案號',
    description: '',
    options: [],
    next: 'q5',
    template: null,
  },
  {
    id: 'q5',
    type: FieldType.TEXT_INPUT,
    title: 'Q5: 執行命令的執行地方?',
    placeholder: '請輸入執行命令的執行地方',
    description: '',
    options: [],
    next: 'a3',
    template: null,
  },
  {
    id: 'a3',
    type: 'answer',
    title: '',
    placeholder: '',
    description: '',
    options: [],
    next: null,
    template: GarnishmentStatus.GARNISHED,
  },
];

export type Answers = {
  [key: string]: {
    ans?: string | number;
    prev: string | null;
  };
};

export const ANSWERS: Answers = {
  q1: {
    ans: 'true',
    prev: null, // 第一道題目沒有上一頁
  },
  a1: {
    ans: undefined,
    prev: 'q1',
  },
  q2: {
    ans: 'true',
    prev: 'q1',
  },
  a2: {
    ans: undefined,
    prev: 'q2',
  },
  q3: {
    ans: undefined,
    prev: 'q2',
  },
  q4: {
    ans: '',
    prev: 'q3',
  },
  q5: {
    ans: '',
    prev: 'q4',
  },
  a3: {
    ans: undefined,
    prev: 'q5',
  },
};

export const FILTER_KEYWORDS_BY_TEMPLATE: Record<GarnishmentStatus, string[]> = {
  [GarnishmentStatus.NOT_EMPLOYEE]: [
    'monthlySalary',
    'salaryDeductionDate',
    'seizedCaseNumber',
    'seizedExecutionLocation',
  ],
  [GarnishmentStatus.PENDING]: [
    'monthlySalary',
    'seizedCaseNumber',
    'seizedExecutionLocation',
  ],
  [GarnishmentStatus.GARNISHED]: ['salaryDeductionDate'],
} as const;

export const KeywordSchema = z.object({
  label: z.string(),
  used: z.boolean(),
  value: z.union([z.number(), z.string()]).optional(),
});

export type Keyword = z.infer<typeof KeywordSchema>;

export const surveySchema = z.object({
  templateId: z.string(),
  answers: z.record(
    z.string(),
    z.object({
      ans: z.union([z.number(), z.string(), z.null()]),
      prev: z.union([z.string(), z.null()]),
    }),
  ),
  AIKeywords: z.array(KeywordSchema),
  manualKeywords: z.array(KeywordSchema),
});

export type SurveyData = z.infer<typeof surveySchema>;

export interface StepProps {
  fileId: string;
  pleadingId?: string;
  nextStep?: () => void;
  prevStep?: () => void;
  generatePleadingStep?: () => void;
  setStep?: React.Dispatch<React.SetStateAction<number | null>>;
  errors?: FieldErrors<SurveyData>;
  control?: Control<SurveyData>;
  value?: SurveyData;
  setValue?: UseFormSetValue<SurveyData>;
}
