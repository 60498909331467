import { createFileRoute } from '@tanstack/react-router';
import SettingLayout from '@/components/layout/setting-layout';
import { Card, CardContent, CardDescription, CardHeader } from '@/components/ui/card';
import { DataTable } from '@/components/data-table.tsx';
import { ColumnDef } from '@tanstack/react-table';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CompanyConfigDocument,
  CompanyKeywordsDocument,
  DepartmentDocument,
  DepartmentModel,
  DepartmentPromptsDocument,
  FileModel,
  ToggleDepartmentClassificationByDocument,
} from '@@graphql';
import { useCallback, useEffect, useState } from 'react';
import { DepartmentsDocument } from '@@graphql';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Input } from '@/components/ui/input.tsx';
import { Switch } from '@/components/ui/switch.tsx';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '@/components/ui/tabs-with-border';
import { Label } from '@/components/ui/label.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { groupColumns } from '@/pages/settings/group-dialog/group-columns';
import { useToast } from '@/components/ui/use-toast';
import { GroupData } from '@/pages/settings/group-dialog/types';
import CreatePromptDialog from '@/pages/settings/group-dialog/create-prompt-dialog';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Folder } from 'lucide-react';
import EmptyData from '@/components/empty-data';
import FtpSetting from '@/components/ftp-setting';
import CodePositionSetting from '@/components/code-position-setting';
import { Separator } from '@/components/ui/separator.tsx';
import { useStore } from '@/store';
import EnableFtpSwitch from '@/components/enableFtpSwitch';
import RemoveFtpDialog from '@/components/removeFtpDialog';

type DepartmentType = {
  id: string;
  name: string;
};

enum GroupType {
  DEPARTMENT = 'DEPARTMENT',
  FIRST_LEVEL_FOLDER = 'FIRST_LEVEL_FOLDER',
}

const GroupTypeLabel: Record<GroupType, string> = {
  [GroupType.DEPARTMENT]: '部門分類',
  [GroupType.FIRST_LEVEL_FOLDER]: '資料夾分類',
};

type DepartmentPromptType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  keywords: string;
  enabled: boolean;
  department: DepartmentType;
};

const keywordsColumns: ColumnDef<DepartmentPromptType>[] = [
  {
    accessorKey: 'keywords',
    header: '關鍵字',
  },
  {
    accessorKey: 'department.name',
    header: '部門名稱',
  },
  {
    accessorKey: 'topFolder.name',
    header: '資料夾名稱',
  },
  {
    accessorKey: 'enabled',
    header: '啟用',
    cell: ({ row }) => <Switch checked={row.original.enabled} disabled={true} />,
  },
];

export const Route = createFileRoute('/_layout/settings/keywords')({
  component: Keywords,
});

function Keywords() {
  const { toast } = useToast();
  const {
    data: companyKeywordsData,
    loading: isCompanyKeywordsDataLoading,
    refetch: refetchCompanyKeywordsData,
  } = useQuery(CompanyKeywordsDocument);
  const [keywords, setKeywords] = useState(
    companyKeywordsData?.departmentPrompts ?? [],
  );
  const [search, setSearch] = useState<string>('');
  const [groupType, setGroupType] = useState<GroupType>(GroupType.DEPARTMENT);
  const [departmentId, setDepartmentId] = useState<string>('');
  const [groupData, setGroupData] = useState<GroupData[]>([]);
  const [showGroupTypeDialog, setShowGroupTypeDialog] = useState(false);
  const companyConfig = useStore.companyConfig(state => state.config);
  const ftpAccountInfo = companyConfig?.ftpAccount;
  const [showFtp, setShowFtp] = useState(!!ftpAccountInfo);
  const [currentFtpPassword, setCurrentFtpPassword] = useState<string | undefined>(
    undefined,
  );
  const hasGroupData = groupData.length !== 0;

  const { data: departmentsData } = useQuery(DepartmentsDocument, {
    fetchPolicy: 'cache-and-network',
  });

  const departments =
    departmentsData?.departments.map(({ id, name, canRemove }: DepartmentModel) => {
      return {
        id,
        name,
        canRemove,
      };
    }) ?? [];

  const { data: departmentData, refetch: refetchDepartment } = useQuery(
    DepartmentDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        departmentId,
      },
      skip: !departmentId,
    },
  );

  const department: DepartmentModel = departmentData?.department;
  const firstLevelFolders: FileModel[] = department?.firstLevelFolders || [];

  const [departmentPromptsQuery, { refetch: refetchDepartmentPrompts }] = useLazyQuery(
    DepartmentPromptsDocument,
    {
      variables: { departmentId },
      fetchPolicy: 'network-only',
    },
  );

  const [toggleDepartmentClassificationBy] = useMutation(
    ToggleDepartmentClassificationByDocument,
    {
      onCompleted: () => {
        refetchDepartmentPrompts();
      },
    },
  );

  useEffect(() => {
    setGroupType(
      (department?.classificationBy as unknown as GroupType) || GroupType.DEPARTMENT,
    );
  }, [department?.classificationBy, departmentId]);

  useEffect(() => {
    setShowFtp(!!ftpAccountInfo);
  }, [ftpAccountInfo]);

  const fetchPrompts = useCallback(
    async (departmentId?: string) => {
      const { data, error, loading } = await departmentPromptsQuery({
        variables: { departmentId },
      });
      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error.message}</div>;
      const departmentPrompts = data?.departmentPrompts || [];
      const formattedData =
        departmentPrompts.map(
          ({
            id,
            enabled,
            keywords,
            topFolder,
          }: {
            id: string;
            enabled: boolean;
            keywords: Array<string>;
            topFolder: FileModel;
          }) => ({
            id,
            keywords: keywords.join(', '),
            enable: enabled,
            topFolder,
          }),
        ) || [];
      setGroupData(formattedData);
      refetchDepartment();
    },
    [departmentPromptsQuery, refetchDepartment],
  );

  useEffect(() => {
    if (search) {
      setKeywords(
        companyKeywordsData.departmentPrompts.filter((item: DepartmentPromptType) =>
          item.keywords.toString().includes(search),
        ),
      );
    } else {
      if (companyKeywordsData) {
        setKeywords(companyKeywordsData.departmentPrompts);
      }
    }
  }, [companyKeywordsData, search]);

  if (isCompanyKeywordsDataLoading) {
    return <div>loading...</div>;
  }

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const selectDepartment = (value: string) => {
    setDepartmentId(value);
    fetchPrompts(value);
  };

  const switchGroupType = (value: GroupType) => {
    if (hasGroupData) {
      setShowGroupTypeDialog(true);
    } else {
      toggleDepartmentClassificationBy({
        variables: {
          departmentId,
          classificationBy: value,
        },
      });
      setGroupType(value);
    }
  };

  return (
    <SettingLayout>
      <Card>
        <Tabs
          defaultValue="list"
          className="p-6"
          onValueChange={() => {
            refetchCompanyKeywordsData();
          }}
        >
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="list">關鍵字使用列表</TabsTrigger>
            <TabsTrigger value="setting">關鍵字分類</TabsTrigger>
            <TabsTrigger value="qrcode">特定文件分類</TabsTrigger>
          </TabsList>
          <TabsContent value="list">
            <CardHeader>
              <CardDescription>您可以在此查看所有關鍵字的使用情況。</CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <Input className="w-64" placeholder="搜尋關鍵字" onInput={handleSearch} />
              <DataTable data={keywords} columns={keywordsColumns} />
            </CardContent>
          </TabsContent>
          <TabsContent value="setting">
            <CardHeader>
              <CardDescription>
                關鍵字作為一般文件分類的依據，設定後文件會自動歸類到相應的部門或者資料夾，此頁面可以針對部門或者資料夾新增關鍵字，設定分類的規則。
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="flex flex-col gap-4 mb-5">
                <div className="flex items-center gap-2">
                  <Label htmlFor="name" className="font-normal">
                    選擇部門
                  </Label>
                  <Select onValueChange={selectDepartment} value={departmentId}>
                    <SelectTrigger className="w-[200px] focus:ring-transparent text-typography-secondary">
                      <SelectValue placeholder="請選擇部門" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {departments.map(({ id, name, canRemove }: DepartmentModel) => (
                          <SelectItem key={id} value={id} disabled={!canRemove}>
                            {name}
                            {canRemove ? '' : ' (系統預設分類)'}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                {departmentId && (
                  <div>
                    <RadioGroup
                      className="flex gap-5 my-3"
                      value={groupType}
                      onValueChange={switchGroupType}
                    >
                      {Object.values(GroupType).map((type: GroupType) => (
                        <div className="flex items-center space-x-2" key={type}>
                          <RadioGroupItem value={type} id={type} />
                          <Label htmlFor={type} className="cursor-pointer">
                            {GroupTypeLabel[type]}
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                    {groupType === GroupType.DEPARTMENT ? (
                      <>
                        <div className="flex justify-end items-center mb-3">
                          <CreatePromptDialog
                            departmentId={departmentId}
                            onPromptCreated={fetchPrompts}
                            refetchQueries={[
                              {
                                query: DepartmentDocument,
                                variables: { departmentId },
                              },
                            ]}
                          />
                        </div>
                        <DataTable
                          data={groupData}
                          columns={groupColumns(departmentId, toast, fetchPrompts)}
                        />
                      </>
                    ) : (
                      <div>
                        {firstLevelFolders.length !== 0 ? (
                          <div className="space-y-6">
                            {firstLevelFolders.map(({ id, name }) => {
                              const folderKeywords = groupData.filter(
                                data => data.topFolder?.id === id,
                              );
                              return (
                                <div key={id} className="p-4 pt-3 border rounded-lg">
                                  <div className="flex justify-between items-center mb-3">
                                    <div className="flex items-center gap-2">
                                      <Folder size={20} strokeWidth={1.5} />
                                      {name}
                                    </div>
                                    <CreatePromptDialog
                                      departmentId={departmentId}
                                      onPromptCreated={fetchPrompts}
                                      fileId={id}
                                      refetchQueries={[
                                        {
                                          query: DepartmentDocument,
                                          variables: { departmentId },
                                        },
                                      ]}
                                    />
                                  </div>
                                  <DataTable
                                    data={folderKeywords}
                                    columns={groupColumns(
                                      departmentId,
                                      toast,
                                      fetchPrompts,
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <EmptyData title="目前沒有資料夾" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CardContent>
          </TabsContent>
          <TabsContent value="qrcode">
            <CardHeader>
              <CardDescription>
                QR Code 作為特定文件分類的依據，請將各部門生成的 QR Code
                新增至文件，系統會根據文件上特定位置的 QR
                Code，自動將文件歸類到對應的部門。
              </CardDescription>
              <CardDescription>
                注意：啟用公司 FTP 之後，請至事務機變更設定再進行掃描，才會執行 QR Code
                分類流程。
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2 h-auto">
              {showFtp ? (
                <div className="space-y-6 w-[600px]">
                  <FtpSetting
                    ftpAccountId={ftpAccountInfo?.id}
                    account={ftpAccountInfo?.account || ''}
                    password={currentFtpPassword}
                    refetchQueries={[
                      {
                        query: CompanyConfigDocument,
                      },
                    ]}
                  />
                  <Separator />
                  <CodePositionSetting
                    ftpAccountId={ftpAccountInfo?.id}
                    defaultRegion={ftpAccountInfo?.processConfig.regionKey}
                    refetchQueries={[
                      {
                        query: CompanyConfigDocument,
                      },
                    ]}
                  />
                  <Separator />
                  <RemoveFtpDialog
                    id={ftpAccountInfo?.id}
                    refetchQueries={[
                      {
                        query: CompanyConfigDocument,
                      },
                    ]}
                  />
                </div>
              ) : (
                <EnableFtpSwitch
                  checked={showFtp}
                  onCheckedChange={setShowFtp}
                  setCurrentFtpPassword={setCurrentFtpPassword}
                  refetchQueries={[
                    {
                      query: CompanyConfigDocument,
                    },
                  ]}
                />
              )}
            </CardContent>
          </TabsContent>
        </Tabs>
      </Card>
      <Dialog open={showGroupTypeDialog} onOpenChange={setShowGroupTypeDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>請刪除關鍵字資料</DialogTitle>
            <DialogDescription>切換分類方式前需清空關鍵字</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-sm">
            為了避免資料未清空而造成的分類錯誤，請先清空分類中的關鍵字資料，才能修改分類方式。
          </div>
          <DialogFooter>
            <Button type="button" onClick={() => setShowGroupTypeDialog(false)}>
              我瞭解了
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </SettingLayout>
  );
}
