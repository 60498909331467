import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createRouter, RouterProvider } from '@tanstack/react-router';

// Import the generated route tree
import { ApolloProvider } from '@apollo/client';
import { client as ApolloClient } from '@/apollo';
import { routeTree } from '@/routeTree.gen.ts';
import { AuthContext, AuthProvider, useAuth } from '@/auth.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/tanstack';

// Set up a Router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    auth: null as unknown as AuthContext
  },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const auth = useAuth()
  return <RouterProvider
    router={router}
    context={{ auth }}
  />
}

function App() {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <ApolloProvider client={ApolloClient}>
      <QueryClientProvider client={queryClient}>
        <StrictMode>
          <App />
        </StrictMode>
      </QueryClientProvider>
    </ApolloProvider>
  );
}
