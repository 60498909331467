import LandingLayout from '@/components/layout/landing-layout.tsx';
import { Button } from '@/components/ui/button.tsx';

const LinkExpired: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <LandingLayout className="items-center h-[580px]">
      <div className="w-full max-w-md bg-white rounded-3xl px-12 py-8 text-center">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl font-bold mb-4 text-neutral-900">連結已失效</h1>
        <div className="text-neutral-500 mb-6">
          {children ? children : '此連結已失效，請點擊按鈕返回登入畫面'}
        </div>
        <Button
          onClick={() => (window.location.href = '/login')}
          className="w-full py-3 rounded-lg font-semibold bg-indigo-600 hover:bg-indigo-700 text-white"
        >
          返回登入頁面
        </Button>
      </div>
    </LandingLayout>
  );
};

export default LinkExpired;
