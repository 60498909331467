import { z } from 'zod';

export const updateMeSchema = z
  .object({
    name: z.string().min(2, { message: '最少要 2 個字元' }),
    currentPassword: z.string().optional(),
    newPassword: z.string().optional(),
    confirmPassword: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword && data.newPassword.length < 6) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 6,
        type: 'string',
        inclusive: true,
        message: '至少要 6 位數',
        path: ['newPassword'],
      });
    }

    if (
      data.currentPassword &&
      data.newPassword &&
      data.currentPassword === data.newPassword
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '新密碼不可與當前密碼相同',
        path: ['newPassword'],
      });
    }

    if (
      data.newPassword &&
      data.confirmPassword &&
      data.newPassword !== data.confirmPassword
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '確認密碼必須與新密碼相同',
        path: ['confirmPassword'],
      });
    }

    if (data.newPassword && !data.currentPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '必須填寫',
        path: ['currentPassword'],
      });
    }

    if (data.currentPassword && !data.newPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '必須填寫',
        path: ['newPassword'],
      });
    }

    if (
      data.newPassword &&
      data.confirmPassword &&
      data.newPassword !== data.confirmPassword
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '確認密碼必須與新密碼相同',
        path: ['confirmPassword'],
      });
    }

    if (data.newPassword && !data.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '必須填寫',
        path: ['confirmPassword'],
      });
    }
  });
