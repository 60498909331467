import { cn } from '@/lib/utils';
import { Toaster } from '@/components/ui/toaster.tsx';

function LandingLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className="h-screen flex items-center justify-center">
      <div
        className={cn(
          'max-w-[1000px] h-[500px] m-0 rounded-lg sm:m-10 bg-white shadow flex justify-center flex-1 overflow-hidden',
          className,
        )}
      >
        {children}
      </div>
      <Toaster />
    </div>
  );
}

export default LandingLayout;
