import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button.tsx';
import { Card, CardContent, CardFooter } from '@/components/ui/card.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateMeDocument } from '@@graphql';
import { useToast } from '@/components/ui/use-toast.ts';
import { updateMeSchema } from '@/zodSchema/update-profile.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import ProfileLayout from '@/components/layout/profile-layout.tsx';
import { useAuth } from '@/auth.tsx';
import { Info } from 'lucide-react';
import PasswordInput from '@/components/PasswordInput.tsx';

function Profile() {
  const { toast } = useToast();
  const { user, logout } = useAuth();
  const [formData, setFormData] = useState({
    name: user?.name || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [basicProfileMutation] = useMutation(UpdateMeDocument);

  const validateForm = () => {
    try {
      resetErrors();
      updateMeSchema.parse(formData);
      return true;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const errorObject = {
    name: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({ ...prevData, [id]: value }));
  };

  const updateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const { data } = await basicProfileMutation({
        variables: {
          name: formData.name,
          password: formData.newPassword || undefined,
          currentPassword: formData.currentPassword || undefined,
        },
      });
      if (data?.updateMe) {
        if (formData.newPassword && formData.currentPassword) {
          await logout();
          window.location.href = '/login';
          return;
        }
        toast({
          title: 'Success',
          description: '個人資料更新成功',
          duration: 1500,
        });
        setFormData(prevData => ({
          ...prevData,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }));
      }
    } catch (err) {
      handleError(err);
    }
  };
  return (
    <ProfileLayout>
      <Card className="w-full px-2 py-6 md:w-1/3">
        <form onSubmit={updateProfile}>
          <CardContent className="w-full space-y-2">
            <h2 className="text-lg font-bold">基本資料</h2>
            <div className="space-y-1">
              <Label htmlFor="name" className="font-normal">
                使用者名稱
              </Label>
              <Input
                autoComplete="username"
                id="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
              />
               {errors.name && (
                <div className="text-red-500">{errors.name}</div>
              )}
            </div>
            <h2 className="pt-3 text-lg font-bold">修改密碼</h2>
            <div className="inline-flex items-center px-3 py-2 text-blue-700 rounded-md bg-blue-50">
              <Info className="w-5 h-5 mr-2" />
              <p className="text-sm">
                在此更改您的密碼，儲存後、您將被登出並需要使用新密碼重新登入
              </p>
            </div>
            <div className="space-y-1">
              <PasswordInput
                id="currentPassword"
                value={formData.currentPassword}
                onChange={handleInputChange}
                label="當前密碼"
              />
            </div>
            <div className="space-y-1">
              <PasswordInput
                id="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                label="設定新密碼"
              />
              {errors.newPassword && (
                <div className="text-red-500">{errors.newPassword}</div>
              )}
            </div>
            <div className="space-y-1">
              <PasswordInput
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                label="再次輸入新密碼"
              />
              {errors.confirmPassword && (
                <div className="text-red-500">{errors.confirmPassword}</div>
              )}
            </div>
          </CardContent>
          <CardFooter className={'flex justify-between gap-2'}>
            <Button type="submit">儲存變更</Button>
          </CardFooter>
        </form>
      </Card>
    </ProfileLayout>
  );
}

export const Route = createFileRoute('/_layout/profile/basic-settings')({
  component: Profile,
});
