export const FolderClose = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 2.5C0.25 1.25736 1.25736 0.25 2.5 0.25H5.92963C6.8491 0.25 7.70774 0.709528 8.21777 1.47457L9.40139 3.25H16.5C18.0188 3.25 19.25 4.48122 19.25 6V6.5C19.25 6.58866 19.2346 6.67373 19.2064 6.75269C20.8695 6.82532 22.0996 8.36054 21.7845 10.0146L20.5464 16.5146C20.2994 17.8116 19.1654 18.75 17.845 18.75H3C1.48122 18.75 0.25 17.5188 0.25 16V2.5ZM5.3323 17.25H17.845C18.4452 17.25 18.9606 16.8235 19.0729 16.2339L20.311 9.73389C20.4577 8.9636 19.8672 8.25 19.0831 8.25H8.15501C7.55484 8.25 7.03938 8.67654 6.92708 9.26611L5.51742 16.6668C5.4784 16.8717 5.41563 17.067 5.3323 17.25ZM17.7927 6.75H8.15501C6.83464 6.75 5.70063 7.6884 5.45357 8.98544L4.04391 16.3862C3.94844 16.8874 3.51023 17.25 3 17.25C2.30964 17.25 1.75 16.6904 1.75 16V2.5C1.75 2.08579 2.08579 1.75 2.5 1.75H5.92963C6.34757 1.75 6.73786 1.95888 6.9697 2.30662L8.37596 4.41603C8.51506 4.62467 8.74924 4.75 9 4.75H16.5C17.1904 4.75 17.75 5.30964 17.75 6V6.5C17.75 6.58766 17.765 6.67181 17.7927 6.75Z"
      fill="currentColor"
    />
  </svg>
);

export const FolderOpen = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 1.75C2.08579 1.75 1.75 2.08579 1.75 2.5V16C1.75 16.6904 2.30964 17.25 3 17.25H19C19.6904 17.25 20.25 16.6904 20.25 16V6C20.25 5.30964 19.6904 4.75 19 4.75H9C8.74924 4.75 8.51506 4.62467 8.37596 4.41603L6.9697 2.30662C6.73786 1.95888 6.34757 1.75 5.92963 1.75H2.5ZM0.25 2.5C0.25 1.25736 1.25736 0.25 2.5 0.25H5.92963C6.8491 0.25 7.70774 0.709528 8.21777 1.47457L9.40139 3.25H19C20.5188 3.25 21.75 4.48122 21.75 6V16C21.75 17.5188 20.5188 18.75 19 18.75H3C1.48122 18.75 0.25 17.5188 0.25 16V2.5Z"
      fill="currentColor"
    />
  </svg>
);

export const FullFolder = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11C4.79086 11 3 12.7909 3 15V63C3 66.3137 5.68629 69 9 69H71C74.3137 69 77 66.3137 77 63V23C77 20.2386 74.7614 18 72 18H29L27.039 13.4243C26.4087 11.9536 24.9625 11 23.3624 11H7Z"
      fill="#7F84EC"
    />
    <path
      d="M72.5151 25C73.8875 25 75 23.8807 75 22.5C75 21.1193 73.8875 20 72.5151 20H29.8572L30.9673 22.5904C31.601 24.0528 33.0428 25 34.6376 25H72.5151Z"
      fill="#2B23A4"
    />
    <path
      d="M31.9918 24H68.0001V17C68.0001 15.8954 67.1046 15 66.0001 15H27.7144L30.9611 22.5757C31.1997 23.1325 31.5552 23.6151 31.9918 24Z"
      fill="#DADEF6"
    />
    <path
      d="M72 25V22C72 20.8954 71.1046 20 70 20H29.8572L30.961 22.5757C31.5914 24.0464 33.0375 25 34.6376 25H72Z"
      fill="#F7F8FE"
    />
    <path
      d="M8.91704 69H70.917C73.89 69 76.358 66.8377 76.8341 64H3C3.47608 66.8377 5.94405 69 8.91704 69Z"
      fill="#5346E6"
    />
    <path
      d="M8 64H3.08301C3.50452 66.5125 5.48752 68.4955 8 68.917V64Z"
      fill="#3D33C5"
    />
    <path
      d="M72 64L72 68.917C74.5125 68.4955 76.4955 66.5125 76.917 64L72 64Z"
      fill="#3D33C5"
    />
  </svg>
);

export const EmptyFolder = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11C4.79086 11 3 12.7909 3 15V63C3 66.3137 5.68629 69 9 69H71C74.3137 69 77 66.3137 77 63V23C77 20.2386 74.7614 18 72 18H29L27.039 13.4243C26.4087 11.9536 24.9625 11 23.3624 11H7Z"
      fill="#B5BBEF"
    />
    <path
      d="M8.91704 69H70.917C73.89 69 76.358 66.8377 76.8341 64H3C3.47608 66.8377 5.94405 69 8.91704 69Z"
      fill="#7F84EC"
    />
    <path
      d="M8 64H3.08301C3.50452 66.5125 5.48752 68.4955 8 68.917V64Z"
      fill="#5346E6"
    />
    <path
      d="M72 64L72 68.917C74.5125 68.4955 76.4955 66.5125 76.917 64L72 64Z"
      fill="#5346E6"
    />
    <path
      d="M72.5151 25C73.8875 25 75 23.8807 75 22.5C75 21.1193 73.8875 20 72.5151 20H29.8572L30.961 22.5757C31.5914 24.0464 33.0375 25 34.6376 25H72.5151Z"
      fill="#7F84EC"
    />
  </svg>
);

export const DocxFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#3366FF"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#7597FF" />
    <path
      d="M64.0434 34.9734C63.4764 34.5796 62.6975 34.7201 62.3037 35.2871L59.1641 39.8083L56.0244 35.2871C55.6306 34.7201 54.8517 34.5796 54.2847 34.9734C53.7176 35.3672 53.5772 36.1461 53.971 36.7131L57.6422 41.9997L53.9709 47.2864C53.5771 47.8535 53.7176 48.6324 54.2846 49.0261C54.8516 49.4199 55.6305 49.2794 56.0243 48.7124L59.1641 44.1912L62.3038 48.7124C62.6976 49.2794 63.4765 49.4199 64.0435 49.0261C64.6106 48.6324 64.751 47.8535 64.3572 47.2864L60.6859 41.9997L64.3572 36.7131C64.7509 36.1461 64.6105 35.3672 64.0434 34.9734Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 34.75C16.3096 34.75 15.75 35.3096 15.75 36V47.9993C15.75 48.6897 16.3096 49.2493 17 49.2493H20.6665C23.934 49.2493 26.5829 46.6004 26.5829 43.3329V40.6664C26.5829 37.3989 23.934 34.75 20.6665 34.75H17ZM18.25 46.7493V37.25H20.6665C22.5533 37.25 24.0829 38.7796 24.0829 40.6664V43.3329C24.0829 45.2197 22.5533 46.7493 20.6665 46.7493H18.25Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.416 40.1664C28.416 37.175 30.841 34.75 33.8324 34.75C36.8239 34.75 39.2489 37.175 39.2489 40.1664V43.8329C39.2489 46.8243 36.8239 49.2493 33.8324 49.2493C30.841 49.2493 28.416 46.8243 28.416 43.8329V40.1664ZM33.8324 37.25C32.2217 37.25 30.916 38.5557 30.916 40.1664V43.8329C30.916 45.4436 32.2217 46.7493 33.8324 46.7493C35.4431 46.7493 36.7489 45.4436 36.7489 43.8329V40.1664C36.7489 38.5557 35.4431 37.25 33.8324 37.25Z"
      fill="white"
    />
    <path
      d="M46.8317 34.75C43.8403 34.75 41.4153 37.175 41.4153 40.1664V43.8329C41.4153 46.8243 43.8403 49.2493 46.8317 49.2493C49.1377 49.2493 51.1041 47.8088 51.8861 45.7829C52.1347 45.1389 51.8142 44.4153 51.1701 44.1667C50.5261 43.918 49.8025 44.2386 49.5539 44.8827C49.1316 45.9767 48.0703 46.7493 46.8317 46.7493C45.221 46.7493 43.9153 45.4436 43.9153 43.8329V40.1664C43.9153 38.5557 45.221 37.25 46.8317 37.25C48.0703 37.25 49.1316 38.0226 49.5539 39.1167C49.8025 39.7607 50.5261 40.0813 51.1701 39.8326C51.8142 39.584 52.1347 38.8604 51.8861 38.2164C51.1041 36.1905 49.1377 34.75 46.8317 34.75Z"
      fill="white"
    />
  </svg>
);

export const PdfFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#EB4545"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6533 34.75C23.3218 34.75 23.0039 34.8817 22.7694 35.1161C22.535 35.3505 22.4033 35.6685 22.4033 36V47.9993C22.4033 48.6897 22.963 49.2493 23.6533 49.2493C24.3437 49.2493 24.9033 48.6897 24.9033 47.9993V43.9163H28.653C31.1842 43.9163 33.2362 41.8643 33.2362 39.3331C33.2362 36.8019 31.1842 34.75 28.653 34.75H23.6533ZM28.653 41.4163H24.9033V37.25H28.653C29.8035 37.25 30.7362 38.1827 30.7362 39.3331C30.7362 40.4836 29.8035 41.4163 28.653 41.4163Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.9858 34.75H36.3193C35.629 34.75 35.0693 35.3096 35.0693 36V47.9993C35.0693 48.6897 35.629 49.2493 36.3193 49.2493H39.9858C43.2533 49.2493 45.9022 46.6004 45.9022 43.3329V40.6664C45.9022 37.3989 43.2533 34.75 39.9858 34.75ZM37.5693 46.7493V37.25H39.9858C41.8726 37.25 43.4022 38.7796 43.4022 40.6664V43.3329C43.4022 45.2197 41.8726 46.7493 39.9858 46.7493H37.5693Z"
      fill="white"
    />
    <path
      d="M48.0679 36C48.0679 35.3096 48.6275 34.75 49.3179 34.75H57.1507C57.8411 34.75 58.4007 35.3096 58.4007 36C58.4007 36.6904 57.8411 37.25 57.1507 37.25H50.5679V41.416H56.9845C57.6748 41.416 58.2345 41.9757 58.2345 42.666C58.2345 43.3564 57.6748 43.916 56.9845 43.916H50.5679V47.9993C50.5679 48.6897 50.0082 49.2493 49.3179 49.2493C48.6275 49.2493 48.0679 48.6897 48.0679 47.9993V36Z"
      fill="white"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#FF8C8C" />
  </svg>
);

export const XlsxFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#35BD73"
    />
    <path
      d="M55.9096 35.2871C55.5159 34.7201 54.737 34.5796 54.1699 34.9734C53.6029 35.3672 53.4624 36.1461 53.8562 36.7131L57.5275 41.9997L53.8561 47.2864C53.4624 47.8535 53.6028 48.6324 54.1699 49.0261C54.7369 49.4199 55.5158 49.2794 55.9096 48.7124L59.0493 44.1912L62.1891 48.7124C62.5828 49.2794 63.3617 49.4199 63.9288 49.0261C64.4958 48.6324 64.6363 47.8535 64.2425 47.2864L60.5712 41.9997L64.2424 36.7131C64.6362 36.1461 64.4957 35.3672 63.9287 34.9734C63.3617 34.5796 62.5828 34.7201 62.189 35.2871L59.0493 39.8083L55.9096 35.2871Z"
      fill="white"
    />
    <path
      d="M47.2166 40.75L44.8833 40.75C43.9169 40.75 43.1335 39.9666 43.1335 39.0002C43.1335 38.0338 43.9169 37.2503 44.8833 37.2503H46.6643C47.54 37.2503 48.3799 37.5982 48.9992 38.2175C49.2336 38.4519 49.5515 38.5836 49.8831 38.5836C50.5734 38.5836 51.1331 38.024 51.1331 37.3336C51.1331 36.9884 50.9931 36.6759 50.7669 36.4497C49.6789 35.3616 48.2031 34.7503 46.6643 34.7503L44.8833 34.7503C42.5362 34.7503 40.6335 36.6531 40.6335 39.0002C40.6335 41.3473 42.5362 43.25 44.8833 43.25L47.2166 43.25C48.183 43.25 48.9664 44.0334 48.9664 44.9998C48.9664 45.9662 48.183 46.7497 47.2166 46.7497H45.1023C44.2266 46.7497 43.3867 46.4018 42.7674 45.7825C42.2793 45.2944 41.4878 45.2944 40.9997 45.7825C40.5115 46.2707 40.5115 47.0621 40.9997 47.5503C42.0878 48.6384 43.5635 49.2497 45.1023 49.2497H47.2166C49.5637 49.2497 51.4664 47.3469 51.4664 44.9998C51.4664 42.6527 49.5637 40.75 47.2166 40.75Z"
      fill="white"
    />
    <path
      d="M18.5786 35.2876C18.1848 34.7206 17.4059 34.5801 16.8389 34.9739C16.2718 35.3677 16.1314 36.1466 16.5252 36.7136L20.1964 42.0001L16.5253 47.2864C16.1316 47.8535 16.272 48.6324 16.8391 49.0261C17.4061 49.4199 18.185 49.2794 18.5788 48.7124L21.7182 44.1916L24.858 48.7129C25.2518 49.2799 26.0307 49.4204 26.5977 49.0266C27.1648 48.6328 27.3052 47.8539 26.9114 47.2869L23.2401 42.0001L26.9116 36.7131C27.3054 36.1461 27.1649 35.3672 26.5979 34.9734C26.0309 34.5796 25.252 34.7201 24.8582 35.2871L21.7182 39.8087L18.5786 35.2876Z"
      fill="white"
    />
    <path
      d="M31.801 36C31.801 35.3096 31.2414 34.75 30.551 34.75C29.8607 34.75 29.301 35.3096 29.301 36V47.9993C29.301 48.6897 29.8607 49.2493 30.551 49.2493H38.2172C38.9076 49.2493 39.4672 48.6897 39.4672 47.9993C39.4672 47.3089 38.9076 46.7493 38.2172 46.7493H31.801V36Z"
      fill="white"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#71E6A6" />
  </svg>
);

export const ImgFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#DFECF2"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#C0DEEB" />
    <circle cx="25.5" cy="25.5" r="5.5" fill="#FDBF61" />
    <path
      d="M39.9262 62H23.0738C20.5914 62 19.1364 59.2324 20.56 57.2181L28.9862 44.2963C30.2078 42.5679 32.7922 42.5679 34.0138 44.2963L42.44 57.2181C43.8636 59.2324 42.4086 62 39.9262 62Z"
      fill="#4FECE2"
    />
    <path
      d="M58.6693 61.9998H36.0051C33.5965 61.9998 32.17 59.3046 33.5243 57.3129L44.8564 40.648C46.047 38.8973 48.6275 38.8973 49.818 40.648L61.1501 57.3129C62.5045 59.3046 61.0779 61.9998 58.6693 61.9998Z"
      fill="#2AD0C6"
    />
  </svg>
);

export const PptxFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#F47046"
    />
    <path
      d="M56.166 35.2871C55.7722 34.7201 54.9933 34.5796 54.4263 34.9734C53.8592 35.3672 53.7188 36.1461 54.1126 36.7131L57.7842 42.0002L54.1132 47.2864C53.7194 47.8535 53.8599 48.6324 54.4269 49.0261C54.994 49.4199 55.7729 49.2794 56.1666 48.7124L59.306 44.1917L62.4454 48.7124C62.8392 49.2794 63.6181 49.4199 64.1851 49.0261C64.7522 48.6324 64.8926 47.8535 64.4988 47.2864L60.8279 42.0002L64.4995 36.7131C64.8933 36.1461 64.7528 35.3672 64.1858 34.9734C63.6187 34.5796 62.8399 34.7201 62.4461 35.2871L59.306 39.8088L56.166 35.2871Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2587 35.1161C16.4931 34.8817 16.8111 34.75 17.1426 34.75H22.1423C24.6735 34.75 26.7254 36.8019 26.7254 39.3331C26.7254 41.8643 24.6735 43.9163 22.1423 43.9163H18.3926V47.9993C18.3926 48.6897 17.8329 49.2493 17.1426 49.2493C16.4522 49.2493 15.8926 48.6897 15.8926 47.9993V36C15.8926 35.6685 16.0243 35.3505 16.2587 35.1161ZM18.3926 41.4163H22.1423C23.2928 41.4163 24.2254 40.4836 24.2254 39.3331C24.2254 38.1827 23.2928 37.25 22.1423 37.25H18.3926V41.4163Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8081 34.75C29.4766 34.75 29.1586 34.8817 28.9242 35.1161C28.6898 35.3505 28.5581 35.6685 28.5581 36V47.9993C28.5581 48.6897 29.1177 49.2493 29.8081 49.2493C30.4985 49.2493 31.0581 48.6897 31.0581 47.9993V43.9163H34.8078C37.339 43.9163 39.391 41.8643 39.391 39.3331C39.391 36.8019 37.339 34.75 34.8078 34.75H29.8081ZM34.8078 41.4163H31.0581V37.25H34.8078C35.9583 37.25 36.891 38.1827 36.891 39.3331C36.891 40.4836 35.9583 41.4163 34.8078 41.4163Z"
      fill="white"
    />
    <path
      d="M41.5571 36C41.5571 35.3096 42.1168 34.75 42.8071 34.75H50.8067C51.497 34.75 52.0567 35.3096 52.0567 36C52.0567 36.6904 51.497 37.25 50.8067 37.25H48.2239V47.9993C48.2239 48.6897 47.6642 49.2493 46.9739 49.2493C46.2835 49.2493 45.7239 48.6897 45.7239 47.9993V37.25H42.8071C42.1168 37.25 41.5571 36.6904 41.5571 36Z"
      fill="white"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#FFA183" />
  </svg>
);

export const UnknownFile = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8 4.47715 12.4772 0 18 0L52 0L72 20V70C72 75.5229 67.5228 80 62 80H18C12.4772 80 8 75.5229 8 70V10Z"
      fill="#D0DFF1"
    />
    <path d="M56 20H72L52 0V16C52 18.2091 53.7909 20 56 20Z" fill="#A8C0DC" />
    <rect x="20" y="30" width="10" height="7" rx="2" fill="#A8C0DC" />
    <rect x="33" y="30" width="27" height="7" rx="2" fill="#A8C0DC" />
    <rect x="20" y="44" width="40" height="3" rx="1.5" fill="#A8C0DC" />
    <rect x="20" y="54" width="40" height="3" rx="1.5" fill="#A8C0DC" />
  </svg>
);

export const Search = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C14.0041 17.25 17.25 14.0041 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75ZM1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10Z"
      fill="#5C576D"
    />
    <rect
      x="16.8853"
      y="18.2993"
      width="2"
      height="6.97888"
      rx="1"
      transform="rotate(-45 16.8853 18.2993)"
      fill="#5C576D"
    />
  </svg>
);

export const More = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="1" fill="#5C576D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
      fill="#5C576D"
    />
    <circle cx="12" cy="16" r="1" fill="#5C576D" />
    <circle cx="12" cy="8" r="1" fill="#5C576D" />
  </svg>
);

export const Share = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4697 13.0303C16.1768 12.7374 16.1768 12.2626 16.4697 11.9697L19.9393 8.5L16.4697 5.03033C16.1768 4.73744 16.1768 4.26256 16.4697 3.96967C16.7626 3.67678 17.2374 3.67678 17.5303 3.96967L21.5303 7.96967C21.8232 8.26256 21.8232 8.73744 21.5303 9.03033L17.5303 13.0303C17.2374 13.3232 16.7626 13.3232 16.4697 13.0303Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 14.5C8.25 10.7721 11.2721 7.75 15 7.75H20.5C20.9142 7.75 21.25 8.08579 21.25 8.5C21.25 8.91421 20.9142 9.25 20.5 9.25H15C12.1005 9.25 9.75 11.6005 9.75 14.5C9.75 14.9142 9.41421 15.25 9 15.25C8.58579 15.25 8.25 14.9142 8.25 14.5Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 7C2.25 4.37665 4.37665 2.25 7 2.25H12C12.4142 2.25 12.75 2.58579 12.75 3C12.75 3.41421 12.4142 3.75 12 3.75H7C5.20507 3.75 3.75 5.20507 3.75 7V17C3.75 18.7949 5.20507 20.25 7 20.25H16C17.7949 20.25 19.25 18.7949 19.25 17V16C19.25 15.5858 19.5858 15.25 20 15.25C20.4142 15.25 20.75 15.5858 20.75 16V17C20.75 19.6234 18.6234 21.75 16 21.75H7C4.37665 21.75 2.25 19.6234 2.25 17V7Z"
      fill="#5C576D"
    />
  </svg>
);

export const Edit = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1993 4.25762C16.4666 2.97748 18.5333 2.97226 19.807 4.24599C21.0717 5.51065 21.0769 7.55947 19.8186 8.8305L18.0641 10.6028L7.52135 21.0748C7.38085 21.2144 7.19085 21.2927 6.99281 21.2927L3.45727 21.2927C3.04306 21.2927 2.70727 20.9569 2.70727 20.5427L2.70727 17.0072C2.70727 16.8074 2.78699 16.6159 2.92873 16.4751L13.4692 6.00527L15.1993 4.25762ZM13.9982 7.59404L4.20727 17.3193L4.20727 19.7927L6.68362 19.7927L16.4731 10.0689L13.9982 7.59404ZM17.5328 9.00735L15.058 6.53249L16.2653 5.31291C16.9477 4.62361 18.0605 4.6208 18.7464 5.30665C19.4273 5.98762 19.4301 7.09082 18.7526 7.77521L17.5328 9.00735Z"
      fill="#5C576D"
    />
  </svg>
);

export const Duplicate = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46967 1.46967C8.61032 1.32902 8.80109 1.25 9 1.25H14.5C16.0188 1.25 17.25 2.48122 17.25 4V16C17.25 16.9665 16.4665 17.75 15.5 17.75H5.5C3.98122 17.75 2.75 16.5188 2.75 15V7.5C2.75 7.30109 2.82902 7.11032 2.96967 6.96967L8.46967 1.46967ZM14.5 2.75H9.75V6.5C9.75 7.4665 8.9665 8.25 8 8.25H4.25V15C4.25 15.6904 4.80964 16.25 5.5 16.25H15.5C15.6381 16.25 15.75 16.1381 15.75 16V4C15.75 3.30964 15.1904 2.75 14.5 2.75ZM5.31066 6.75H8C8.13807 6.75 8.25 6.63807 8.25 6.5V3.81066L5.31066 6.75Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 5.25C20.9142 5.25 21.25 5.58579 21.25 6V19C21.25 20.5188 20.0188 21.75 18.5 21.75H7.5C7.08579 21.75 6.75 21.4142 6.75 21C6.75 20.5858 7.08579 20.25 7.5 20.25H18.5C19.1904 20.25 19.75 19.6904 19.75 19V6C19.75 5.58579 20.0858 5.25 20.5 5.25Z"
      fill="#5C576D"
    />
  </svg>
);

export const Move = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 4.5C3.25 3.25736 4.25736 2.25 5.5 2.25H8.92963C9.8491 2.25 10.7077 2.70953 11.2178 3.47457L12.4014 5.25H20C21.5188 5.25 22.75 6.48122 22.75 8V19C22.75 20.5188 21.5188 21.75 20 21.75H6C4.48122 21.75 3.25 20.5188 3.25 19V18C3.25 17.5858 3.58579 17.25 4 17.25C4.41421 17.25 4.75 17.5858 4.75 18V19C4.75 19.6904 5.30964 20.25 6 20.25H20C20.6904 20.25 21.25 19.6904 21.25 19V8C21.25 7.30964 20.6904 6.75 20 6.75H12C11.7492 6.75 11.5151 6.62467 11.376 6.41603L9.9697 4.30662C9.73786 3.95888 9.34757 3.75 8.92963 3.75H5.5C5.08579 3.75 4.75 4.08579 4.75 4.5V9C4.75 9.41421 4.41421 9.75 4 9.75C3.58579 9.75 3.25 9.41421 3.25 9V4.5ZM10.4697 9.96967C10.7626 9.67678 11.2374 9.67678 11.5303 9.96967L14.5303 12.9697C14.8232 13.2626 14.8232 13.7374 14.5303 14.0303L11.5303 17.0303C11.2374 17.3232 10.7626 17.3232 10.4697 17.0303C10.1768 16.7374 10.1768 16.2626 10.4697 15.9697L12.1893 14.25L2 14.25C1.58579 14.25 1.25 13.9142 1.25 13.5C1.25 13.0858 1.58579 12.75 2 12.75L12.1893 12.75L10.4697 11.0303C10.1768 10.7374 10.1768 10.2626 10.4697 9.96967Z"
      fill="#5C576D"
    />
  </svg>
);

export const Delete = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2.75C10.3096 2.75 9.75 3.30964 9.75 4V6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6V4C8.25 2.48122 9.48122 1.25 11 1.25H13C14.5188 1.25 15.75 2.48122 15.75 4V6C15.75 6.41421 15.4142 6.75 15 6.75C14.5858 6.75 14.25 6.41421 14.25 6V4C14.25 3.30964 13.6904 2.75 13 2.75H11Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.25C5.91421 6.25 6.25 6.58579 6.25 7V19C6.25 19.6904 6.80964 20.25 7.5 20.25H16.5C17.1904 20.25 17.75 19.6904 17.75 19V7C17.75 6.58579 18.0858 6.25 18.5 6.25C18.9142 6.25 19.25 6.58579 19.25 7V19C19.25 20.5188 18.0188 21.75 16.5 21.75H7.5C5.98122 21.75 4.75 20.5188 4.75 19V7C4.75 6.58579 5.08579 6.25 5.5 6.25Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0303 10.4697C15.3232 10.7626 15.3232 11.2374 15.0303 11.5303L10.0303 16.5303C9.73744 16.8232 9.26256 16.8232 8.96967 16.5303C8.67678 16.2374 8.67678 15.7626 8.96967 15.4697L13.9697 10.4697C14.2626 10.1768 14.7374 10.1768 15.0303 10.4697Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96967 10.4697C9.26256 10.1768 9.73744 10.1768 10.0303 10.4697L15.0303 15.4697C15.3232 15.7626 15.3232 16.2374 15.0303 16.5303C14.7374 16.8232 14.2626 16.8232 13.9697 16.5303L8.96967 11.5303C8.67678 11.2374 8.67678 10.7626 8.96967 10.4697Z"
      fill="#5C576D"
    />
  </svg>
);

export const Upload = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15V17C3.75 18.7949 5.20507 20.25 7 20.25H17C18.7949 20.25 20.25 18.7949 20.25 17V15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V17C21.75 19.6234 19.6234 21.75 17 21.75H7C4.37665 21.75 2.25 19.6234 2.25 17V15C2.25 14.5858 2.58579 14.25 3 14.25Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5303 8.53033C17.2374 8.82322 16.7626 8.82322 16.4697 8.53033L12 4.06066L7.53033 8.53033C7.23744 8.82322 6.76256 8.82322 6.46967 8.53033C6.17678 8.23744 6.17678 7.76256 6.46967 7.46967L11.4697 2.46967C11.7626 2.17678 12.2374 2.17678 12.5303 2.46967L17.5303 7.46967C17.8232 7.76256 17.8232 8.23744 17.5303 8.53033Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V3C11.25 2.58579 11.5858 2.25 12 2.25Z"
      fill="#5C576D"
    />
  </svg>
);

export const Download = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15V17C3.75 18.7949 5.20507 20.25 7 20.25H17C18.7949 20.25 20.25 18.7949 20.25 17V15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V17C21.75 19.6234 19.6234 21.75 17 21.75H7C4.37665 21.75 2.25 19.6234 2.25 17V15C2.25 14.5858 2.58579 14.25 3 14.25Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46967 8.46967C6.76256 8.17678 7.23744 8.17678 7.53033 8.46967L12 12.9393L16.4697 8.46967C16.7626 8.17678 17.2374 8.17678 17.5303 8.46967C17.8232 8.76256 17.8232 9.23744 17.5303 9.53033L12.5303 14.5303C12.2374 14.8232 11.7626 14.8232 11.4697 14.5303L6.46967 9.53033C6.17678 9.23744 6.17678 8.76256 6.46967 8.46967Z"
      fill="#5C576D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.75C12.4142 1.75 12.75 2.08579 12.75 2.5V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V2.5C11.25 2.08579 11.5858 1.75 12 1.75Z"
      fill="#5C576D"
    />
  </svg>
);

export const NewFolder = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91669 3.125C2.57151 3.125 2.29169 3.40482 2.29169 3.75V15C2.29169 15.5753 2.75806 16.0417 3.33335 16.0417H16.6667C17.242 16.0417 17.7084 15.5753 17.7084 15V6.66667C17.7084 6.09137 17.242 5.625 16.6667 5.625H8.33335C8.12438 5.625 7.92924 5.52056 7.81332 5.34669L6.64143 3.58885C6.44824 3.29906 6.123 3.125 5.77471 3.125H2.91669ZM1.04169 3.75C1.04169 2.71447 1.88115 1.875 2.91669 1.875H5.77471C6.54094 1.875 7.25647 2.25794 7.6815 2.89548L8.66784 4.375H16.6667C17.9323 4.375 18.9584 5.40101 18.9584 6.66667V15C18.9584 16.2657 17.9323 17.2917 16.6667 17.2917H3.33335C2.0677 17.2917 1.04169 16.2657 1.04169 15V3.75ZM10 7.70833C10.3452 7.70833 10.625 7.98816 10.625 8.33333V10.2083H12.5C12.8452 10.2083 13.125 10.4882 13.125 10.8333C13.125 11.1785 12.8452 11.4583 12.5 11.4583H10.625V13.3333C10.625 13.6785 10.3452 13.9583 10 13.9583C9.65484 13.9583 9.37502 13.6785 9.37502 13.3333V11.4583H7.50002C7.15484 11.4583 6.87502 11.1785 6.87502 10.8333C6.87502 10.4882 7.15484 10.2083 7.50002 10.2083H9.37502V8.33333C9.37502 7.98816 9.65484 7.70833 10 7.70833Z"
      fill="#5346E6"
    />
  </svg>
);

export const Setting = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 2.21652C11.2852 1.54664 12.7147 1.54664 13.875 2.21652L19.5352 5.48447C20.6955 6.15434 21.4102 7.39231 21.4102 8.73206V15.268C21.4102 16.6077 20.6955 17.8457 19.5352 18.5156L13.875 21.7835C12.7147 22.4534 11.2852 22.4534 10.125 21.7835L4.46472 18.5156C3.30447 17.8457 2.58972 16.6077 2.58972 15.268V8.73206C2.58972 7.39231 3.30447 6.15434 4.46472 5.48447L10.125 2.21652ZM13.125 3.51555C12.4288 3.11363 11.5711 3.11363 10.875 3.51555L5.21472 6.7835C4.51857 7.18543 4.08972 7.92821 4.08972 8.73206V15.268C4.08972 16.0718 4.51857 16.8146 5.21472 17.2165L10.875 20.4845C11.5711 20.8864 12.4288 20.8864 13.125 20.4845L18.7852 17.2165C19.4814 16.8146 19.9102 16.0718 19.9102 15.268V8.73206C19.9102 7.92821 19.4814 7.18543 18.7852 6.7835L13.125 3.51555Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
      fill="currentColor"
    />
  </svg>
);

export const SignOut = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 6C10.25 4.75736 9.24264 3.75 8 3.75L6 3.75C4.75736 3.75 3.75 4.75736 3.75 6L3.75 18C3.75 19.2426 4.75736 20.25 6 20.25L8 20.25C9.24264 20.25 10.25 19.2426 10.25 18C10.25 17.5858 10.5858 17.25 11 17.25C11.4142 17.25 11.75 17.5858 11.75 18C11.75 20.0711 10.0711 21.75 8 21.75L6 21.75C3.92893 21.75 2.25 20.0711 2.25 18L2.25 6C2.25 3.92893 3.92893 2.25 6 2.25L8 2.25C10.0711 2.25 11.75 3.92893 11.75 6C11.75 6.41421 11.4142 6.75 11 6.75C10.5858 6.75 10.25 6.41421 10.25 6Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4697 17.5303C16.1768 17.2374 16.1768 16.7626 16.4697 16.4697L20.9393 12L16.4697 7.53033C16.1768 7.23744 16.1768 6.76256 16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967L22.5303 11.4697C22.8232 11.7626 22.8232 12.2374 22.5303 12.5303L17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 12C21.75 12.4142 21.4142 12.75 21 12.75L10 12.75C9.58579 12.75 9.25 12.4142 9.25 12C9.25 11.5858 9.58579 11.25 10 11.25L21 11.25C21.4142 11.25 21.75 11.5858 21.75 12Z"
      fill="#120D6E"
    />
  </svg>
);

export const TrashCan = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2.75C10.3096 2.75 9.75 3.30964 9.75 4V6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6V4C8.25 2.48122 9.48122 1.25 11 1.25H13C14.5188 1.25 15.75 2.48122 15.75 4V6C15.75 6.41421 15.4142 6.75 15 6.75C14.5858 6.75 14.25 6.41421 14.25 6V4C14.25 3.30964 13.6904 2.75 13 2.75H11Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.25C5.91421 6.25 6.25 6.58579 6.25 7V19C6.25 19.6904 6.80964 20.25 7.5 20.25H16.5C17.1904 20.25 17.75 19.6904 17.75 19V7C17.75 6.58579 18.0858 6.25 18.5 6.25C18.9142 6.25 19.25 6.58579 19.25 7V19C19.25 20.5188 18.0188 21.75 16.5 21.75H7.5C5.98122 21.75 4.75 20.5188 4.75 19V7C4.75 6.58579 5.08579 6.25 5.5 6.25Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.5C10.4142 9.5 10.75 9.83579 10.75 10.25V17.75C10.75 18.1642 10.4142 18.5 10 18.5C9.58579 18.5 9.25 18.1642 9.25 17.75V10.25C9.25 9.83579 9.58579 9.5 10 9.5Z"
      fill="#120D6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9.5C14.4142 9.5 14.75 9.83579 14.75 10.25V17.75C14.75 18.1642 14.4142 18.5 14 18.5C13.5858 18.5 13.25 18.1642 13.25 17.75V10.25C13.25 9.83579 13.5858 9.5 14 9.5Z"
      fill="#120D6E"
    />
  </svg>
);

export const DeletePermanently = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6C2.25 6.41421 2.58579 6.75 3 6.75H4.75V19C4.75 20.5188 5.98122 21.75 7.5 21.75H16.5C18.0188 21.75 19.25 20.5188 19.25 19V6.75H21C21.4142 6.75 21.75 6.41421 21.75 6C21.75 5.58579 21.4142 5.25 21 5.25ZM15 6.75C15.4142 6.75 15.75 6.41421 15.75 6V4C15.75 2.48122 14.5188 1.25 13 1.25H11C9.48122 1.25 8.25 2.48122 8.25 4V6C8.25 6.41421 8.58579 6.75 9 6.75C9.41421 6.75 9.75 6.41421 9.75 6V4C9.75 3.30964 10.3096 2.75 11 2.75H13C13.6904 2.75 14.25 3.30964 14.25 4V6C14.25 6.41421 14.5858 6.75 15 6.75Z"
      fill="#5C576D"
    />
    <path
      d="M15.0303 11.5303C15.3232 11.2374 15.3232 10.7626 15.0303 10.4697C14.7374 10.1768 14.2626 10.1768 13.9697 10.4697L12 12.4393L10.0303 10.4697C9.73744 10.1768 9.26256 10.1768 8.96967 10.4697C8.67678 10.7626 8.67678 11.2374 8.96967 11.5303L10.9393 13.5L8.96967 15.4697C8.67678 15.7626 8.67678 16.2374 8.96967 16.5303C9.26256 16.8232 9.73744 16.8232 10.0303 16.5303L12 14.5607L13.9697 16.5303C14.2626 16.8232 14.7374 16.8232 15.0303 16.5303C15.3232 16.2374 15.3232 15.7626 15.0303 15.4697L13.0607 13.5L15.0303 11.5303Z"
      fill="white"
    />
  </svg>
);

export const Restore = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88468 17C7.32466 19.1128 9.75033 20.5 12.5 20.5C16.9183 20.5 20.5 16.9183 20.5 12.5C20.5 8.08172 16.9183 4.5 12.5 4.5C8.08172 4.5 4.5 8.08172 4.5 12.5V13.5M12.5 8V12.5L15.5 15.5"
      stroke="#121923"
      strokeWidth="1.2"
    ></path>
    <path d="M7 11L4.5 13.5L2 11" stroke="#121923" strokeWidth="1.2"></path>
  </svg>
);

export const GoogleCalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Google Calendar"
    role="img"
    viewBox="0 0 512 512"
    width="60px"
    height="60px"
  >
    <rect width="100%" height="100%" fill="#ffffff" />
    <path d="M100 340h74V174H340v-74H137Q100 100 100 135" fill="#4285f4" />
    <path d="M338 100v76h74v-41q0-35-35-35" fill="#1967d2" />
    <path d="M338 174h74V338h-74" fill="#fbbc04" />
    <path d="M100 338v39q0 35 35 35h41v-74" fill="#188038" />
    <path d="M174 338H338v74H174" fill="#34a853" />
    <path d="M338 412v-74h74" fill="#ea4335" />
    <path
      d="M204 229a25 22 1 1 1 25 27h-9h9a25 22 1 1 1-25 27M270 231l27-19h4v-7V308"
      stroke="#4285f4"
      strokeWidth="7"
      strokeLinejoin="bevel"
      fill="none"
    />
  </svg>
);
