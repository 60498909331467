import { createFileRoute } from '@tanstack/react-router';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import LandingLayout from '@/components/layout/landing-layout.tsx';
import { useMutation } from '@apollo/client';
import { ForgotPasswordDocument } from '@@graphql';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { forgotPasswordSchema } from '@/zodSchema/forgot-password.ts';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [cooldown, setCooldown] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [forgotPasswordMutation] = useMutation(ForgotPasswordDocument);
  const { handleError, resetErrors, errors } = useErrorHandler({ email: '' });

  useEffect(() => {
    const storedCooldown = localStorage.getItem('forgotPasswordCooldown');
    const storedTimestamp = localStorage.getItem('forgotPasswordTimestamp');

    if (storedCooldown && storedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
      const remainingCooldown = Math.max(0, parseInt(storedCooldown) - elapsedTime);

      if (remainingCooldown > 0) {
        setCooldown(remainingCooldown);
        setIsSent(true);
      } else {
        localStorage.removeItem('forgotPasswordCooldown');
        localStorage.removeItem('forgotPasswordTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setTimeout(() => {
        const newCooldown = cooldown - 1;
        setCooldown(newCooldown);
        if (newCooldown === 0) {
          setIsSent(false);
          localStorage.removeItem('forgotPasswordCooldown');
          localStorage.removeItem('forgotPasswordTimestamp');
        } else {
          localStorage.setItem('forgotPasswordCooldown', newCooldown.toString());
          localStorage.setItem('forgotPasswordTimestamp', Date.now().toString());
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldown]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (cooldown > 0) return;
    resetErrors();
    try {
      forgotPasswordSchema.parse({ email });
      await forgotPasswordMutation({
        variables: { email },
      });
      setIsSent(true);
      setCooldown(30);
      localStorage.setItem('forgotPasswordCooldown', '30');
      localStorage.setItem('forgotPasswordTimestamp', Date.now().toString());
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <LandingLayout className="items-center">
      <div className="w-full max-w-md bg-white rounded-3xl p-8">
        <img src="/logo.svg" alt="Docubank logo" className="mx-auto mb-8 w-auto" />
        <h1 className="text-2xl text-center mb-4">忘記密碼？</h1>
        {isSent ? (
          <p className="text-center text-gray-500 mb-6">
            已寄送重設密碼認證信到您的 Email
            <br />
            請查收並點擊信件中的連結重新設定您的密碼，謝謝
          </p>
        ) : (
          <p className="text-center text-gray-500 mb-6">
            系統會發送重置密碼驗證信到您的 Email
            <br />
            點擊信件中之連結重新設定您的密碼，謝謝
          </p>
        )}
        <div className="mx-8">
          {!isSent && (
            <div className="mb-8">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                className="w-full px-4 py-3 rounded-lg border border-gray-300"
                onChange={e => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
          )}

          <Button
            type="submit"
            className={`w-full py-3 rounded-lg font-semibold relative overflow-hidden ${isSent ? 'cursor-not-allowed' : ''}`}
            onClick={handleSubmit}
          >
            {cooldown > 0 ? (
              <>
                <span className="relative z-10">再次發送 ({cooldown})</span>
                <div
                  className="absolute left-0 top-0 h-full bg-indigo-300"
                  style={{ width: `${((30 - cooldown) / 30) * 100}%` }}
                ></div>
              </>
            ) : (
              '發送'
            )}
          </Button>
        </div>
      </div>
    </LandingLayout>
  );
}

export const Route = createFileRoute('/forgot-password')({
  component: ForgotPassword,
});
