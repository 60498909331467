import { create } from 'zustand';

export type Department = {
  id: string;
  name: string;
  departmentId: string;
  scope: string;
};

interface HomeStore {
  departments: Department[];
  setDepartments: (departments: Department[]) => void;
  clearDepartments: () => void;
}

export const useHomeStore = create<HomeStore>((set) => ({
  departments: [],
  setDepartments: (departments) => set({ departments }),
  clearDepartments: () => set({ departments: [] }),
}));
