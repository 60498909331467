import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Settings, UserRoundPlus } from 'lucide-react';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { DialogActionType } from '@/constants';
import {
  CreateRoleDocument,
  RolesQuery,
  RolesQueryVariables,
  UpdateRoleDocument,
} from '@@graphql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { toast } from '@/components/ui/use-toast.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import PermissionSelector from '@/components/permission-selector.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { SaveRoleSchema } from '@/zodSchema/role.ts';
import { PermissionData } from '@/types';

type RoleDialogProps = {
  type: DialogActionType;
  id?: string;
  description?: string;
  name?: string;
  permission?: number;
  refetch?: (
    variables?: Partial<RolesQueryVariables>,
  ) => Promise<ApolloQueryResult<RolesQuery>>;
  permissionConfig: PermissionData[];
  canEdit: boolean;
};

interface RoleFormData {
  name: string;
  description?: string;
  permission: number;
}

const RoleDialog = ({
  permission,
  description,
  name,
  refetch,
  type,
  id,
  permissionConfig,
  canEdit,
}: RoleDialogProps) => {
  const [open, setOpen] = useState(false);
  const [newPermission, setNewPermission] = useState(permission || 0);
  const errorObject = {
    name: '',
    description: '',
    permission: '',
  };
  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);

  const [createRoleMutation] = useMutation(CreateRoleDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const [updateRoleMutation] = useMutation(UpdateRoleDocument, {
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  const createRole = async (formData: RoleFormData) => {
    await createRoleMutation({
      variables: {
        ...formData,
      },
    });
  };

  const updateRole = async (id: string, formData: RoleFormData) => {
    await updateRoleMutation({
      variables: {
        id,
        ...formData,
      },
    });
  };
  const onSubmitCreateOrUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    const data = new FormData(e.currentTarget);
    const nameFieldValue = data.get('name');
    const descriptionFieldValue = data.get('description');
    const formData = {
      name: nameFieldValue!.toString().trim(),
      description: descriptionFieldValue?.toString().trim(),
      permission: newPermission,
    };
    try {
      if (type === DialogActionType.CREATE) {
        SaveRoleSchema.parse(formData);
        await createRole(formData);
        toast({ title: '新增角色成功' });
      } else {
        SaveRoleSchema.parse(formData);
        id && (await updateRole(id, formData));
        toast({ title: '編輯角色成功' });
      }
      setOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {type === DialogActionType.CREATE ? (
          <Button className="gap-1 font-normal text-primary" variant="ghost">
            <UserRoundPlus size={20} strokeWidth={1.5} /> 新增角色
          </Button>
        ) : (
          <Button
            className="size-8 text-typography-secondary"
            variant="ghost"
            size="icon"
            disabled={!canEdit}
          >
            <Settings size={20} strokeWidth={1.5} />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogDescription />
        <form onSubmit={onSubmitCreateOrUpdate}>
          <DialogHeader>
            <DialogTitle>
              {type === DialogActionType.CREATE ? '新增角色' : '編輯角色'}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 pt-6">
            <div className="grid gap-2">
              <Label htmlFor="name" className="font-normal">
                名稱
              </Label>
              <Input id="name" name="name" className="col-span-3" defaultValue={name} />
              {errors.name && <div className="text-red-500">{errors.name}</div>}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description" className="font-normal">
                描述
              </Label>
              <Textarea
                id="description"
                name="description"
                defaultValue={description}
              />
              {errors.description && (
                <div className="text-red-500">{errors.description}</div>
              )}
            </div>
            <div className="grid gap-2">
              <Label className="font-normal">權限</Label>
              <PermissionSelector
                permissionConfig={permissionConfig}
                defaultPermission={permission}
                onPermissionChange={v => setNewPermission(v)}
              />
              {errors.permission && (
                <div className="text-red-500">{errors.permission}</div>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" type="button" onClick={() => setOpen(false)}>
              取消
            </Button>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RoleDialog;
