'use client';

import { useState } from 'react';
import dayjs from 'dayjs';
import { Calendar as CalendarIcon, CircleX, ChevronDown } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { DATE_FORMAT } from '@/constants';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui//calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

export default function DatePickerWithRange({
  className,
  date,
  setDate,
  placeholder = 'Pick a date',
  onSelect,
}: {
  className?: string;
  date?: DateRange;
  setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  placeholder?: string;
  onSelect?: () => void;
}) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal relative pr-10',
              date && 'text-muted-foreground bg-primary-100',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {dayjs(date.from, 'LLL dd, y').format(DATE_FORMAT)} -{' '}
                  {dayjs(date.to, 'LLL dd, y').format(DATE_FORMAT)}
                </>
              ) : (
                dayjs(date.from, 'LLL dd, y').format(DATE_FORMAT)
              )
            ) : (
              <span>{placeholder}</span>
            )}

            {date ? (
              <CircleX
                className="absolute h-4 w-4 right-3 text-gray-400"
                onClick={e => {
                  e.preventDefault();
                  setDate(undefined);
                }}
              />
            ) : (
              <ChevronDown className="absolute h-4 w-4 right-3 text-gray-400" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={date => {
              onSelect && onSelect();
              if (date?.to) {
                setCalendarOpen(false);
              }
              setDate(date);
            }}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
