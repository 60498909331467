import { useCallback, useState } from 'react';
import { z } from 'zod';
import { toast } from '@/components/ui/use-toast.ts';
type ErrorObject = Record<string, string>;

const TOAST_DURATION = 1500;

export const useErrorHandler = (initialErrorState: ErrorObject) => {
  const [errors, setErrors] = useState<ErrorObject>(initialErrorState);

  const handleError = useCallback(
    (err: unknown, defaultErrorMessage = '發生未預期的錯誤') => {
      if (err instanceof z.ZodError) {
        const zodErrors = err.errors.reduce((acc, issue) => {
          const path = issue.path.join('.');
          acc[path] = issue.message;
          return acc;
        }, {} as ErrorObject);

        setErrors(prevErrors => ({
          ...prevErrors,
          ...zodErrors,
        }));
      } else if (err instanceof Error) {
        toast({
          title: '錯誤',
          description: err.message || defaultErrorMessage,
          variant: 'destructive',
          duration: TOAST_DURATION,
        });
        setErrors(prevErrors => ({
          ...prevErrors,
          general: err.message || defaultErrorMessage,
        }));
      } else {
        toast({
          title: '錯誤',
          description: defaultErrorMessage,
          variant: 'destructive',
          duration: TOAST_DURATION,
        });
        setErrors(prevErrors => ({
          ...prevErrors,
          general: defaultErrorMessage,
        }));
      }
    },
    [],
  );

  const resetErrors = useCallback(() => {
    setErrors(initialErrorState);
  }, [initialErrorState]);

  return { errors, handleError, resetErrors };
};
