import { useEffect, Fragment, useState, useRef, useCallback } from 'react';
import { BreadcrumbModel, FolderModel } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { DialogClose, DialogFooter } from '@/components/ui/dialog';
import { useLazyQuery } from '@apollo/client';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { BreadcrumbsDocument, FoldersDocument } from '@@graphql';
import { FolderOpen } from '@/components/icons';

const formSchema = z.object({
  targetFolderId: z.string(),
  fileIds: z.string().array(),
});

interface MoveFormProps {
  selectedFileIds: string[];
  fileId: string;
  onSubmit: (values: z.infer<typeof formSchema>) => void;
}

const MoveForm = ({ selectedFileIds, fileId, onSubmit }: MoveFormProps) => {
  const [selectFileId, setSelectFileId] = useState<string | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbModel[]>([]);
  const [folders, setFolders] = useState<FolderModel[]>([]);
  const [breadcrumbsQuery] = useLazyQuery(BreadcrumbsDocument);
  const [foldersQuery] = useLazyQuery(FoldersDocument);
  const lastClickTimeRef = useRef(0);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      targetFolderId: '',
      fileIds: selectedFileIds,
    },
  });

  useEffect(() => {
    form.setValue('targetFolderId', fileId);
    setSelectFileId(fileId);
  }, [fileId, form]);

  const fetchFoldersAndBreadcrumbs = useCallback(
    async (currentFileId: string) => {
      try {
        const [foldersResult, breadcrumbsResult] = await Promise.all([
          foldersQuery({
            variables: {
              fileId: currentFileId,
              isDir: true,
            },
          }),
          breadcrumbsQuery({
            variables: {
              fileId: currentFileId,
            },
          }),
        ]);
        if (foldersResult.data) {
          const items = foldersResult.data.folders.items.filter(
            (item: FolderModel) => !selectedFileIds.includes(item.id), // ignore self
          );
          setFolders(items);
        }
        if (breadcrumbsResult.data) {
          setBreadcrumbs(breadcrumbsResult.data?.breadcrumbs as BreadcrumbModel[]);
        }
        setSelectFileId(currentFileId);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [foldersQuery, breadcrumbsQuery],
  );

  useEffect(() => {
    fetchFoldersAndBreadcrumbs(fileId);
  }, [fileId, fetchFoldersAndBreadcrumbs]);

  const handleItemClick = (fileId: string) => {
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastClickTimeRef.current;

    setSelectFileId(fileId);

    if (timeDiff < 300) {
      // Double click
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
      fetchFoldersAndBreadcrumbs(fileId);
    } else {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      clickTimeoutRef.current = setTimeout(() => {}, 300);
    }

    lastClickTimeRef.current = currentTime;
  };

  useEffect(() => {
    return () => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="targetFolderId"
          render={({ field }) => (
            <FormItem>
              <div className="bg-neutral-100 px-4 py-2 rounded-t-lg border-neutral-200 border-t border-x">
                <Breadcrumb>
                  <BreadcrumbList>
                    {breadcrumbs.map((item, index) => (
                      <Fragment key={item.id}>
                        <BreadcrumbItem>
                          <BreadcrumbLink
                            className={
                              item.scope === `/${item.id}`
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            }
                            onClick={e => {
                              e.preventDefault();
                              if (item.scope === `/${item.id}`) {
                                return;
                              }
                              fetchFoldersAndBreadcrumbs(item.id);
                              field.onChange(item.id);
                              handleItemClick(item.id);
                            }}
                          >
                            {item.name}
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                      </Fragment>
                    ))}
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <ul className="max-h-60 min-h-60 overflow-y-auto border-b border-x border-neutral-200 rounded-b-lg">
                {folders.map(item => (
                  <li
                    className={`flex items-center cursor-pointer ${
                      selectFileId === item.id
                        ? 'bg-primary text-white'
                        : 'text-primary-dark hover:bg-primary-50'
                    }`}
                    key={item.id}
                    onClick={() => {
                      field.onChange(item.id);
                      handleItemClick(item.id);
                    }}
                  >
                    <span className="px-4 py-2">
                      <FolderOpen />
                    </span>
                    <div>{item.name}</div>
                  </li>
                ))}
              </ul>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button type="submit">搬移</Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default MoveForm;
