import { createFileRoute } from '@tanstack/react-router';
import { HomeDocument } from '@@graphql';
import { useQuery } from '@apollo/client';
import { FileModel } from '@/types';
import {
  formatFileSize,
  transFormDate,
  transformFileType,
} from '@/utils/file.ts';
import FolderView from '@/pages/folderView.tsx';

export const Route = createFileRoute('/_layout/')({
  component: Index,
});

function Index() {
  const { data, error, loading } = useQuery(HomeDocument, {
    fetchPolicy: 'network-only',
  });
  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  const fileRawData = (data?.folders.items as FileModel[]) ?? [];
  const fileData = fileRawData.map(
    ({ name, ext, isDir, id, size, updatedAt, createdAt, isPrivate }) => {
      return {
        id,
        createdAt: transFormDate(createdAt),
        updatedAt: transFormDate(updatedAt),
        isDir,
        name,
        type: isDir ? undefined : transformFileType(ext),
        size: formatFileSize(size),
        isPrivate,
      };
    },
  );
  return <FolderView breadcrumbs={[]} folderName="Home" files={fileData} />;
}
