import { create } from 'zustand';
import { User } from '@/types';
import { MeDocument } from '@@graphql';
import { ApolloClient } from '@apollo/client';

interface UserState {
  user: User | null;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  clearUser: () => void;
  checkAuth: (client: ApolloClient<any>) => Promise<void>;
}

export const useUserStore = create<UserState>()(
  (set) => ({
    user: null,
    isAuthenticated: !!localStorage.getItem('accessToken'),
    setUser: (user) => set({ user, isAuthenticated: true }),
    clearUser: () => set({ user: null, isAuthenticated: false }),
    checkAuth: async (client) => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        set({ isAuthenticated: false, user: null });
        return;
      }
      try {
        const { data } = await client.query({
          query: MeDocument,
          context: {
            headers: {
              authorization: token
                ? `Bearer ${localStorage.getItem('accessToken')}`
                : ''
            }
          }
        });
        if (data && data.me) {
          set({ isAuthenticated: true, user: data.me });
        } else {
          throw new Error('User data not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        localStorage.removeItem('accessToken');
        set({ isAuthenticated: false, user: null });
      }
    }
  })
);
