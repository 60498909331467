import { useState } from 'react';
import * as XLSX from 'xlsx';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Download } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { EventsDocument } from '@/graphql/generated/.graphql';
import AuditTable from './audit-table';
import { DATE_TIME_FORMAT } from '@/constants';

enum ReportTerm {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

const TERM_DATA_MAP = {
  [ReportTerm.DAILY]: {
    label: '每日',
    startAt: dayjs().startOf('day').format(),
    endAt: dayjs().endOf('day').format(),
  },
  [ReportTerm.WEEKLY]: {
    label: '每週',
    startAt: dayjs().startOf('week').format(),
    endAt: dayjs().endOf('week').format(),
  },
  [ReportTerm.MONTHLY]: {
    label: '每月',
    startAt: dayjs().startOf('month').format(),
    endAt: dayjs().endOf('month').format(),
  },
} as { [key: string]: { label: string; startAt: string; endAt: string } };

export type Report = {
  id: string;
  subject: string;
  begin: Date;
  end: Date;
  updatedAt: Date;
  createdAt: Date;
  procedure?: string;
  content?: string;
  fileUrl: string;
};

const AuditReports = () => {
  const [term, setTerm] = useState<string>(ReportTerm.WEEKLY);
  const {
    data: eventsData,
    loading,
    error,
  } = useQuery(EventsDocument, {
    variables: {
      search: {
        startAt: TERM_DATA_MAP[term].startAt,
        endAt: TERM_DATA_MAP[term].endAt,
      },
    },
    fetchPolicy: 'network-only',
  });

  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  const events = eventsData?.events.items || [];

  return (
    <div className="p-8">
      <h1 className="mb-4 text-lg md:text-2xl font-bold">重要事項提醒</h1>
      <div className="flex justify-between items-center mb-4">
        <Tabs defaultValue={term} onValueChange={setTerm} className="w-[400px]">
          <TabsList className="grid w-full grid-cols-3">
            {Object.keys(TERM_DATA_MAP).map(term => (
              <TabsTrigger key={term} value={term}>
                {TERM_DATA_MAP[term].label}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            const filteredData = events.map(
              ({
                subject,
                createdAt,
                begin,
                end,
                procedure,
                content,
                fileUrl,
              }: Report) => ({
                主旨: subject,
                建立時間: dayjs(createdAt).format(DATE_TIME_FORMAT),
                事件開始時間: dayjs(begin).format(DATE_TIME_FORMAT),
                事件結束時間: dayjs(end).format(DATE_TIME_FORMAT),
                程序事項: procedure,
                內容: content,
                檔案位置: fileUrl,
              }),
            );
            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            const { label } = TERM_DATA_MAP[term];
            XLSX.utils.book_append_sheet(wb, ws, `${label}報告`);
            XLSX.writeFile(wb, `${label}重要事項提醒.xlsx`);
          }}
        >
          <Download className="mr-1" size={20} strokeWidth={1.5} />
          {`下載${TERM_DATA_MAP[term].label}報告`}
        </Button>
      </div>
      <AuditTable data={events} />
    </div>
  );
};

export default AuditReports;
