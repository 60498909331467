import { useEffect, useMemo, useRef, useState } from 'react';
import { DocumentNode, OperationVariables, useLazyQuery } from '@apollo/client';
import { useInfiniteQuery, QueryKey } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import EmptyData from './empty-data';

const COLUMN_HEIGHT = 50;
interface TableProps<TData, TVariables extends OperationVariables> {
  height?: string;
  columns: ColumnDef<TData>[];
  query: DocumentNode;
  getVariables: (pageParam: any) => TVariables;
  queryKey: QueryKey;
  getNextPageParam: (lastPage: any) => any | undefined;
  dataExtractor: (data: unknown) => TData[];
}

function InfiniteScrollTable<TData, TVariables extends OperationVariables>({
  height = 'calc(100vh - 237px)',
  columns,
  query,
  getVariables,
  queryKey,
  getNextPageParam,
  dataExtractor,
}: TableProps<TData, TVariables>) {
  const [getQueryResult, { called }] = useLazyQuery<any, TVariables>(query);
  const [sorting, setSorting] = useState<SortingState>([]);

  const fetchPage = async ({ pageParam = null }) => {
    const { data } = await getQueryResult({
      variables: getVariables(pageParam),
    });
    return data || [];
  };

  const { status, data, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey,
      queryFn: fetchPage,
      initialPageParam: null,
      getNextPageParam,
    });

  useEffect(() => {
    if (!called) {
      getQueryResult({ variables: getVariables(null) });
    }
  }, [called, getQueryResult, getVariables]);

  const flatData: TData[] = useMemo(() => {
    return (data?.pages ?? []).flatMap(dataExtractor);
  }, [data, dataExtractor]);

  const table = useReactTable({
    data: flatData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? flatData.length + 1 : flatData.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => COLUMN_HEIGHT,
    overscan: 5,
  });

  const columnWidths = useMemo(
    () => table.getAllColumns().map(column => column.getSize()),
    [table],
  );

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= flatData.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage &&
      !!flatData['0']
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    flatData.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  if (status === 'pending') {
    return <div>Loading...</div>;
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div
      className="rounded-md border bg-white"
      style={{
        width: '100%',
        maxHeight: height,
        overflow: 'auto',
      }}
      ref={tableContainerRef}
    >
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id} className="flex">
              {headerGroup.headers.map(header => (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    display: 'inline-block',
                    lineHeight: `${COLUMN_HEIGHT}px`,
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().length === 0 || !flatData['0'] ? (
            <TableRow className="sticky flex top-[-50px] left-[calc(50%-150px)] hover:bg-transparent">
              <TableCell className="w-full">
                <EmptyData />
              </TableCell>
            </TableRow>
          ) : (
            rowVirtualizer.getVirtualItems().map(virtualRow => {
              const { index, size, start } = virtualRow;
              const row = rows[index];
              const isLoaderRow = index > flatData.length - 1;
              return (
                <TableRow
                  key={index}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                >
                  {isLoaderRow
                    ? hasNextPage
                      ? 'Loading more...'
                      : 'Nothing more to load'
                    : row.getVisibleCells().map((cell, cellIndex) => {
                        return (
                          <TableCell
                            key={cell.id}
                            className="px-4 py-0 truncate"
                            style={{
                              width: columnWidths[cellIndex],
                              maxWidth: columnWidths[cellIndex],
                              lineHeight: `${COLUMN_HEIGHT}px`,
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default InfiniteScrollTable;
